import React from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import XLSX from 'xlsx'
import moment from "moment";
import AppSidebar from '../../Components/AppSidebar'
import Navmain from '../../Components/Navbar'
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { AppBar, Dialog, Grid, IconButton, Toolbar, Slide, List, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core'
import { Card, Table } from "react-bootstrap";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import ListIcon from "@material-ui/icons/List";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@mui/material'
import ListDoctor from './tables/ListDoctor'
import EditDoctor from './forms/EditDoctor'
import { useEffect } from 'react'
import axios from 'axios'
import Backdrop from "@material-ui/core/Backdrop";
import { toast } from "react-toastify";
import c9gif from '../../SampleFile/cloudnine.gif';
import { APIURL } from '../../CommomMethods/Fetch'
import Agreement from '../../Pages/Payout/NEW/Agreement';
import { documentlist } from '../../actions/documentActions';
import Iframe from 'react-iframe';
import { dateFormatter } from '../../actions/createPositionActions';
import { Modal } from "react-bootstrap";

import { useHistory } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styleSheet = makeStyles((theme) => ({
    iconButtonStyle: {
        float: "right",
    },
    marginforMaster: {
        marginTop: "30px",
    },
}));
const backDropStyle = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
function ManageDoctors() {
    const classes = styleSheet();
    const backClasses = backDropStyle();
    const [preview, setPreview] = useState(true);
    const [viewDocumentModal, setViewDocumentModal] = useState(false);
    const [editing, setEditing] = useState(false);
    const [doctor, setDoctor] = useState({});
    const [doctorDetail, setDoctorDetail] = useState({});
    const [urlData, setUrlData] = useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [rows, setRows] = useState([]);
    const [backDropOpen, setBackDropOpen] = useState(false);
    const [data, setData] = useState(false);
    const [docData, setDocData] = useState([]);
    const [viewAgreement, setViewAgreement] = useState(false);
    const [categoty, setCategory] = useState("");
    const [filterCenters, setFilterCenters] = useState([]);
    const [filterDatas, setfilterDatas] = useState([])
    const dispatch = useDispatch();
    const history = useHistory();

    const userLogin = useSelector((state) => state.userLogin);
    const { user } = userLogin;
    const listDoc = useSelector((state) => state.listDoc);
    const { list } = listDoc;


    // State for All the Agreement
    const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
    const [agreementList, setAgreementList] = useState('');

    // state for edit lt id
    const [ltEeditRowDetails, setLTeditRowDetails] = useState(null);
    const [LTid, setLTid] = useState(null);
    const [modalRemarks, setModalRemarks] = useState("");

    const formStyle = {
        borderRadius: "5px",
        border: "1px solid black",
    };
    const headerStyle = {
        backgroundColor: "#94248E",
        margins: "0px",
        padding: "10px",
        color: "white",
        borderRadius: "5px",
        borderBottom: "3px black solid",
    };
    const bodyStyle = {
        borderRadius: "5px",
    };

    // get all DoctorList
    const getAllDoctors = async () => {
        axios.get(APIURL() + "admin/doctors")
            .then((res) => res.data)
            .then((rows) => {
                console.log(rows, 'rowsDoctor')
                setRows(rows);
            })
    }

    useEffect(() => {
        getAllDoctors()
        axios
            .get(APIURL() + "centers/")
            .then((res) => res.data)
            .then((rows) => {
                var centerFilter = rows.reduce((a, v) => ({ ...a, [v.name]: v.name }), {})
                setFilterCenters(centerFilter);
            });
        axios.get(APIURL() + "designations/").then((res) => {
            var desFilters = res.data.data.reduce((a, v) => ({ ...a, [v.name]: v.name }), {})
            setfilterDatas(desFilters);
        });
    }, [data])

    //populate single record in the Edit component
    const editRow = async (row) => {
        setPreview(false);
        setEditing(true);
        await axios.get(`${APIURL()}ProfileDetails/${row.doctorId}`).then((res) => {
            setDoctor(res.data.details[0]);
            console.log(res.data.details[0], "doctor===========")
        })
    };

    //populate single record in the View component
    const viewRow = (row) => {
        console.log(row, "department===========")
        axios
            .get(APIURL() + "onboard/agreementView/" + row.doctorId)
            .then((res) => {
                if (res.data != null) {
                    console.log(res.data.data, "category-view");
                    setCategory(res.data.data.vacancyData[0].category);
                    setDocData(res.data.data);
                }
            });
        setViewAgreement(true);
    };

    const closeAgreement = () => {
        setViewAgreement(false);
        setDocData([]);
        setCategory("");
    };

    // view Documents
    const viewDoc = (row) => {
        dispatch(documentlist(row.doctorId));
        setViewDocumentModal(true);
        setDoctorDetail(row)
    }

    const closeDocumentModal = () => {
        setViewDocumentModal(false);
    }

    // View Doc File
    const viewDocFile = (url, filename) => {
        setOpenDialog(true);

        fetch(APIURL() + "documents/download/" + url, {
            method: "GET",
            headers: {
                "Content-Type": "application/pdf",
            },
        }).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                setUrlData(url);
            });
        });
    };

    // update Doctor Details
    const updateDoctor = async (id, doctor) => {
        setBackDropOpen(true)
        setData(false)
        await axios.put(`${APIURL()}admin/doctors/update-doctor/${id}`, {
            doctor: doctor,
            doctorId: id,
            userId: user.user.id,
            role: user.user.roles,
        }).then((res) => {
            if(res.status == 200){
                setBackDropOpen(false)
                setPreview(true);
                setEditing(false);
                setData(true)
                setDoctor({});
                toast.success(res.data.message)
            }else{
                setBackDropOpen(false)
                toast.warning(res?.data?.message ? res?.data?.message : "Something went wrong Please try again")
            }
        }).catch((err) => {
            // console.log(err.response.data, 'error uploading');
            setBackDropOpen(false)
            toast.error(err.response?.data?.message ? err.response?.data?.message : err.message ? err.message : "Something went wrong Please try again")
        })
    }

    // Download document
    const handleDownloadFile = (url, filename) => {
        fetch(APIURL() + "documents/download/" + url, {
            method: "GET",
            headers: {
                "Content-Type": "application/pdf",
            },
        }).then((response) => {
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = filename;
                a.click();
            });
            //window.location.href = response.url;
        });
    };

    // Download Signed Agreement
    // const downloadAgreement = async (row) => {
    //     console.log(row, "view-agreement-ch");
    //     // setAgreementRow(row);
    //     if (row.doctorAgreement) {
    //         await fetch(APIURL() + "documents/download/" + row.doctorAgreement, {
    //             method: "GET",
    //             headers: {
    //                 "Content-Type": "application/pdf",
    //             },
    //         }).then((response) => {
    //             response.blob().then((blob) => {
    //                 let url = window.URL.createObjectURL(blob);
    //                 let a = document.createElement("a");
    //                 a.href = url;
    //                 a.download = row.name + "_Agreement.pdf";
    //                 a.click();
    //             });
    //         });
    //         return;
    //     }
    //     if (!row.digio_doc_id) {
    //         toast.info("Document Id not found please try again some time!")
    //         return;
    //     }
    //     await axios.get(APIURL() + "onboard/download-agreement/" + row.digio_doc_id, {
    //         responseType: 'blob',
    //         headers: {
    //             Accept: 'application/pdf',
    //         },
    //     }).then((res) => {
    //         console.log(res)
    //         const url = window.URL.createObjectURL(new Blob([res.data]));
    //         const link = document.createElement("a");
    //         link.target = "_blank";
    //         link.download = row.name + "_Agreement.pdf"
    //         link.href = url;
    //         // link.setAttribute('download', 'Doctor Agreement.pdf'); //or any other extension
    //         document.body.appendChild(link);
    //         link.click();
    //     })
    // }

    const downloadAgreement = async (row) => {
        console.log(row);
        setIsAgreementModalOpen(true);
        setAgreementList("");
        let new_list = [];
        if (row.doctorAgreement) {
            new_list.push({
                type: 'manual',
                digio_doc_id: row.doctorAgreement,
                digio_doc_file_name: row.name,
                digio_doc_date: row.doj
            })
        }
        // else if(row.digio_doc_id){
        //     new_list.push({
        //       digio_doc_id: row.digio_doc_id,
        //       digio_doc_file_name: row.name,
        //       digio_doc_date: row.doj
        //     })
        // }
        axios.get(APIURL() + "onboard/" + row.id)
            .then((response) => {
                // console.log(response, "response");
                if (response.data?.activityData.length > 0) {
                    response.data.activityData.map((data) => {
                        let index = new_list.findIndex((o) => o.digio_doc_id === data.digio_doc_id);
                        if (index >= 0) {
                            new_list.splice(index);
                        }
                    })
                    new_list = new_list.concat(response.data.activityData);
                }
                setAgreementList(new_list);
            })
    }

    const viewSignedAgreementDoc = async (id, name, doctorAgreement = false) => {
        if (doctorAgreement) {
            await fetch(APIURL() + "documents/download/" + id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/pdf",
                },
            }).then((response) => {
                if (response.status === 500) {
                    toast.error("Something went wrong. Please try again!");
                }
                else {
                    response.blob().then((blob) => {
                        let url = window.URL.createObjectURL(blob);
                        let a = document.createElement("a");
                        a.href = url;
                        a.download = name + "_Agreement.pdf";
                        a.click();
                    });
                }
            });
        } else {
            // setAgreementRow(row);
            await axios.get(APIURL() + "onboard/download-agreement/" + id, {
                responseType: 'blob',
                headers: {
                    Accept: 'application/pdf',
                },
            }).then((res) => {
                console.log(res)
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.target = "_blank";
                link.download = name + "_Agreement.pdf"
                link.href = url;
                // link.setAttribute('download', 'Doctor Agreement.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
        }
    };

    // Download Excel 
    const downloadExcel = () => {
        var count = 1

        console.log(rows, 'rowsDoctor')
        setRows(rows);
        const newData = rows.map(row => {
            var status = 'Request Initiated'
            var newRow = {
                'SI. No': count,
                'Name of Doctor': row.name,
                'Designation': row.designationLabel,
                'Type': row.category,
                'Department': row.departmentLabel,
                'D365 Id': row.VendorAccountNumber,
                'DOB': row.birthday ? moment(moment(row.birthday)).format("DD-MM-YYYY") : "---",
                'Joining Date': row.doj ? moment(moment(row.doj)).format("DD-MM-YYYY") : "---",
                'Commencement Date': row.cdoj ? moment(moment(row.cdoj)).format("DD-MM-YYYY") : "---",
                'Contract Execution Date': row.stampdate ? moment(moment(row.stampdate)).format("DD-MM-YYYY") : "---",
                'Term in Months': row.contractterm,
                'Next Renewal': row.doj ? (moment(moment(row.doj.substring(0, 10)).add(row.contractterm, 'M').calendar()).format("DD-MM-YYYY")) : "---",
                'Center': row.centerName,
                'Contact No': row.mobile,
                'Personal Email Id': row.email ? row.email : "",
                'LT Email ID': row.lt_email_id ? row.lt_email_id : "---",
                'PAN No.': row.panno,
                'State of Registration': row.mcrstate,
                'Medical Council Certificate Number': row.mcrstate ? (row.mcrstate.substring(0, 3) + "- " + row.mcrcval) : row.mcrcval,
                'Medical Council Expiry Date': row.mcrdate ? moment(moment(row.mcrdate)).format("DD-MM-YYYY") : "---",
                'Indemnity Amount': row.indemnityvalue,
                'Valid Till': row.indemnityexpiry ? moment(moment(row.indemnityexpiry)).format("DD-MM-YYYY") : "---",
                'Qualification': row.medicaldegree + ", " + row.pgdegree,
                'Current Address': row.Address,
                'Current State': row.State,
                'Cureent City': row.City,
                'Current Pin Code': row.PinCode,
                'Permanent Address': row.PermanentAddress,
                'Permanent State': row.PermanentState,
                'Permanent City': row.PermanentCity,
                'Permanent Pin Code': row.PermanentPinCode,
                'Gender': row.Gender,
                'Father Name': row.fathername,
                'Spouse Name': row.husbandname,
                'Post PG Degree Total Experience': row.pgrelevantexp ? row.pgrelevantexp + " months" : "---",
                'Post PG Degree Relevant Experience': row.pgtotalexp ? row.pgtotalexp + " months" : "---",
                'Aadhar Card Number': row.adhaarno,
                'Bank Name': row.bankname,
                'IFSC Code': row.bankifsc,
                'Account Number': row.bankaccno,
                'Requisition Number': row.requisition_id ? ("REQ-" + row.requisition_id) : "",
                'Onboarding Status': row.currentStatus,
                'Manual Agreement': row.manualAgreement == 1 ? "Yes" : "No",
                'User Status': row.userStatus,
            }
            delete row.tableData
            count++
            return newRow
        })
        console.log(newData, 'excelData')
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "doctors")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "DoctorsList.xlsx")

    }

    // console.log(user, 'users Detail')

    // lt id edit feature
    const editLTID = (row) => {
        console.log(row);
        console.log(user)
        setLTeditRowDetails(row);
        setLTid(row.lt_email_id);
        setModalRemarks("");
    }

    const updateAction = async () => {

        if (LTid === null || LTid === '' || modalRemarks === "") {
            toast.error(LTid === null || LTid === "" ? "Please Fill the LT Email ID" : modalRemarks === "" ? "Plesae fill the remarks" : "Something went wrong Please try again")
        }
        else {
            setData(false)
            await axios.put(`${APIURL()}admin/doctors/update-ltid/${ltEeditRowDetails.id}`, {
                ltID: LTid,
                remarks: modalRemarks,
                doctorName: ltEeditRowDetails.name,
                reqId: 0,
                editUserId: user.user.id,
                editUserRole: user.user.roles
            }).then((res) => {
                console.log(res);
                setLTid(null);
                setModalRemarks("");
                setLTeditRowDetails(null);
                toast.success(res.data.message)
                setData(true)
            }).catch((err) => {
                toast.error(err.response?.data?.message ? err.response?.data?.message : err.message ? err.message : "Something went wrong Please try again")
            })
        }

    }
    return (
        <>
            <AppSidebar />
            <div className="main-content side-content pt-0 mg-b-30">
                <div className="container-fluid">
                    <div className="inner-body">
                        <Navmain />
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={8}
                                lg={8}
                                xl={8}
                                className={classes.marginforMaster}
                            >
                                <Typography variant="h6">Manage Doctor</Typography>
                                {preview ? (
                                    <Typography variant="body2">
                                        Masters / Manage Doctor / Doctor List
                                    </Typography>
                                ) : editing ? (
                                    <Typography variant="body2">
                                        Masters / Manage Doctor / Edit Doctor
                                    </Typography>
                                ) : (
                                    <Typography variant="body2">
                                        Masters / Manage Doctor / Add Doctor
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                lg={4}
                                xl={4}
                                className={classes.marginforMaster}
                            >
                                <div className={classes.iconButtonStyle}>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            setPreview(true);
                                            setEditing(false);
                                            setDoctor({});
                                        }}
                                    >
                                        <ListIcon />
                                    </IconButton>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {preview ? (
                                    <ListDoctor
                                        rows={rows}
                                        filterCenters={filterCenters}
                                        filterDatas={filterDatas}
                                        editRow={editRow}
                                        viewRow={viewRow}
                                        viewDoc={viewDoc}
                                        downloadAgreement={downloadAgreement}
                                        downloadExcel={downloadExcel}
                                        editLTID={editLTID}
                                    />
                                ) : editing ? (
                                    <EditDoctor
                                        editing={editing}
                                        setEditing={setEditing}
                                        setPreview={setPreview}
                                        doctorDetail={doctor}
                                        updateDoctor={updateDoctor}
                                    />
                                ) : ''}
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Backdrop
                    className={backClasses.backdrop}
                    open={backDropOpen}
                >
                    <img src={c9gif} alt="loading..." />
                </Backdrop>
                <Dialog
                    fullScreen
                    open={viewAgreement}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="end"
                                style={{ marginLeft: "99%" }}
                                color="inherit"
                                onClick={closeAgreement}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <List>
                        {docData && docData.agreementTemplate ?
                            <Agreement
                                recData={docData}
                                catagory={categoty}
                            />
                            : ""}
                    </List>
                </Dialog>
                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    onClose={closeDocumentModal}
                    aria-labelledby="customized-dialog-title"
                    open={viewDocumentModal}
                >
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={closeDocumentModal}
                        style={{ textAlign: "center" }}
                    >
                        Uploaded Documents
                    </DialogTitle>
                    <DialogContent dividers>
                        <Card style={{
                            borderRadius: "5px",
                            border: "1px solid black",
                        }}>
                            <Card.Header style={{
                                borderRadius: "5px",
                                border: "1px solid black",
                            }}></Card.Header>
                            <Card.Body
                                style={{
                                    borderRadius: "5px",
                                }}
                            >
                                <Table style={{ width: "100%" }} borderless>
                                    <thead>
                                        <tr
                                            style={{
                                                textAlign: "center",
                                                backgroundColor: "hsla(340, 97%, 26%, 0.55)",
                                                color: "white",
                                                borderBottom: "1px solid rgb(200, 200, 200)",
                                            }}
                                        >
                                            <th className="col-2">Document Title</th>
                                            <th className="col-2"> Download Document</th>
                                            <th className="col-2"> View Document</th>
                                            <th className="col-2">Document Details</th>
                                            {/* <th className="col-2"> Actions </th> */}
                                        </tr>
                                        {/* )} */}
                                    </thead>
                                    <tbody>
                                        {list &&
                                            list.data &&
                                            list.data.map((ele) => (
                                                <tr>
                                                    <td style={{ textAlign: "center" }}>{ele.docTitle}</td>
                                                    <td style={{ cursor: "pointer", textAlign: "center" }}>
                                                        {" "}
                                                        <i
                                                            className="fas fa-download"
                                                            onClick={() =>
                                                                handleDownloadFile(ele.url, ele.docTitle)
                                                            }
                                                            title={"Download"}
                                                            style={{ color: "#810e36", fontSize: "22px" }}
                                                        />
                                                    </td>
                                                    <td style={{ cursor: "pointer", textAlign: "center" }}>
                                                        {" "}
                                                        <InsertDriveFileIcon
                                                            onClick={() => viewDocFile(ele.url, ele.docTitle)}
                                                            title={"View"}
                                                            style={{ color: "#810e36", fontSize: "22px" }}
                                                        />
                                                    </td>


                                                    <td style={{ textAlign: "center" }}>
                                                        <p style={{ color: "#810e36", fontSize: "10px" }} >{
                                                            ele.docTitle == 'Medical Council Registration Certificate No' ?
                                                                <>
                                                                    <p style={{ color: "#810e36", fontSize: "10px" }}>{doctorDetail && doctorDetail.mcrcval ? doctorDetail.mcrcval : '---'}</p>
                                                                    <p style={{ color: "#810e36", fontSize: "10px" }}>{doctorDetail && doctorDetail.mcrcval && ele.certExpiryDate ? "Expiry: " + ele.certExpiryDate : ''}</p>
                                                                </>
                                                                : ele.docTitle == 'PAN Card' ?
                                                                    doctorDetail && doctorDetail.panno ? doctorDetail.panno : '---'
                                                                    : ele.docTitle == 'Aadhaar Card' ?
                                                                        doctorDetail && doctorDetail.adhaarno ? doctorDetail.adhaarno : '---'
                                                                        : ele.docTitle == 'Indemnity Insurance Copy' ?
                                                                            <p style={{ color: "#810e36", fontSize: "10px" }}>{doctorDetail && doctorDetail.indemnityexpiry ? "Expiry: " + dateFormatter(doctorDetail.indemnityexpiry) : ''}</p>
                                                                            : ele.docTitle == 'Cancelled Cheque (Bank Details)' ?
                                                                                <>
                                                                                    <p style={{ color: "#810e36", fontSize: "10px" }}>{doctorDetail && doctorDetail.bankname ? doctorDetail.bankname : ''}</p>
                                                                                    <p style={{ color: "#810e36", fontSize: "10px" }}>{doctorDetail && doctorDetail.bankaccno ? "A/C: " + doctorDetail.bankaccno : ''}</p>
                                                                                </>
                                                                                : ele.docTitle == 'Address Proof' ? doctorDetail && doctorDetail.State && doctorDetail.PinCode && doctorDetail.City && doctorDetail.Address ? doctorDetail.City + ', ' + doctorDetail.State : '---'
                                                                                    : '---'
                                                        }</p>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            autoFocus
                            onClick={() => { console.log(doctorDetail); history.push("/ViewDocument", { state: { id: doctorDetail.id, name: doctorDetail.name } }) }}
                            color="primary"
                            variant="contained"
                        >
                            Edit
                        </Button>
                        <Button
                            autoFocus
                            onClick={closeDocumentModal}
                            color="secondary"
                            variant="contained"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    fullWidth={true}
                    maxWidth="md"
                    onClose={() => {
                        setOpenDialog(false);
                    }}
                    aria-labelledby="customized-dialog-title"
                    open={openDialog}
                    disableBackdropClick
                >
                    <DialogTitle
                        id="customized-dialog-title"
                        // onClose={handlerClickcloseCommercials}
                        style={{ textAlign: "center" }}
                    ></DialogTitle>
                    <DialogContent dividers></DialogContent>
                    <Iframe
                        url={urlData}
                        width="900px"
                        height="800px"
                        id="myId"
                        className="myClassname"
                        display="initial"
                        position="relative"
                    />

                    <DialogActions>
                        <Button
                            onClick={() => {
                                setOpenDialog(false);
                            }}
                            color="secondary"
                            variant="contained"
                            size="small"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            {/* new all agreement modal */}
            <Modal
                size="lg"
                scrollable
                dialogClassName="modalStandard"
                centered
                aria-labelledby="all-agreement-modal-sizes-title-lg"
                show={isAgreementModalOpen}
                onHide={() => { setIsAgreementModalOpen(false) }}
            >
                <Modal.Header className="modalHeader" style={{ backgroundColor: '#810e36' }} closeButton>
                    <Modal.Title className="modalTitle">All The Agreements</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table borderless style={{ display: 'inline-table', width: '-webkit-fill-available', textAlign: 'center' }}>
                        <thead>
                            <tr
                                style={{
                                    borderBottom: "1px solid rgb(200, 200, 200)",
                                }}
                            >
                                <th> Name </th>
                                <th> Date </th>
                                <th> Download </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                agreementList === "" ?
                                    <tr><td colSpan="3">Loading....</td></tr>
                                    :
                                    agreementList.length === 0 ?
                                        <tr><td colSpan="3">No Agreement Generated Yet!</td></tr>
                                        :
                                        agreementList.map((ag, ai) => (
                                            <tr key={ai}>
                                                <td>{ag.digio_doc_file_name}</td>
                                                <td>{new Date(ag.digio_doc_date).toDateString()}</td>
                                                <td><i class="fa fa-download" aria-hidden="true" style={{ color: "#810e36", cursor: "pointer" }} onClick={() => { viewSignedAgreementDoc(ag.digio_doc_id, ag.digio_doc_file_name, ag.type === 'manual') }}></i></td>
                                            </tr>
                                        ))
                            }
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center"></Modal.Footer>
            </Modal>

            {/* Edit LT ID MODAL */}
            <Dialog
                fullWidth={true}
                maxWidth="md"
                onClose={() => { setLTeditRowDetails(null); }}
                aria-labelledby="customized-dialog-title"
                open={ltEeditRowDetails !== null}
            >
                <DialogTitle
                    id="customized-dialog-title"
                    onClose={() => { setLTeditRowDetails(null); }}
                    style={{ textAlign: "center" }}
                >
                    LT EMAIL ID Change for Dr {ltEeditRowDetails?.name}
                </DialogTitle>
                <DialogContent dividers>
                    <label>LT EMAIL ID </label>
                    <input
                        placeholder="LT Email ID"
                        value={LTid}
                        type="email"
                        onChange={(e) => { setLTid(e.target.value) }}
                        style={{ color: "black", border: "1px solid black" }}
                        className="form-control"
                    />
                    <label>Remarks </label>
                    <textarea
                        placeholder="Remarks"
                        value={modalRemarks}
                        type="text"
                        onChange={(e) => { setModalRemarks(e.target.value) }}
                        style={{ color: "black", border: "1px solid black" }}
                        className="form-control"
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={() => updateAction()}
                        color="secondary"
                        variant="contained"
                        size="small"
                    >
                        Yes, Change
                    </Button>
                    <Button
                        autoFocus
                        onClick={() => { setLTeditRowDetails(null); }}
                        color="primary"
                        variant="contained"
                        size="small"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ManageDoctors