import React, { useEffect, useState } from "react";
import Sidebar from "../../Components/Sidebar";
import axios from "axios";
import { Card, Table, Toast } from "react-bootstrap";
import { agreementState } from "../../CommomMethods/States";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Navmain from "../../Components/Navbar";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Backdrop from "@material-ui/core/Backdrop";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import ConfirmDialog from "../../Components/ConfirmDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import MaterialTable from "material-table";
import { listusers } from "../../actions/userActions";
import { agreementlist } from "../../actions/agreementActions";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { postRecord, putRecord } from "../../CommomMethods/Save";
import { APIURL } from "../../CommomMethods/Fetch";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import moment from 'moment';
import { dateFormatter } from "../../actions/createPositionActions";
const { onboardState } = require("../../CommomMethods/States");


const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
}));
const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
const APIONBOARD = APIURL() + "onboard/renew";

export default function RenewalProcess() {
  const tables = myTableStyle();
  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };
  const [backDropOpen, setBackDropOpen] = useState(false);
  const backClasses = backDropStyle();

  const [onboardData, setOnboardData] = useState([]);
  const [openr, setOpenr] = React.useState(false);
  const [openOp, setOpenOp] = React.useState(false);
  const [openIp, setOpenIp] = React.useState(false);
  const [openCommercials, setOpenCommercials] = React.useState(false);
  const [renewDialog, setRenewDialog] = useState(false);
  const [disDialog, setDisDialog] = useState(false);

  const [docid, setDocid] = useState("");
  const [renewStat, setRenewStat] = useState("");
  const [doctorDetail, setDoctorDetail] = useState([]);
  const [doctorOpDetail, setDoctorOpDetail] = useState([]);

  const [doctorIpDetail, setDoctorIpDetail] = useState([]);
  const [doctorOpCommercial, setDoctorOpCommercial] = useState([]);
  const [doctorIpCommercial, setDoctorIpCommercial] = useState([]);
  const [remark, setRemark] = useState("");
  const [allRecord, setAllrecord] = useState({});
  const [staus, setStatus] = useState(false);
  const [load, setLoad] = useState(false);
  const [discontinueDate, setDiscontinueDate] = useState(null);

  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  const agreementList = useSelector((state) => state.agreementList);
  const { agreements } = agreementList;


  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(agreementlist());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  today = dd + '-' + mm + '-' + yyyy;
  console.log(today, "dateee");
  useEffect(() => {
    axios
      .get(APIURL() + "onboard/currentstatusrenewal/" + user.user.id)
      .then((res) => {
        if (user.user.roles === 1) {
          setOnboardData(res.data.data.filter((item) => item.status === 3 || item.status === 4));
        } else {
        setOnboardData(res.data.data);
        }
        // var m = res.data.data.map(y => {
        //   if (y.agreementexpirydate == today) {
        //     setRenewStat("Renew");
        //   }else if(y.agreementexpirydate < today){
        //     setRenewStat("Renew");
        //   }else if(y.agreementexpirydate > today){
        //     setRenewStat("Expired");
        //   }
        // })
      })
      .catch((error) => {
      });
  }, [staus]);

  console.log(onboardData, "onboardDataonboardDataonboardData");
  function getDocdetail(id) {
    axios
      .get(APIURL() + "onboard/previewOne/" + id)
      .then((res) => {
        setDoctorDetail(res.data);
        setDoctorIpDetail(res.data.doctorIPParameter);
        setDoctorOpDetail(res.data.doctorOPParameter);
        setDoctorOpCommercial(res.data.doctorOpCommercial);
        setDoctorIpCommercial(res.data.doctorIpCommercial);

      })
      .catch((error) => {
      });
  }

  useEffect(() => {
    getDocdetail(docid);
  }, [docid]);

  const handlerClickOpen = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }

    setOpenr(true);
  };
  const handlerClose = () => {
    setOpenr(false);
  };
  const handlerClickOpenOp = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }

    setOpenOp(true);
  };
  const handlerCloseOp = () => {
    setOpenOp(false);
  };
  const handleDiscontinue = () => {
    if(discontinueDate !== null){
      setBackDropOpen(true);
      setStatus(true);
      let status = {
        status: 4,
        user_id: user.user.id,
        role: user.user.roles,
        activity: "Sent for Discontinue",
        name: allRecord.name,
        request_id: allRecord.request_id,
        currentAction: "DISCONTINUE_INIT",
        nextAction: "COO_DISCONTINUE",
        doe:discontinueDate
      }
      if(user.user.roles === 1){
        status = {
          status: 3,
          user_id: user.user.id,
          role: user.user.roles,
          activity: "Discontinued by COO",
          name: allRecord.name,
          request_id: allRecord.request_id,
          currentAction: "COO_DISCONTINUE",
          nextAction: "DISCONTINUED",
          doe:discontinueDate
        }
      }
      axios.put(APIURL() + "onboard/agreementexpiryupdate/" + docid, status).then((res) => {
        if (res.data) {
          setBackDropOpen(false);
          setDisDialog(false);
          setStatus(false);
          // setRenewStat("Renewed Successfully");
          toast.success(user.user.roles === 1? 'Successfully Discontinued':'The Request Sent for COO Approval');

        }
      })
    }
    else{
      console.log('select the date first!')
      toast.error('Select Date First!');

    }
  }

  const handleDiscontinueApprove = (row) => {
    setBackDropOpen(true);
    setStatus(true);
    const status = {
      status: 3,
      user_id: user.user.id,
      role: user.user.roles,
      activity: "Discontinued by COO",
      name: row.name,
      request_id: row.request_id,
      currentAction: "COO_DISCONTINUE",
      nextAction: "DISCONTINUED",
    }
    axios.put(APIURL() + "onboard/agreementexpiryupdate/" + row.doctorId, status).then((res) => {
      if (res.data) {
        setBackDropOpen(false);
        setDisDialog(false);
        setStatus(false);
        // setRenewStat("Renewed Successfully");
      }
    })
  }

  const handlerClickOpenIp = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }

    setOpenIp(true);
  };

  const handlerClickOpenCommercials = (row) => {
    if (row.doctorId) {
      setDocid(row.doctorId);
      getDocdetail(row.doctorId);
    }
    setOpenCommercials(true);
  };

  const handlerClickcloseCommercials = () => {
    setOpenCommercials(false);
  };

  const handledis = () => {
    setOpenCommercials(false);
  };
  const handlerCloseIp = () => {
    setOpenIp(false);
  };

  const openDailog = (row) => {
    console.log(row);
    setRenewDialog(true);
    setDocid(row.doctorId);
    setAllrecord(row);
  };

  const openDailogDiscontinue = (row) => {
    console.log(row);
    setDisDialog(true);
    setDocid(row.doctorId);
    setAllrecord(row);
   
    setDiscontinueDate(row.doe?row.doe:null);
  };

  const [expTodate, setExpTodate] = useState("");
  const [expFromdate, setExpFromdate] = useState("");

  const handleexpiryFromDate = (event) => {
    const { name, value } = event.target;
    setExpFromdate(value);
  };

  const handleexpiryToDate = (event) => {
    const { name, value } = event.target;
    setExpTodate(value);
  };

  const handleRenewExpiry = () => {
    setLoad(true);
    setBackDropOpen(true);
    const agreementexpirydate = {
      agreementexpirydate: expTodate
    }
    axios.put(APIURL() + "onboard/agreementexpiryupdate/" + docid, agreementexpirydate).then((res) => {
      if (res.data) {
        setBackDropOpen(false);
        setRenewDialog(false);
        // setRenewStat("Renewed Successfully");
      }
      toast.success(res.data.message);
      setLoad(false);

    })
  };

  // const handleRenewExpiry = () => {
  //   setStatus(false);
  //   axios.put(APIURL()+ "onboards/agreementexpiryupdate/"+docid, expTodate).then((res) => {
  //   })
  //   allRecord.agreementexpirydate = expFromdate;
  //   allRecord.doj = expTodate;
  //   allRecord.id = 0;
  //   doctorOpDetail.forEach((element) => {
  //     element.id = 0;
  //   });
  //   doctorIpDetail.forEach((element) => {
  //     element.id = 0;
  //   });
  //   doctorOpCommercial.forEach((element) => {
  //     element.id = 0;
  //   });
  //   doctorIpCommercial.forEach((element) => {
  //     element.id = 0;
  //   });
  //   getDocdetail(docid);
  //   const postData = {
  //     data: allRecord,
  //     opservice: doctorOpDetail,
  //     ipservice: doctorIpDetail,
  //     opcommercial: doctorOpCommercial,
  //     ipcommercial: doctorIpCommercial,
  //   };
  //   postRecord(APIONBOARD, postData).then((res) => {
  //     setRenewDialog(false);
  //     setStatus(true);
  //   });
  // };
  const handleApprove = (ele) => {
    // alert("The Agreement is APPROVED")
    const obj = {
      doctorId: ele.doctorId,
      name: ele.name,
      currentAction: agreementState.init.state,
      currentActionBy: users[0].id,
      nextAction: agreementState.hr_revision.state,
      status: 1,
    };
    const obj1 = {
      doctorId: ele.doctorId,
      name: ele.name,
      currentAction: onboardState.rd.state,
      currentActionBy: users[0].id,
      nextAction: onboardState.done.state,
      status: 1,
    };
    axios
      .post(APIURL() + "onboard/action/", obj1)
      .then((res) => {
      });
    axios
      .post(APIURL() + "onboard/agreementAction/", obj)
      .then((res) => {
      });
    window.location.reload();
  };
  var a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  var b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  var n;
  function inWords(num) {
    if ((num = num.toString()).length > 9) return "overflow";
    n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        "only "
        : "";
    return str;
  }

  var amount = parseInt(
    doctorDetail.doctorCommercial &&
    doctorDetail.doctorCommercial[0] &&
    doctorDetail.doctorCommercial[0].ctcmonth
  );

  var addamount = parseInt(
    doctorDetail.doctorCommercial &&
    doctorDetail.doctorCommercial[0] &&
    doctorDetail.doctorCommercial[0].additionalfee
  );

  const Remark = (e) => {
    setRemark(e.target.value);
  };
  const updateRecord = () => {
    const obj = {
      doctorOpDetail,
      doctorIpDetail,
      doctorOpCommercial,
      doctorIpCommercial,
    };
    axios
      .put(APIURL() + "onboard/updateFourtables/" +
        doctorOpDetail[0].doctorId,
        obj
      )
      .then((res) => {
      });
    if (remark != "") {
      const obj1 = {
        doctorId: doctorDetail.doctorDetails.id,
        name: doctorDetail.doctorDetails.name,
        currentAction: onboardState.rd.state,
        currentActionBy: 1,
        nextAction: onboardState.ch.state,
        remark: remark,
        status: 1,
      };
      axios
        .post(APIURL() + "onboard/action/", obj1)
        .then((res) => {
        });
      window.location.reload();
    }
  };
  console.log(onboardData, "onboardData=====")
  const [muiTableKey, setMuiTableKey] = React.useState(0);
  return (
    <>
      <Sidebar />

      <div className="main-content side-content pt-0">
        <Navmain />
        <Card.Body
          // style={bodyStyle}
          style={{ width: "100%", marginTop: "4rem" }}
        >
          <MaterialTable
            columns={[
              { title: "Doctor Name", field: "name" },
              { title: "Doctor Type", field: "category" },
              { title: "Doctor Email", field: "email" },
              { title: "Mobile", field: "mobile" },
              {
                title: "Joining Date",
                field: "doj",
                render: (row) => (
                  <>{row.doj ? moment(row.doj).format("YYYY-MM-DD") : row.doj}</>
                ),
              },
              {
                title: "Commencement Date",
                field: "cdoj",
                render: (row) => (
                  <>{row.cdoj ? moment(row.cdoj).format("YYYY-MM-DD") : row.cdoj}</>
                ),
              },
              {
                title: "Agreement Expiry Date",
                field: "agreementexpirydate",
                render: (row) => (
                  <>{row.doj && row.contractterm && parseInt(row.contractterm) > 0 ? moment(moment(row.doj).add(row.contractterm, 'M').calendar()).format("YYYY-MM-DD") : ''}</>
                ),
              },
              {
                title: "Discontinuation Date",
                hidden: user.user.roles !== 1,
                field: "doe",
                render: (row) => (
                  <>{row.doe  ? moment(moment(row.doe)).format("YYYY-MM-DD") : '-'}</>
                ),
              },
              {
                title: "Renew",
                field: "Action",
                hidden: user.user.roles === 1 ? true : false,
                filtering: false,
                render: (row) => (
                  row.status === 3 ? (
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disabled
                    >
                      Discontinued
                    </Button>
                  ) : row.status === 4 ? (
                    <Button
                      size="small"
                      color="red"
                      variant="contained"
                      disabled
                    >
                      Sent to COO
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                    >
                      <Link
                        to={{
                          pathname: "/onboard-doctor",
                          state: { data: row },
                        }}
                        style={{ color: 'white', fontSize: 'inherit' }}
                      >
                        Renew
                      </Link>
                    </Button>
                  )
                ),
              },
              {
                title: "Discontinue",
                field: "Action",
                filtering: false,
                render: (row) => (
                  row.status === 3 ? (
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      disabled
                    >
                      Discontinued
                    </Button>
                  ) : row.status === 4 ? user.user.roles === 1 ? (
                    <Button
                      size="small"
                      color="secondary"
                      variant="contained"
                      onClick={() => {
                        // handleDiscontinueApprove(row);
                        openDailogDiscontinue(row);
                      }}
                    >
                      Discontinue
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      color="red"
                      variant="contained"
                      disabled
                    >
                      Sent to COO
                    </Button>
                  ) : user.user.roles === 1 ? "" : (
                    <IconButton
                      style={{
                        color: "#810e36",
                        textAlign: "center"
                      }}
                      onClick={() => {
                        openDailogDiscontinue(row);
                      }}
                    >
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  )
                ),
              },
            ]}
            data={onboardData}
            actions={[
              {
                icon: () => <ClearAllIcon />,
                tooltip: "Clear All Filters",
                isFreeAction: true,
                onClick: (event) => {
                  setMuiTableKey(muiTableKey + 1); // set new key causing remount
                }
              }
            ]}
            options={{
              filtering: true,
              sorting: true,
              exportButton: true,
              pageSize: 5,
              pageSizeOptions: [5, 10, 50, 100, 200, 500],
              search: true,
            }}
            icons={{
              Filter: "filter",
            }}
            title="Agreement Renewal-Process"
          />
        </Card.Body>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Doctor Detail
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Name:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].fullname}
          </Typography>
          <Typography gutterBottom>
            Email-Id:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].email}
          </Typography>
          <Typography gutterBottom>
            Mobile:{" "}
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].mobile}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerCloseOp}
        aria-labelledby="customized-dialog-title"
        open={openOp}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerCloseOp}
          style={{ textAlign: "center" }}
        >
          <h5 style={{ margin: ".4rem", textAlign: "center" }}>
            <b>Doctor Details</b>
          </h5>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <b>Name: </b>
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].fullname}
          </Typography>
          <Typography gutterBottom>
            <b> Email-Id: </b>
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].email}
          </Typography>
          <Typography gutterBottom>
            <b> Mobile: </b>
            {doctorDetail &&
              doctorDetail.doctorCommercial &&
              doctorDetail.doctorCommercial[0] &&
              doctorDetail.doctorCommercial[0].mobile}
          </Typography>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemGroup" },
                { title: "Revenue", field: "oprevenue" },
                { title: "Pay Out", field: "oppayout" },
                { title: "Net Amount", field: "netamount" },
              ]}
              data={doctorOpDetail}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              title="Op Details"
            />
            <br />
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemGroup" },
                { title: "Revenue", field: "revenue" },
                { title: "Pay Out", field: "payout" },
                { title: "Net Amount", field: "ipTotalAmount" },
              ]}
              data={doctorIpDetail}
              options={{
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              title="Ip Details"
            />
            <br />
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemgroup" },

                { title: "Commercial Term", field: "commercialterm" },
              ]}
              // data={doctorDetail.doctorIPParameter}
              data={doctorOpCommercial}
              // editable={{
              //   onRowUpdate: (newData, oldData) =>
              //     new Promise((resolve, reject) => {
              //       setTimeout(() => {
              //         const dataUpdate = [...doctorOpCommercial];
              //         const index = oldData.tableData.id;
              //         dataUpdate[index] = newData;
              //         setDoctorOpCommercial([...dataUpdate]);

              //         resolve();
              //       }, 1000);
              //     }),
              // }}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              title="Op Commercial"
            />
            <br />
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemgroup" },

                { title: "Commercial Term", field: "commercialterm" },
              ]}
              // data={doctorDetail.doctorIPParameter}
              data={doctorIpCommercial}
              // editable={{
              //   onRowUpdate: (newData, oldData) =>
              //     new Promise((resolve, reject) => {
              //       setTimeout(() => {
              //         const dataUpdate = [...doctorIpCommercial];
              //         const index = oldData.tableData.id;
              //         dataUpdate[index] = newData;
              //         setDoctorIpCommercial([...dataUpdate]);

              //         resolve();
              //       }, 1000);
              //     }),
              // }}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              title="Ip Commercial"
            />
          </Paper>
          <br />
          <label>
            Remark{" "}
            <i
              class="fa fa-asterisk"
              style={{
                color: "red",
                fontSize: "10px",
                marginLeft: "3px",
                marginTop: "15px",
              }}
              aria-hidden="true"
            ></i>
          </label>
          <textarea
            placeholder="Remark"
            type="text"
            onChange={Remark}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
            required
          />
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={updateRecord}
            color="primary"
            variant="contained"
            size="small"
          >
            Update Data
          </Button>
          <Button
            autoFocus
            onClick={handlerCloseOp}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerCloseIp}
        aria-labelledby="customized-dialog-title"
        open={openIp}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerCloseIp}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "Item Group", field: "itemGroup" },
                { title: "Revenue", field: "revenue" },
                { title: "Pay Out", field: "payout" },
                { title: "Net Amount", field: "ipTotalAmount" },
              ]}
              data={doctorDetail.doctorIPParameter}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              title="Ip Details"
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerCloseIp}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClickcloseCommercials}
        aria-labelledby="customized-dialog-title"
        open={openCommercials}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClickcloseCommercials}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers>
          <Paper className={tables.root}>
            <MaterialTable
              columns={[
                { title: "Item Group/Procedures", field: "itemGroup" },
                { title: "Commercial Term", field: "CommercialTerm" },
              ]}
              data={doctorDetail.doctorIPParameter}
              options={{
                // filtering: true,
                sorting: true,
                exportButton: true,
                pageSize: 5,
                pageSizeOptions: [5, 10, 50, 100, 200, 500],
                search: true,
              }}
              title="Commercial Details"
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlerClickcloseCommercials}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        onClose={() => {
          setDisDialog(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={disDialog}
        disableBackdropClick
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handledis}
          style={{
            backgroundColor: "#810e36",
            color: "white",
            textAlign: "center"
          }}
        >Discontinue {allRecord?.name}</DialogTitle>
        <DialogContent dividers style={{
          textAlign: "center"
        }}>
          {/* <Paper className={tables.root}> */}
          {/* {JSON.stringify(allRecord)} */}
          {/* <div class="form-group"> */}
            <label for="discontinueDate">Discontinuation Date</label>
            <input
              className="form-control"
              type="date"
              name="discontinueDate"
              id="discontinueDate"
              placeholder="date placeholder"
              value={discontinueDate}
              onChange={(e)=>setDiscontinueDate(e.target.value)}
            />
          {/* </div> */}
          {/* </Paper> */}
        </DialogContent>
        <DialogActions>
        {user.user.roles === 1 ? '' : <h6 className="float-left"><span className="text-danger">*</span>COO Permission Required!</h6>}
          <Button
            onClick={() => {
              handleDiscontinue();
            }}
            // color="success"
            variant="contained"
            size="small"
            style={{
              backgroundColor: "#810e36",
              color: "white",
              textAlign: "center"
            }}
          >
            Discontinue
          </Button>
          <Button
            onClick={() => {
              setDisDialog(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="sm"
        onClose={() => {
          setRenewDialog(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={renewDialog}
        disableBackdropClick
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClickcloseCommercials}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers>
          <Paper className={tables.root}>
            <label for="pg-relexp">
              <h6> Expiry Date </h6>
            </label>
            <div className="form-group">
              <input
                className="form-control form-doctor"
                type="date"
                placeholder="Post PG Degree Relevant Experience"
                id="expTodate"
                style={{ color: "#000000" }}
                value={expTodate}
                name="expTodate"
                onChange={handleexpiryToDate}
              />
            </div>
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleRenewExpiry();
            }}
            color="success"
            variant="contained"
            size="small"
          >
            Renew
          </Button>
          <Button
            onClick={() => {
              setRenewDialog(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        className={backClasses.backdrop}
        open={backDropOpen}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

    </>
  );
}
