import axios from "axios";

export const APIURL = () => {
  return process.env.REACT_APP_API_PATH;
};
export const fetchAllRecords = (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
        } else {
          reject(err);
        }
      });
  });
};

export const fetchRecordByID = (url, id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url + id)
      .then((res) => {
  console.log(res, "1212121");

        resolve(res);
      })
      .catch((err) => reject(err));
  });
};
