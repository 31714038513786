import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Container, Row, Col, Table } from "react-bootstrap";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";
import { agreementState } from "../../../CommomMethods/States";
import { listusers } from "../../../actions/userActions";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import html2PDF from 'jspdf-html2canvas';
import moment from 'moment'
import { FaArrowCircleDown } from 'react-icons/fa';
import AgreementComponent from '../../../Components/AgreementComponent1'
import AgreementMgip from '../../../Components/AgreementMGip'
import { Helmet } from "react-helmet";
import { APIURL } from "../../../CommomMethods/Fetch";
import './../../../index.css';
import { dateFormatter } from '../../../actions/createPositionActions';
import { numberWithCommas } from "../../../actions/createPositionActions";
function FixedMG(props) {
  const [lists, setList] = useState([]);
  const [annexure, setAnnexure] = useState([]);
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  useEffect(() => {
    axios
      .get(APIURL() + "agreementannexures/getdata/getprocedures")
      .then((res) => {
        console.log(res.data.data, "res-ponse");
        setAnnexure(res.data.data);
      });
  }, []);
  const formStyle = {
    // borderRadius: "5px",
    // border: "1px solid black",
    border: "white",
    marginTop: "50px",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };

  console.log(props, "$$$$$$$$$$$$$$");
  const confirmAgreement = (id) => {
    const obj = {
      doctorId: props.recData.doctorCommercial[0].doctorId,
      name: props.recData.doctorCommercial[0].fullname,
      currentAction: agreementState.hr_revision.state,
      currentActionBy: users[0].id,
      nextAction: agreementState.dr_accepted.state,
      status: 1,
    };

    axios.post(APIURL() + "onboard/agreementAction/", obj).then((res) => {
      toast.success("Successfully Confirmed");
      window.location.reload();
    });
  };

  var k =
    props &&
    props.recData &&
    props.recData.doctorDetails &&
    props.recData.doctorDetails.id;
  useEffect(() => {
    axios
      .get(APIURL() + "documents/list/" + k)
      .then((res) => res.data)
      .then((rows) => {
        setList(rows);
      });
  }, [k]);

  var a = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  var b = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  var n;
  function inWords(num) {
    if ((num = num.toString()).length > 9) return "overflow";
    n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        "only "
        : "";
    return str;
  }
  function yearinWords(num) {
    if ((num = num.toString()).length > 9) return "overflow";
    n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (a[Number(n[1])] || b[n[1][0]] + " " + a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (a[Number(n[2])] || b[n[2][0]] + " " + a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (a[Number(n[3])] || b[n[3][0]] + " " + a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (a[Number(n[4])] || b[n[4][0]] + " " + a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" ? "and " : "") +
        (a[Number(n[5])] || b[n[5][0]] + " " + a[n[5][1]]) +
        ""
        : "";
    return str;
  }
  var amount = parseInt(
    props &&
    props.recData &&
    props.recData.fixedMg &&
    props.recData.fixedMg[0].settlementterm
  );
  var Mgterm = parseInt(
    props &&
    props.recData &&
    props.recData.fixedMg &&
    props.recData.fixedMg[0].mgperiod
  );

  const pages = document.getElementsByClassName("page");
  let page = document.getElementById("page");
  const generatePdf = () => {
    html2PDF(page, {
      jsPDF: {
        format: "a4",
      },
      imageType: "image/jpeg",
      output: "./pdf/Agreement.pdf",
    });
  };

  const printAll = () => {
    // const printableElements = document.getElementById("page").innerHTML;
    // window.print();
    var container = document.getElementById("containerscrollbtn").innerHTML = "";
    var divContents = document.getElementById("page").innerHTML;
    var a = window.open("", "", "height=500, width=500");
    a.document.write("<html><body>");
    // a.document.write("<style> @page{size: A4; margin: 7rem 4rem; } p{ page-break-inside: avoid;}</style>");
    // style={{ position: "fixed", right: "0px", color: "#d6d6d4", }}
    // body{counter-reset: pageNo;} footer{counter-increment: pageNo; content:counter(pageNo); position:fixed; bottom: 5px; right: 0px;}
    a.document.write(
      "<style> @page{size: A4; margin: 5rem;} p{page-break-inside: avoid;} header{color:#d6d6d4;marginBottom:5rem;display:block }@media print {@page{counter-reset: pageNo;} footer::after{counter-increment: pageNo; content:'Page | 'counter(pageNo); position:fixed; bottom: 5px; right: 0px;}} </style>"
    );
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    a.print();
  };

  // date as string
  console.log(props);
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;
  const myDate = today;
  // const myDate = props && props.recData && props.recData.doctorCommercial && props.recData.doctorCommercial[0].stampdate && props.recData.doctorCommercial[0].stampdate.substring(0, 10);
  const date = new Date(myDate + " 0:0:0");

  const expirydate = moment(
    props &&
    props.recData &&
    props.recData.doctorCommercial &&
    props.recData.doctorCommercial.length &&
    props.recData.doctorCommercial[0].indemnityexpiry,
    "YYYY-MM-DD"
  ).format("DD-MM-YYYY");
  var indemnityExpiryDate = moment(
    props &&
    props.recData &&
    props.recData.doctorCommercial &&
    props.recData.doctorCommercial[0] &&
    props.recData.doctorCommercial[0].indemnityexpiry,
    "YYYY-MM-DD"
  ).format("DD-MM-YYYY");
  var MCRCDate = moment(
    props &&
    props.recData &&
    props.recData.doctorCommercial &&
    props.recData.doctorCommercial[0] &&
    props.recData.doctorCommercial[0].mcrdate,
    "YYYY-MM-DD"
  ).format("DD-MM-YYYY");
  var commencementDate = moment(
    props &&
    props.recData &&
    props.recData.doctorCommercial &&
    props.recData.doctorCommercial[0] &&
    props.recData.doctorCommercial[0].cdoj &&
    props.recData.doctorCommercial[0].cdoj,
    "YYYY-MM-DD"
  ).format("DD-MM-YYYY");
  var dates = moment(
    props &&
    props.recData &&
    props.recData.doctorCommercial &&
    props.recData.doctorCommercial[0].createdAt &&
    props.recData.doctorCommercial[0].createdAt.substring(0, 10),
    "YYYY-MM-DD"
  ).format("DD-MM-YYYY");

  // var ipArray=[];
  var dt =
    props &&
    props.recData &&
    props.recData.doctorCommercial &&
    props.recData.doctorCommercial[0].cdoj &&
    props.recData.doctorCommercial[0].cdoj.substring(0, 10);
  var term =
    props &&
      props.recData &&
      props.recData.doctorCommercial && props.recData.doctorCommercial[0].contractlength ?
      (props.recData.doctorCommercial[0].contractlength.split(' ')[0]) * 12 : "";

  console.log(dt, term, "1props && props.recData")
  var futureMonth = moment(moment(dt).add(term, 'M').calendar()).format("DD-MM-YYYY");
  console.log(props && props.recData, "props && props.recData")
  return (
    <>
      <Helmet>
        <title
          style={{
            color: "gray",
            fontSize: "20px",
            display: "block !important",
            marginRight: "20rem",
          }}
        >
          {props &&
            props.recData &&
            props.recData.doctorCommercial &&
            props.recData.doctorCommercial[0].fullname &&
            props.recData.doctorCommercial[0].fullname.toString().toUpperCase()}
        </title>
      </Helmet>
      <div className="main-content  pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <br />

            <Container fluid style={{ padding: "0px", marginTop: "40px" }}>
              <div
                id="page"
                style={{
                  width: "99%",
                }}
              >
                <header
                  style={{
                    position: "fixed",
                    right: "0px",
                    top: "-24px",
                    marginTop: "0px",
                  }}
                >
                  {" "}
                  <h4>
                    {props &&
                      props.recData &&
                      props.recData.doctorCommercial &&
                      props.recData.doctorCommercial[0].fullname}
                  </h4>
                </header>
                <br />
                <br />
                <br />
                <br />
                <div id="carddiv">
                  <Card style={formStyle}>
                    <Card.Body style={{ padding: "50px", marginTop: "40rem", border: "white" }} className="bodyAgmt" id="section1">
                      {"    "} <a href="#section2" style={{ fontSize: "25px", color: "red", paddingLeft: "20px", position: "fixed", top: "90px", left: "10px", zIndex: "99" }} id="containerscrollbtn"><FaArrowCircleDown></FaArrowCircleDown></a>
                      <Container fluid className="cardAgmt" style={{ border: "white" }}>
                        <h6 className="titleAgmt" ><strong>AGREEMENT FOR PROVIDING PROFESSIONAL SERVICES</strong></h6>

                        <p className="subtitleAgmt"><strong>(MG CONSULTancy AGREEMENT)</strong>

                        </p>
                        <p>
                          <strong>
                            {" "}
                            This consultancy agreement (the ‘Agreement’) is made and
                            entered into this {" "}
                            {props && props.recData && props.recData.doctorCommercial && props.recData.doctorCommercial[0] && props.recData.doctorCommercial[0].stampdate && props.recData.doctorCommercial[0].stampdate !== '0000-00-00' ?
                              dateFormatter(props.recData.doctorCommercial[0].stampdate).split('-')[0] : "___"}<sup>{props && props.recData && props.recData.doctorCommercial && props.recData.doctorCommercial[0] && props.recData.doctorCommercial[0].stampdate ?
                                dateFormatter(props.recData.doctorCommercial[0].stampdate).split('-')[0][1] == "1" ? "st" : props && props.recData && props.recData.doctorCommercial &&
                                  dateFormatter(props.recData.doctorCommercial[0].stampdate).split('-')[0][1] == "2" ? "nd" : props && props.recData && props.recData.doctorCommercial &&
                                    dateFormatter(props.recData.doctorCommercial[0].stampdate).split('-')[0][1] == "3" ? "rd" : "th" : "__"}</sup> day of {props && props.recData && props.recData.doctorCommercial && props.recData.doctorCommercial[0] && props.recData.doctorCommercial[0].stampdate && props.recData.doctorCommercial[0].stampdate !== '0000-00-00' ?
                                      dateFormatter(props.recData.doctorCommercial[0].stampdate).split('-')[1] == '01' ? "January" : props && props.recData && props.recData.doctorCommercial &&
                                        dateFormatter(props.recData.doctorCommercial[0].stampdate).split('-')[1] == '02' ? "February" : props && props.recData && props.recData.doctorCommercial &&
                                          dateFormatter(props.recData.doctorCommercial[0].stampdate).split('-')[1] == '03' ? "March" : props && props.recData && props.recData.doctorCommercial &&
                                            dateFormatter(props.recData.doctorCommercial[0].stampdate).split('-')[1] == '04' ? "April" : props && props.recData && props.recData.doctorCommercial &&
                                              dateFormatter(props.recData.doctorCommercial[0].stampdate).split('-')[1] == '05' ? "May" : props && props.recData && props.recData.doctorCommercial &&
                                                dateFormatter(props.recData.doctorCommercial[0].stampdate).split('-')[1] == '06' ? "June" : props && props.recData && props.recData.doctorCommercial &&
                                                  dateFormatter(props.recData.doctorCommercial[0].stampdate).split('-')[1] == '07' ? "July" : props && props.recData && props.recData.doctorCommercial &&
                                                    dateFormatter(props.recData.doctorCommercial[0].stampdate).split('-')[1] == '08' ? "August" : props && props.recData && props.recData.doctorCommercial &&
                                                      dateFormatter(props.recData.doctorCommercial[0].stampdate).split('-')[1] == '09' ? "September" : props && props.recData && props.recData.doctorCommercial &&
                                                        dateFormatter(props.recData.doctorCommercial[0].stampdate).split('-')[1] == '10' ? "October" : props && props.recData && props.recData.doctorCommercial &&
                                                          dateFormatter(props.recData.doctorCommercial[0].stampdate).split('-')[1] == '11' ? "November" : props && props.recData && props.recData.doctorCommercial &&
                                                            dateFormatter(props.recData.doctorCommercial[0].stampdate).split('-')[1] == '12' ? "December" : "" : "____"}, {props.recData && props.recData.doctorCommercial && props.recData.doctorCommercial[0] && props.recData.doctorCommercial[0].stampdate && props.recData.doctorCommercial[0].stampdate !== '0000-00-00' ? dateFormatter(props.recData.doctorCommercial[0].stampdate).split('-')[2] : "____"}
                            {" "}
                            at {props &&
                              props.recData &&
                              props.recData.vacancyData &&
                              props.recData.vacancyData[0].centerNames} by and between;
                          </strong>
                        </p>
                        {/* <p><strong> This consultancy agreement (the ‘Agreement’) is made and entered into this on {props && props.recData && props.recData.doctorCommercial && props.recData.doctorCommercial[0].createdAt.substring(0, 10)} at Bengaluru by and between</strong></p> */}
                        {/* <p><strong> This consultancy agreement (the ‘Agreement’) is made and entered into this 12<sup>th</sup> day of August, 2021 at Bengaluru by and between</strong></p> */}

                        <p>
                          <strong>Kids Clinic India Private Limited</strong> a
                          Company incorporated under the provisions of Companies
                          Act, 1956, having its registered office at #1533, 9th
                          main, 3rd Block, Jayanagar, Bengaluru-560 011
                          (hereinafter referred to as the{" "}
                          <strong>‘Company’</strong>, which expression, unless
                          repugnant to the context hereof shall include its
                          holding Company, subsidiaries, associates, affiliates,
                          shareholders and directors) of the{" "}
                          <strong>ONE</strong> part.
                        </p>

                        <p>
                          <strong>AND </strong>
                        </p>

                        <p>
                          Person named in <strong>Schedule 1</strong> to this
                          agreement as Consultant (hereinafter referred to as
                          the <strong>“Consultant”</strong>) of the SECOND part.
                        </p>

                        <p>
                          <strong>WHEREAS </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            a) The Company is engaged in providing the
                            specialized medical service to its
                            patients/customers through specialized medical
                            professionals and in order to achieve the object,
                            the Company is engaging the consultant based the
                            representations made by the consultant and the
                            engagement is on the terms and conditions mentioned
                            hereinafter.
                          </p>

                          <p>
                            b) <strong>AND WHEREAS</strong> the Consultant has
                            represented to the Company that he/she has requisite
                            experience, competency and qualification recognized
                            under Indian laws for providing the Services to the
                            Company in an effective and timely manner.{" "}
                          </p>

                          <p>
                            c) <strong>AND WHEREAS </strong>the Company, based
                            on the representation of the Consultant, agreed to
                            engage the Consultant for providing the medical
                            Services on the terms and conditions contained in
                            this Agreement.{" "}
                          </p>

                          <p>
                            d) The Company and the Consultant on mutual
                            acceptance agreed to enter into this Agreement to
                            reduce the terms and conditions of the services.
                          </p>
                        </div>

                        <p>
                          The Company and the Consultant being collectively
                          referred to as Parties and severally as the Party;
                        </p>

                        <p>
                          NOW THIS AGREEMENT WITNESSETH as follows and the
                          parties hereto hereby mutually agree with each other
                          as follows:
                        </p>

                        <p>
                          1.{" "}
                          <strong className="sectitleAgmt">
                            DEFINITIONS AND INTERPRETATION
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            1.1. Definitions - In this Agreement, the following
                            definitions apply:{" "}
                          </p>

                          <div className="sublistAgmt">
                            <p>
                              1.1.1. <strong>“Commencement Date” </strong>shall
                              mean date of commencement mentioned under{" "}
                              <strong>Schedule 1 </strong>to this Agreement.
                            </p>

                            <p>
                              1.1.2. <strong>“Confidential Information”</strong>{" "}
                              includes information confidential to the Hospital,
                              Company and its group company including but not
                              limited to Intellectual Property, technical
                              know-how, customer information (including names
                              addresses, contact names and addresses, telephone
                              numbers and e-mail addresses), business plans,
                              trade secrets, product specifications, market
                              research, financial data and forecasts, capital
                              strategy and capital raising activities (proposed
                              and ongoing), business methods, marketing
                              strategies, tenders and price sensitive
                              information, fees, commission structure,
                              feasibility figures and plans relating to
                              contracts (actual and proposed), details of actual
                              and proposed contracts, requirements of customers
                              or prospective customers, information in respect
                              of which the Company or its group company is bound
                              by an obligation of confidence to any third party.
                            </p>

                            <p>
                              1.1.3. “<strong>Consultation Fees</strong>” shall
                              mean and include the fees collected from the
                              patients as a consultation fees as part of
                              hospital tariff charges.
                            </p>

                            <p>
                              1.1.4. “<strong>Expiry Date</strong> “shall mean
                              the date of expiry of this contract mentioned in{" "}
                              <strong>Schedule 1</strong> hereinafter.
                            </p>

                            <p>
                              1.1.5. “<strong>Hospital</strong> “shall mean
                              healthcare facility located at the address
                              mentioned under <strong>Schedule 1</strong>{" "}
                              hereinafter.
                            </p>

                            <p>
                              1.1.6. “<strong>Medical Practitioner</strong>{" "}
                              “shall mean a person registered and licensed as a
                              medical practitioner with Indian Medical Council/
                              state Medical Councilor any other applicable
                              Indian laws to provide the Medical Services.{" "}
                            </p>

                            <p>
                              1.1.7. “<strong>Medical Services</strong>” means
                              the type of medical services specified in{" "}
                              <strong>Part 1</strong> of{" "}
                              <strong>Schedule – 2</strong>.
                            </p>

                            <p>
                              1.1.8. “<strong>Term</strong>” shall mean the
                              period mentioned under <strong>Schedule 1</strong>{" "}
                              hereinafter.
                            </p>
                          </div>

                          <p>
                            1.2. Interpretation - In this Agreement, unless the
                            context otherwise requires:
                          </p>

                          <div className="sublistAgmt">
                            <p>
                              1.2.1. a reference to a clause or schedule means a
                              clause of or schedule to this Agreement;
                            </p>

                            <p>
                              1.2.2. the singular includes the plural and vice
                              versa;
                            </p>

                            <p>
                              1.2.3. words denoting one gender include the other
                              gender;
                            </p>

                            <p>
                              1.2.4. the word person includes a firm, a body
                              corporate, an unincorporated association or an
                              authority;
                            </p>

                            <p>
                              1.2.5. a reference to this Agreement or another
                              instrument includes any variation or replacement
                              of either of them;
                            </p>

                            <p>
                              1.2.6. reference to a statute, ordinance, code or
                              other law includes regulations and other
                              instruments under it and consolidations,
                              amendments, re-enactments or replacements of any
                              of them; and
                            </p>

                            <p>
                              1.2.7. reference to a person includes a reference
                              to the person's executors, administrators,
                              successors, substitutes and permitted assigns.{" "}
                            </p>

                            <p>
                              1.2.8. Any obligation in this Agreement on a
                              person not to do something includes an obligation
                              not to agree or allow that thing to be done.{" "}
                            </p>

                            <p>
                              1.2.9. This Agreement is a jointly drafted product
                              of the parties and any rule of statutory
                              interpretation interpreting Agreement against a
                              party primarily responsible for drafting the
                              agreement shall not be applicable to this
                              Agreement.
                            </p>
                          </div>
                        </div>

                        <p>
                          2.{" "}
                          <strong className="sectitleAgmt">
                            TERMS OF ENGAGEMENT
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            2.1. The Company engages the Consultant to provide,
                            and the Consultant agrees to provide, Medical
                            Services on the terms and conditions mentioned
                            hereinafter in this Agreement.
                          </p>

                          <p>
                            2.2. The parties agree and acknowledge that the
                            Consultant is an independent professional and not an
                            employee of the Company and the Company is not
                            responsible for any legal obligations, liabilities
                            and expenses in respect of personal taxation,
                            worker's compensation, superannuation, leave
                            entitlements and the like. As a result of being an
                            independent professional nothing in this Agreement
                            affects the Company’s legal or equitable rights of
                            indemnity against the Consultant or either party’s
                            rights against the other in the applicable laws.
                            Except as specifically provided in this Agreement,
                            neither party shall have the right nor authority to
                            assume, create or enlarge any obligation or
                            commitment on behalf of the other party.{" "}
                          </p>

                          <p>
                            2.3. It is agreed between the parties that the
                            Company may engage other Consultants to provide the
                            same or similar Medical Services as the Consultant
                            shall provide under this Agreement.
                          </p>

                          <p>
                            2.4. The Consultant shall declare on signing of this
                            Agreement, all current external positions held such
                            as other employment, directorship, trusteeship,
                            partnership and offices of responsibility including
                            honorary positions held including the potential
                            positions which is conflicting with the Company’s
                            interest. During the term of this Agreement, the
                            Consultant agrees not to undertake any obligation or
                            assignment contractual or otherwise, which may
                            involve actual or potential conflict of interest
                            with the business of the Company. If the Company
                            thinks in its discretion that the Consultant has
                            undertaken any obligation or assignment contractual
                            or otherwise, which may involve actual or potential
                            conflict of interest with the business of the
                            Company then it shall be deemed to be in the
                            conflict of interest of the Company.
                          </p>

                          <p>
                            2.5. The Consultant shall use her best efforts,
                            skills, and abilities in rendering the professional
                            services and shall render the professional services
                            diligently and in good faith. The Company, with an
                            object of providing the best services to its
                            patients/customers, has framed the policies,
                            procedures and by-laws (<strong>“Protocols”</strong>
                            ), which needs to be followed by the Consultant
                            during the term of this Agreement including any
                            renewals thereof along with any modification(s) or
                            amendments to such Protocols.
                          </p>

                          <p>
                            2.6. The Consultant shall provide the medical
                            services as mentioned in <strong>Part 1</strong> of{" "}
                            <strong>Schedule 2</strong> to this agreement to the
                            best of his/her ability. The services provided by
                            the Consultant should be to the absolute
                            satisfaction of the company and the patient. The
                            same shall be the essence of this contract{" "}
                          </p>

                          <p>
                            2.7. The Consultant is independent in deciding the
                            medical treatment to be provided to the Patients/
                            Customers of the Company in their professional
                            capacity.
                          </p>

                          <p>
                            2.8. The Consultant shall be available for the
                            provision of the services at a location mentioned
                            hereinabove at all the times as specified by the
                            Company from time to time. It is however understood
                            that the location &amp; time for provision of the
                            services by the Consultant can be changed by the
                            Company, with prior notice to the Consultant.
                          </p>
                        </div>

                        <p>
                          3.{" "}
                          <strong className="sectitleAgmt">
                            CONSIDERATION
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            3.1. Subject to the provisions of this Clause 3 and
                            subject to the Consultant performing all the
                            obligation under this agreement to the subjective
                            satisfaction of the Company, the Company will pay
                            the Consultant for the Medical Services provided
                            under this Agreement in accordance with{" "}
                            <strong>Part 2</strong> of{" "}
                            <strong>Schedule 2 </strong>hereinafter.
                          </p>

                          <p>
                            3.2. That the Consultant shall not be entitled to
                            any benefits from the Company including all those
                            benefits which are made available to the employees
                            of the Company.
                          </p>

                          <p>
                            3.3. The payment to the Consultant shall be made,
                            after deduction of applicable taxes at source, on
                            monthly basis within 10 (ten) working days of close
                            of the calendar month. All the payments are subject
                            to realization of money from patients/ insurance
                            companies/ guarantors. The Consultant shall pay all
                            his personal and other applicable taxes under the
                            applicable laws and shall always keep the Company
                            indemnified in this respect.{" "}
                          </p>
                        </div>

                        <p>
                          4.{" "}
                          <strong className="sectitleAgmt">
                            TERM &amp; TERMINATION
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>4.1. This Agreement shall continue to be in force for the Term unless earlier terminated in accordance with this Clause 4. <a name="_Hlk536540876"></a></p>

                          <p>4.2. Either party may terminate this Agreement at any time by giving the <strong>{props && props.recData && props.recData.doctorCommercial && props.recData.doctorCommercial[0].noticeperiod} </strong> {" "} prior written notice to other party in this respect after completion of first <strong>{props && props.recData && props.recData.doctorCommercial && props.recData.doctorCommercial[0].lockinperiod}{" "} </strong> from the date of commencement .</p>

                          <p>4.3. The Company and the Consultant may by written agreement terminate this Agreement at any time.<a name="_Hlk536540891"></a></p>

                          <p>4.4. Notwithstanding anything stated hereunder, this Agreement may be terminated with immediate effect upon the occurrence of any of the following events;</p>

                          <div className="sublistAgmt">
                            <p>
                              (a) if the Consultant is in breach of any of the
                              terms and conditions of this Agreement and/or the
                              conduct of the Consultant is considered as
                              detrimental to the interest of the Company or in
                              violation of the Protocols of the Company.
                            </p>

                            <p>
                              (b) Any information, declaration and/or
                              representation given by the consultant is found to
                              be incorrect, false or misleading.
                            </p>

                            <p>(c) Death or insolvency of the Consultant.</p>

                            <p>
                              (d) On acceptance of any assignments in violation
                              of this Agreement.
                            </p>

                            <p>
                              (e) If any regulatory authority disqualifies the
                              Consultant from providing the medical services or
                              on expiry of Registration certificate issued by
                              Medical Council of India and/or State Medical
                              Council as the case maybe and the same being not
                              renewed within stipulated period as prescribed in
                              terms of any specific regulation or otherwise.
                            </p>

                            <p>(f) engages in serious or willful misconduct;</p>

                            <p>
                              (g) is convicted of any offence including moral
                              turpitude
                            </p>

                            <p>
                              (h) if the services rendered by the Consultant are
                              not satisfactory to the Company
                            </p>

                            <p>
                              (i) if the Consultant commits breach of
                              confidentiality as envisaged under clause 12 of
                              this agreement;
                            </p>
                          </div>

                          <p>
                            4.5 The Consultant hereby agrees that the medical
                            services provided under this agreement is critical
                            in nature and Patients/Customers shall not be
                            deprived of on earlier termination, thus the
                            Consultant shall continue to provide the services as
                            agreed upon, during the Notice Period. In case
                            Consultant fails to provide the required Notice
                            Period and/ or fails to provide the services during
                            the Notice Period, the Consultant shall require to
                            pay damages to the Company which is equivalent to
                            last three (03) months monthly professional fees
                            received by the Consultant under this Agreement.
                          </p>

                          <p>
                            4.6 Upon expiry or earlier termination of this
                            Agreement, the Consultant shall immediately handover
                            to Company all correspondence, documents, papers,
                            property belonging to the Company which may be in
                            Consultant’s possession or under Consultant’s
                            control including without limitation all the
                            creations that may be in Consultant’s possession.
                            The Consultant shall not keep any copies of these
                            items nor distribute the same to any party without
                            the prior written authorization of the Company. The
                            consultant agrees that failure by her/him to return
                            the Property of the company upon termination shall
                            be deemed to be criminal breach of trust.
                          </p>
                        </div>

                        <p>
                          5.{" "}
                          <strong className="sectitleAgmt">
                            PRE-REQUISITE{" "}
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            5.1. The Consultant shall be empaneled with the
                            Company based on the condition that Consultant’s
                            medical degree{" "}
                            <strong>
                              {props &&
                                props.recData &&
                                props.recData.doctorCommercial &&
                                props.recData.doctorCommercial[0]
                                  .medicaldegree}{" "}
                            </strong>{" "}
                            and the specialization degree{" "}
                            <strong>
                              {props &&
                                props.recData &&
                                props.recData.doctorCommercial &&
                                props.recData.doctorCommercial[0].pgdegree}
                              FRCP &amp; CH
                            </strong>{" "}
                            is registered with Medical Council of India and/or
                            State Medical Council as the case may be. The
                            Consultant shall maintain the aforesaid registration
                            valid during the term of this agreement at
                            Consultant’s own cost and expenses and shall renew
                            the registration as when required and provide the
                            copies of the same to Company. If in case the
                            Consultant fails to renew the registrations within
                            the stipulated time, this Agreement shall cease to
                            be valid and the Company at its sole discretion
                            would be at a liberty to terminate this Agreement
                            without notice.
                          </p>

                          <p>
                            5.2. Consultant shall produce copies of original
                            certificates supporting degrees and/or professional
                            qualification, registration and experience
                            certificates and any other documents as the Company
                            requires.
                          </p>

                          <p>
                            5.3. The Consultant shall obtain valid visa and work
                            permit (if required/applicable) being granted prior
                            to commencement of this agreement and maintained and
                            valid for the duration of this agreement with the
                            Company.
                          </p>

                          <p>
                            5.4. The Consultant hereby agrees that, the Company
                            can disclose and release any and all information
                            relating to Consultant and/ or documents furnished
                            by Consultant to the Company’s authorized
                            representative, agents or to any government
                            authorities including the Company’s affiliates as
                            maybe required by the Company.
                          </p>

                          <p>
                            5.5. This Agreement is subject to correctness of the
                            self-attested documents mentioned in{" "}
                            <strong>Schedule 3</strong> to be submitted by the
                            Consultant to the Company for verification.
                          </p>

                          <p>
                            5.6. The Consultant shall obtain adequate
                            Professional Indemnity Insurance Policy and other
                            Insurance policies in relation to any causes of
                            actions that can be initiated by any patient or
                            others relating to any negligence, act, omission or
                            deficiency of services provided by the Consultant to
                            the patients of the Company. This indemnity Policy
                            should cover at least a risk of an amount mentioned
                            under <strong>Schedule 1</strong> hereinafter and
                            endorse Company name &amp;amp; address in such
                            policy to extend the benefits in case of claim. A
                            Copy of such policy shall be provided by the
                            Consultant to the Company. The Consultant shall
                            ensure the policy is renewed from time to time and
                            the Consultant agrees to maintain and keep the same
                            in full force and effect, at his/ her sole cost and
                            expense and provide copies to the Company on
                            renewals.
                          </p>
                        </div>

                        <p>
                          6.{" "}
                          <strong className="sectitleAgmt">
                            OBLIGATIONS OF THE CONSULTANT{" "}
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            6.1. The Consultant agrees to provide and be
                            available to provide Medical Services to patients at
                            the Hospital in the department mentioned under{" "}
                            <strong>Schedule 1</strong> hereinafter.
                          </p>

                          <p>
                            6.2. The Consultant shall follow protocols as per
                            the law of the land.{" "}
                          </p>

                          <p>
                            6.3. The Consultant agrees to assist in the
                            development of emergency service rosters and other
                            facilities/services relevant to the Medical Services
                            in his/her department.
                          </p>

                          <p>
                            6.4. The Consultant agrees to notify the Hospital in
                            accordance with Hospital procedures or in the
                            absence of such procedures within a reasonable
                            period of time, of any matter of which he/she is
                            aware and about which a prudent Medical Practitioner
                            should reasonably believe the Hospital should be
                            notified. This includes:
                          </p>

                          <div className="sublistAgmt">
                            <p>
                              a) <strong>involving the Consultant: </strong>
                            </p>

                            <div className="sublistAgmt2">
                              <p>· misconduct of the Consultant</p>

                              <p>
                                · fines imposed or registration as a Medical
                                Practitioner being made conditional, suspended,
                                removed or lapsing;{" "}
                              </p>

                              <p>
                                · charges with or convictions of any criminal
                                offence involving moral turpitude;{" "}
                              </p>

                              <p>
                                · actual or potential conflicts of interest; or{" "}
                              </p>

                              <p>
                                · actual or anticipated failures to comply with
                                this Agreement, including specifically any
                                inability or non-availability to provide Medical
                                Services; and
                              </p>
                            </div>
                          </div>

                          <div className="sublistAgmt">
                            <p>
                              b){" "}
                              <strong>involving any Hospital patient:</strong>
                            </p>

                            <div className="sublistAgmt2">
                              <p>· Adverse incidents; </p>

                              <p>· Verbal or written complaints received; </p>

                              <p>
                                · Threats of legal action or legal notice or
                                summons received; or{" "}
                              </p>

                              <p>
                                · Any other matter which may adversely affect
                                the Hospital.{" "}
                              </p>
                            </div>
                          </div>

                          <p>
                            6.5. <strong>Obligation to Co-operate:</strong>
                          </p>

                          <div className="sublistAgmt">
                            <p>6.5.1. Subject to sub-clause (b): </p>

                            <div className="sublistAgmt2">
                              <p>
                                (a) if requested by the Company, the Consultant
                                must provide as soon as reasonably practicable
                                all relevant details of any matters of which the
                                Hospital is notified under Clause 6.4 or
                                otherwise becomes aware; and{" "}
                              </p>

                              <p>
                                (b) the Consultant must take all reasonable
                                steps to assist the Hospital in inquiring into
                                and resolving any complaint, dispute or other
                                difficulty arising under or in connection with
                                this Agreement.
                              </p>
                            </div>
                          </div>

                          <p>
                            6.5.2. Nothing in Clauses 6.4 or 6.5 requires the
                            Consultant to disclose information to the Hospital
                            where to do so would cause the Consultant to be in
                            breach of their respective obligations to any
                            insurer, or which may significantly prejudice any
                            claim by the Consultant under their respective
                            medical license or professional indemnity insurance.
                            In any such circumstances the Consultant must use
                            his best endeavors to obtain the requisite approval
                            to disclosure of the information required by the
                            Hospital and must disclose the information to the
                            Hospital in accordance with any approval given.
                          </p>
                        </div>

                        <p>
                          7.{" "}
                          <strong className="sectitleAgmt">
                            REPRESENTATIONS &amp; WARRANTIES
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            7.1. The Consultant represents and warrants to
                            Company that, the Consultant has full right to enter
                            into and fully perform this Agreement, and entering
                            into this Agreement with the Company will not in any
                            way infringe upon or violate any applicable law,
                            rule or regulation, any contract with a third party
                            or any rights of any third person.
                          </p>

                          <p>
                            7.2. The Consultant represents that he/she shall
                            provide the services in a diligent manner to the
                            full and complete satisfaction of the Company in
                            compliance with the applicable standards and
                            Protocols of the Company.
                          </p>

                          <p>
                            7.3. The Consultant will be responsible for medical
                            investigation, treatment, discharge, and follow-ups
                            and pre- and post-operative care of the patients
                            admitted under her/his supervision and care.
                          </p>

                          <p>
                            7.4. The Consultant warrants that all the Documents
                            provided by the Consultant under Schedule C to this
                            agreement are true.
                          </p>

                          <p>
                            7.5. The Company has represented to the Consultant
                            that the Company has full right to enter into and
                            fully perform this Agreement, and entering into this
                            Agreement with the Consultant, will not in any way
                            infringe upon or violate any applicable law, rule or
                            regulation, any contract with a third party or any
                            rights of any third person.{" "}
                          </p>
                        </div>

                        <p>
                          8.{" "}
                          <strong className="sectitleAgmt">
                            INTELLECTUAL PROPERTY RIGHTS
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            8.1. If the Consultant discovers or participates in
                            the making or discovery of Intellectual Property
                            during the Term of this Agreement by using the
                            resources of Company or otherwise (irrespective of
                            whether he was carrying out his normal duties or
                            others specifically assigned to him) then all such
                            Intellectual Property shall belong to the Company
                            absolutely. For the purpose of this Agreement.
                            “Intellectual Property” includes letters patent,
                            trademarks, service marks, copyrights, design
                            rights, applications for registration of any of the
                            foregoing and the right to apply for them in any
                            part of the world, creations, arrangements, devices,
                            inventions or improvements upon or additions to an
                            invention, moral rights, Confidential Information,
                            know-how and rights of a similar nature arising or
                            subsisting anywhere in the world in relation to all
                            of the foregoing whether registered or unregistered.
                          </p>

                          <p>
                            8.2. The Consultant shall forthwith notify to the
                            Company full details of all Intellectual Property
                            which he/she may make discover or participate in the
                            making or discovery during the Term of this
                            Agreement, and will keep the Company appraised at
                            all times of the stage that has been reached in
                            relation to any improvement or creation of such
                            Intellectual Property. If the Company requests, the
                            Consultant shall give and supply all such
                            information, data, drawings and assistance as may be
                            required to enable the Company to exploit the
                            Intellectual Property to the best advantage.{" "}
                          </p>

                          <p>
                            8.3. The Consultant shall, without payment of any
                            consideration to the Consultant, take all steps and
                            carry out all acts that may be necessary to ensure
                            that the title to the Intellectual Property is
                            lawfully vested in the Company, including signing
                            all applications and executing any other documents
                            that may be necessary and will carry out such acts
                            and steps with expedition on the instructions of the
                            Company, in particular where the filing of any claim
                            to such Intellectual Property right may give the
                            Company priority.{" "}
                          </p>

                          <p>
                            8.4. The Consultant hereby irrevocably appoints the
                            Company as his lawful attorney in his name and on
                            his/ her behalf to execute any documents and
                            generally to act and to use his/ her name for the
                            purpose of giving the full benefit of this clause to
                            the Company (or its nominee). A certificate in
                            writing signed by a director of the Company that an
                            instrument or act falls within the authority
                            confirmed by this clause shall be conclusive
                            evidence in favor of a third party that it is the
                            case.{" "}
                          </p>

                          <p>
                            8.5. The provisions of this Clause shall remain in
                            force with regard to any Intellectual Property made
                            or discovered during the Term of this Agreement and
                            shall be binding upon the Consultant’s legal
                            representatives notwithstanding the termination of
                            this Agreement
                          </p>

                          <p>
                            8.6. It is clarified that all Intellectual Property
                            of the Company shall be exclusive property of
                            Company and Company shall remain the absolute owner
                            of the same and all rights thereto shall continue to
                            vest in the Company only.
                          </p>

                          <p>
                            8.7. The Company, its affiliates and associates own
                            all Intellectual Property Rights attached to the
                            Company. This Agreement does not constitute and
                            shall not be construed as an assignment or sale or
                            transfer of any trademark rights or any other
                            Intellectual Property rights of the Company, its
                            affiliates and associates in any manner whatsoever
                            to Consultant. Consultant undertake that he/she
                            shall not, at any time, during the subsistence of
                            this Agreement or at any time after its termination
                            or expiry of this Agreement claim ownership or user
                            rights or interest in respect of the name,
                            trademarks, logo, artwork and brand names of Company
                            or use the trademark without obtaining prior
                            permission from the Company.
                          </p>

                          <p>
                            8.8. The Consultant shall not be permitted to use
                            any of the brand name of the Company for any
                            purposes without the prior written consent of the
                            Company and shall not take any action that will
                            constitute infringement of Brand name of the Company
                            in any manner whatsoever.
                          </p>
                        </div>

                        <p>
                          9.{" "}
                          <strong className="sectitleAgmt">
                            DATA PROTECTION
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            9.1. The Consultant shall at all times during the
                            Term act in accordance with the law relating to data
                            protection applicable to the Company including
                            pursuant to any client contract and shall also
                            comply with any policy introduced by the Company
                            from time to time to comply with the data
                            protection. If there is no policy, then the
                            Consultant shall do everything that needs to be done
                            as per law to protect the sensitive personal data.{" "}
                          </p>

                          <p>
                            9.2. The Consultant hereby consents to the Company
                            holding and processing both electronically and
                            manually the personal data it collects which relates
                            to the Consultant which is necessary or reasonably
                            required for the proper performance of this
                            Agreement, for management, administrative and other
                            related purposes or for the conduct of the group’s
                            business or to comply with client’s requirement,
                            applicable laws, rules and regulations and the
                            Consultant agrees to provide the Company’s group
                            with all personal data relating to him/ her which is
                            necessary or reasonably required for the aforesaid
                            purposes.{" "}
                          </p>

                          <p>
                            9.3. The Consultant agrees that all the information
                            provided by him/her will be stored in electronic
                            form. The Company is not responsible for any data
                            breach. In the event of a data breach, the
                            Consultant shall not hold the Company responsible
                            and shall not claim any damages for the breach.{" "}
                          </p>
                        </div>

                        <p>
                          10.{" "}
                          <strong className="sectitleAgmt">
                            CONFLICT OF INTEREST
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            10.1. The Consultant shall declare on signing of
                            this Agreement all current external positions held
                            such as other employment, directorship, trusteeship,
                            partnership and offices of responsibility including
                            honorary positions held including the potential
                            positions which is conflicting with the Company’s
                            interest. During the term of this Agreement, the
                            Consultant agrees not to undertake any obligation or
                            assignment contractual or otherwise, which may
                            involve actual or potential conflict of interest
                            with the business of the Company.
                          </p>

                          <p>
                            10.2. The Consultant agrees and undertakes that
                            Consultant will not during the subsistence of this
                            agreement and three years after termination of this
                            agreement, directly or indirectly solicit, divert or
                            attempt to solicit or divert for her benefit or on
                            behalf of any other person, firm, corporation,
                            partnership or any other entity, either directly or
                            indirectly any patient for whom the Company has
                            performed any services during the period of
                            Consultant’s association with the Company.
                          </p>
                        </div>

                        <p>
                          11.{" "}
                          <strong className="sectitleAgmt">
                            INDEMNIFICATION
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            11.1 The Consultant shall be fully responsible for
                            the clinical treatment given to the patient(s) under
                            this agreement. The Company shall be kept
                            indemnified against any medico legal liability
                            arising out of the medical treatment provided by the
                            Consultant and which arise during or after the
                            expiry of this agreement.
                          </p>

                          <p>
                            11.2 The Consultant agrees to defend with counsel
                            acceptable to the Company (or settle at the
                            Consultant’s sole cost &amp;expense), indemnify and
                            hold the Company/Hospital its affiliates, assignees
                            and each of its and their managing directors,
                            directors, officers, partners, employees and agents
                            (collectively, the “Indemnified Parties”) harmless
                            from and against any and all suits, liabilities
                            incurred, claims and proceedings by third parties
                            relating to and/or resulting in liabilities,
                            damages, costs, losses and expenses, including court
                            costs and reasonable attorneys’ fees, which arise
                            out of the performance or non-performance by
                            Consultant pursuant to this Agreement or out of the
                            breach of any of the terms of this Agreement.{" "}
                          </p>

                          <p>
                            11.3 The Consultant shall obtain professional
                            indemnity insurance policy as per Clause 5.6
                            mentioned herein above.
                          </p>

                          <p>
                            11.4 Notwithstanding anything stated above, if any
                            authority or Courts or Tribunals or statutory
                            authorities were to find that the Company is
                            vicariously liable for any medical negligence of the
                            Consultant, the Consultant agrees to indemnify the
                            Company to the extent of any claim to which the
                            Company may become liable including expenses to be
                            incurred for defending any proceedings.
                          </p>
                        </div>

                        <p>
                          12.{" "}
                          <strong className="sectitleAgmt">
                            CONFIDENTIALITY
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            12.1. All discussions and services held/provided/to
                            be provided by and between the Consultant and
                            Company are of a sensitive and confidential nature
                            and all continued interactions shall be of such
                            nature. The parties hereto in mutual trust and
                            respect agree and warrant to keep confidential all
                            such discussion and interactions including but not
                            limited to information, documents, inventions,
                            know-how, and other intellectual property, trade
                            secrets, and other confidential information relating
                            to the services or otherwise (hereinafter
                            “Confidential Information”, which expression shall
                            include the terms of this arrangement). The parties
                            therefore shall during the Term and thereafter hold
                            such information and all Confidential Information in
                            strict confidence and shall neither disclose the
                            same to any third party nor use it in any manner
                            without prior written consent of the other
                            contracting party.
                          </p>

                          <p>
                            12.2. The Consultant agrees that he/she shall not,
                            without the written consent of the Company, disclose
                            to third parties or use for her own financial
                            benefit or for the financial or other benefit of any
                            direct or indirect competitor of Company, any
                            information, data, and know-how, manuals, disks, or
                            otherwise, including all programs, decks, listings,
                            tapes, summaries of any papers, documents, plans,
                            specifications, or drawings, which belong to the
                            Company.{" "}
                          </p>

                          <p>
                            12.3. The Consultant agrees that all confidential
                            information of the Company shall be deemed to be and
                            shall be treated as the sole and exclusive property
                            of Company.
                          </p>

                          <p>
                            12.4. In the event of the breach of this clause by
                            the Consultant, the Company shall have the right to
                            seek such remedies at law or in equity against the
                            Consultant including but not limited to termination
                            of this agreement forthwith without any notice and
                            the Company can also claim damages. This section
                            shall survive the termination of this agreement for
                            any reason whatsoever.
                          </p>
                        </div>

                        <p>
                          13.{" "}
                          <strong className="sectitleAgmt">
                            FORCE MAJEAURE
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            13.1 Notwithstanding anything to the contrary herein
                            contained, if there is any
                            hindrance/obstruction/Disruption in the business of
                            the company, which results into complete or partial
                            stoppage of work or business activity of the Company
                            or any reason whatsoever including any Force majeure
                            event, acts of God, fires, explosions, accidents,
                            embargoes, wars, riots, labour disputes, strikes,
                            outbreak of an epidemic or pandemic disease,
                            governmental requirements ,civil unrest, civil
                            commotion, strike, Lockout, Court orders, Government
                            order etc. or for any reason whatsoever then in such
                            an event, the Company will not be obligated to pay
                            any consideration for the period of such
                            Interruption/disruption/hinderance to the
                            Consultant. Such Force Majeure situation shall be
                            notified to the Consultant within 30(Thirty days)
                            days from the occurrence of the same.
                          </p>
                        </div>

                        <p>
                          14. <strong className="sectitleAgmt">SURVIVAL</strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            14.1. All such provisions (or relevant part thereof)
                            where the context requires shall survive the
                            termination of this Agreement.
                          </p>
                        </div>

                        <p>
                          15.{" "}
                          <strong className="sectitleAgmt">
                            MISCELLANEOUS{" "}
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            15.1. The Consultant will render the professional
                            services independently as per her own expertise and
                            Consultant will have full liberty to perform the
                            services based on her knowledge and skills without
                            interference of company’s employees or
                            representatives. However, the Consultant should
                            perform professional duties faithfully and
                            diligently without any breach of law or Protocols of
                            the Company formulated from time to time and will
                            not indulge in any unfair or unethical practice in
                            any manner.{" "}
                          </p>

                          <p>
                            15.2. Nothing contained in this Agreement shall be
                            deemed to create an employee employer relationship
                            between Consultant and the Company accordingly
                            Company shall not be liable to make any statutory
                            payments to Consultant. Specifically, no provident
                            fund or ESI contribution shall be deducted from
                            payments to Consultant. Consultant also agree not to
                            act or represent before any other party as an
                            employee of the Company.
                          </p>

                          <p>
                            15.3. During the term of this Agreement, Consultant
                            shall not receive any cash directly from the
                            patient(s).
                          </p>

                          <p>
                            15.4. The Parties expressly do not intend hereby to
                            form a partnership, either general or limited, under
                            any jurisdiction's partnership law. The Parties do
                            not intend to be partners one to another, or
                            partners as to any third party, or create any
                            fiduciary relationship among them.{" "}
                          </p>

                          <p>
                            15.5. This Agreement may be executed in any number
                            of originals or counterparts, each in the like form
                            and all of which when taken together shall
                            constitute one and the same document and any Party
                            may execute this Agreement by signing any one or
                            more of such originals or counterparts.{" "}
                          </p>

                          <p>
                            15.6. No variation of this Agreement shall be
                            binding on any Party unless such variation is in
                            writing and signed by or on behalf of each of the
                            Parties hereto.{" "}
                          </p>

                          <p>
                            15.7. This Agreement shall not be capable of
                            assignment by the Consultant. The Company may
                            however assign this Agreement to any third party
                            without requiring any consent or approval of the
                            Consultant.{" "}
                          </p>

                          <p>
                            15.8. No waiver of any breach of any provision of
                            this Agreement shall constitute a waiver of any
                            prior, concurrent or subsequent breach of the same
                            or any other provisions hereof, and no waiver shall
                            be effective unless made in writing and signed by an
                            authorized representative of the waiving Party.
                          </p>

                          <p>
                            15.9. Each and every obligation under this Agreement
                            shall be treated as a separate obligation and shall
                            be severally enforceable as such and in the event of
                            any obligation or obligations being or becoming
                            unenforceable in whole or in part. To the extent
                            that any provision or provisions of this Agreement
                            are unenforceable the Parties shall endeavor to
                            amend such articles as may be necessary to make the
                            provision or provisions valid and effective.
                            Notwithstanding the foregoing any provision which
                            cannot be amended as may be necessary to make it
                            valid and effective shall be deemed to be deleted
                            from this Agreement and any such deletion shall not
                            affect the enforceability of the remainder of this
                            Agreement not so deleted provided the fundamental
                            terms of the Agreement are not altered.
                          </p>

                          <p>
                            15.10. This Agreement along with the Schedules
                            constitute the whole agreement between the Parties
                            relating to the subject matter hereof and supersedes
                            any prior arrangements whether oral or written,
                            relating to such subject matter. No Party has relied
                            upon any representation or warranty in entering this
                            Agreement other than those expressly contained
                            herein.{" "}
                          </p>
                        </div>

                        <p>
                          16.{" "}
                          <strong className="sectitleAgmt">
                            COMMUNICATION
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            16.1. Any notice or other communication to be given
                            by either party hereunder shall be in writing and
                            signed by or on behalf of the party giving it. It
                            shall be given in person or sent by registered mail
                            or through courier to the address mentioned in the
                            beginning of this agreement if it is to the Company
                            and to the address mentioned in{" "}
                            <strong>Schedule 1</strong> if it is to the
                            Consultant. The Company may send any notice or may
                            communicate to the Consultant by way of an email
                            mentioned at <strong>Schedule 1</strong> to this
                            agreement{" "}
                          </p>
                        </div>

                        <p>
                          17.{" "}
                          <strong className="sectitleAgmt">
                            REMEDIES FOR BREACH{" "}
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            17.1. The Consultant agrees that upon a breach of
                            this Agreement by the Consultant and failure by
                            Consultant to rectify such breach within the cure
                            period of seven (7) days, only if such breach is
                            capable of such cure or rectification as per the
                            Company, the Company shall have the right to recover
                            all the losses and damages suffered by it and the
                            Consultant shall be liable to pay to the Company for
                            the losses suffered by the Company.{" "}
                          </p>

                          <p>
                            17.2. The Consultant agrees that any negative
                            covenants, obligations and/or undertakings given to
                            the Company shall be specifically enforceable by
                            injunction and any damages claimed in addition
                            thereto shall not constitute a defense to any claim
                            of injunction nor prevent the grant of specific
                            relief to the Company. The Consultant expressly
                            waives the defense that damages are sufficient
                            alternate relief to an injunction and the Consultant
                            confirms, assures and represents that each and all
                            the negative covenants and obligations and
                            undertakings referred to herein or separately
                            executed in favor of the Company shall be
                            enforceable by one or more injunctions prohibiting
                            the breach of any covenant or compelling specific
                            performance of any obligation or the observance of
                            any covenant on the part of the Consultant as
                            contracted herein (whether by way of ad interim or
                            interim relief, or otherwise by way of permanent
                            injunction and damages).{" "}
                          </p>

                          <p>
                            17.3. No remedy conferred by any of the provisions
                            of this Agreement is intended to be exclusive of any
                            other remedy which is otherwise available at law, in
                            equity, by statute or otherwise, and each and every
                            other remedy shall be cumulative and shall be in
                            addition to every other remedy given hereunder or
                            now or hereafter existing at law, in equity, by
                            statute or otherwise. The election of any one or
                            more of such remedies by any of the Parties hereto
                            shall not constitute a waiver by such party of the
                            right to pursue any other available remedy.{" "}
                          </p>
                        </div>

                        <p>
                          18.{" "}
                          <strong className="sectitleAgmt">
                            DISPUTE RESOLUTION
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            18.1. This Agreement shall be governed and construed
                            in accordance with the laws of India, and any
                            disputes therein shall fall within the exclusive
                            jurisdiction of the courts in Bangalore, India.
                          </p>

                          <p>
                            18.2. In the event of any dispute arising between
                            the parties with respect to this Agreement, the same
                            shall be referred to the Sole Arbitrator and the
                            arbitration shall be in accordance with Arbitration
                            and Conciliation Act of 1996. The language of
                            arbitration proceeding shall be English. The seat
                            and place of arbitration shall be Bangalore and the
                            decision of the Arbitrator shall be final and
                            binding on both parties herein.
                          </p>
                        </div>

                        <p>
                          19.{" "}
                          <strong className="sectitleAgmt">
                            ANTI BRIBERY:
                          </strong>
                        </p>

                        <div className="listAgmt">
                          <p>
                            19.1. Each party covenants and undertakes that they
                            shall not permit any of the their Subsidiaries or
                            Affiliates or any of their representatives or agents
                            to promise, authorize or make any payment to, or
                            otherwise contribute any item of value directly or
                            indirectly, to any third party, including any Non-
                            U.S. Official (as defined under the Foreign Corrupt
                            Practices Act, 1977 ("FCPA")), in each case, in
                            violation of the FCPA or Prevention of Corruption
                            Act, 1988 ("PCA"), the (UK) Anti-Bribery Act (“ABA”)
                            or any other applicable anti-bribery or
                            anti-corruption laws.
                          </p>
                        </div>

                        <p>
                          20.{" "}
                          <strong className="sectitleAgmt">
                            MISCELLANEOUS
                          </strong>{" "}
                        </p>

                        <div className="listAgmt">
                          <p>
                            20.1. This Agreement shall supersede all previous
                            agreements, understandings, contracts and any other
                            documents in respect to the services mentioned under
                            this Agreement.{" "}
                          </p>
                        </div>

                        <p>
                          <strong>IN WITNESS WHEREOF</strong> the parties hereto
                          have signed and executed this Agreement on the date
                          first mentioned hereinabove in the presence of the
                          following witnesses;
                        </p>

                        <div>
                          <br />
                          <br />
                          <Row>
                            <Col>
                              <p>
                                For <strong>Kids Clinic India Pvt. Ltd.</strong>
                              </p>
                            </Col>
                            <Col></Col>
                          </Row>
                          <br />

                          <Row>
                            <Col>
                              {" "}
                              <p>
                                Name:{" "}
                                <strong>Mr. Raviganesh Venkataraman</strong>
                              </p>
                            </Col>
                            <Col></Col>
                          </Row>
                          <Row style={{ marginTop: "-22px" }}>
                            <Col>
                              {" "}
                              <p>
                                Designation:{" "}
                                <strong>Chief Executive Officer</strong>
                              </p>
                            </Col>
                            <Col></Col>
                          </Row>
                          <Row style={{ marginTop: "-22px" }}>
                            <Col>
                              {" "}
                              <p>Date: </p>
                            </Col>
                            <Col>
                              <p>) __________________________</p>
                            </Col>
                          </Row>
                          <br />
                          <br />
                          <br />

                          <Row style={{ marginTop: "-22px" }}>
                            <Col>
                              {" "}
                              <p>Witness:</p>
                            </Col>
                            <Col></Col>
                          </Row>
                          <Row style={{ marginTop: "-22px" }}>
                            <Col>
                              {" "}
                              <p>Name: </p>
                            </Col>
                            <Col></Col>
                          </Row>
                          <Row style={{ marginTop: "-22px" }}>
                            <Col>
                              {" "}
                              <p>Address:</p>
                            </Col>
                            <Col>
                              {" "}
                              <p>) __________________________</p>
                            </Col>
                          </Row>

                          <br />
                          <br />
                          <br />
                          <Row style={{ marginTop: "-22px" }}>
                            <Col>
                              {" "}
                              <p>
                                <strong>Signed by the Consultant</strong>
                              </p>
                            </Col>
                            <Col></Col>
                          </Row>
                          <Row style={{ marginTop: "-22px" }}>
                            <Col>
                              {" "}
                              <p>
                                <strong>
                                  Dr.{" "}
                                  {props &&
                                    props.recData &&
                                    props.recData.doctorCommercial &&
                                    props.recData.doctorCommercial[0].fullname}
                                </strong>
                              </p>
                            </Col>
                            <Col></Col>
                          </Row>
                          <Row style={{ marginTop: "-22px" }}>
                            <Col>
                              {" "}
                              <p>Date:</p>
                            </Col>
                            <Col>
                              {" "}
                              <p>) __________________________</p>
                            </Col>
                          </Row>
                          <br />
                          <br />
                          <br />
                          <Row style={{ marginTop: "-22px" }}>
                            <Col>
                              {" "}
                              <p>Witness:</p>
                            </Col>
                            <Col></Col>
                          </Row>
                          <Row style={{ marginTop: "-22px" }}>
                            <Col>
                              {" "}
                              <p>Name: </p>
                            </Col>
                            <Col></Col>
                          </Row>
                          <Row style={{ marginTop: "-22px" }}>
                            <Col>
                              {" "}
                              <p>Address:</p>
                            </Col>
                            <Col>
                              {" "}
                              <p>) __________________________</p>
                            </Col>
                          </Row>
                        </div>
                      </Container>
                      <br />
                      <Container
                        fluid
                        className="cardAgmt"
                        style={{ border: "white" }}
                      >
                        <div>
                          <div style={{ pageBreakBefore: "always" }}>
                            &nbsp;
                          </div>
                          <h6 className="subtitleAgmt">
                            <strong>SCHEDULE 1:</strong>
                          </h6>

                          <table
                            border="1"
                            cellspacing="0"
                            cellpadding="0"
                            align="left"
                          >
                            <tbody>
                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>Name of the Consultant</strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  <p>
                                    {props &&
                                      props.recData &&
                                      props.recData.doctorCommercial &&
                                      props.recData.doctorCommercial[0]
                                        .fullname}
                                  </p>
                                </td>
                              </tr>

                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>Designation</strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  <p>
                                    {" "}
                                    {props &&
                                      props.recData &&
                                      props.recData.vacancyData &&
                                      props.recData.vacancyData[0]
                                        .designationLabel}
                                  </p>
                                </td>
                              </tr>

                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>
                                      Father name of the Consultant
                                    </strong>
                                  </p>
                                </td>
                                <td valign="bottom">

                                  {props &&
                                    props.recData &&
                                    props.recData.doctorCommercial &&
                                    props.recData.doctorCommercial[0] && props.recData.doctorCommercial[0].fathername === null ? (
                                    <p style={{ marginRight: "-4rem" }}> --- </p>
                                  ) : (
                                    <p>
                                      {" "}
                                      {props &&
                                        props.recData &&
                                        props.recData.doctorCommercial &&
                                        props.recData.doctorCommercial[0]
                                          .fathername}{" "}
                                    </p>)}
                                </td>

                              </tr>
                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>
                                      Spouse name of the Consultant
                                    </strong>
                                  </p>
                                </td>
                                {props &&
                                  props.recData &&
                                  props.recData.doctorCommercial &&
                                  props.recData.doctorCommercial[0] &&
                                  props.recData.doctorCommercial[0].husbandname === null ? (
                                  <p style={{ marginRight: "-2rem" }}> --- </p>
                                ) : (
                                  <td valign="bottom">
                                    <p>
                                      {" "}
                                      {props &&
                                        props.recData &&
                                        props.recData.doctorCommercial &&
                                        props.recData.doctorCommercial[0] && props.recData.doctorCommercial[0]
                                          .husbandname}
                                    </p>
                                  </td>
                                )}
                              </tr>
                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>PAN</strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  {props &&
                                    props.recData &&
                                    props.recData.doctorCommercial &&
                                    props.recData.doctorCommercial[0] &&
                                    props.recData.doctorCommercial[0].panno === null ? (
                                    <p style={{ marginRight: "-4rem" }}> --- </p>
                                  ) : (
                                    <p>
                                      {props &&
                                        props.recData &&
                                        props.recData.doctorCommercial &&
                                        props.recData.doctorCommercial[0].panno}
                                    </p>)}
                                </td>
                              </tr>

                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>
                                      Medical Council Certificate No.
                                    </strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  <p>
                                    {props && props.recData && props.recData.doctorCommercial && props.recData.doctorCommercial[0] && props.recData.doctorCommercial[0].mcrcval ?
                                      props &&
                                        props.recData &&
                                        props.recData.doctorCommercial &&
                                        props.recData.doctorCommercial[0] &&
                                        props.recData.doctorCommercial[0].mcrstate
                                        ? (props.recData.doctorCommercial[0].mcrstate.substring(0, 3) + "- " +
                                          props.recData.doctorCommercial[0].mcrcval)
                                        : props.recData.doctorCommercial[0].mcrcval
                                      :
                                      "---"}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>
                                      Medical Council Certificate Validity
                                    </strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  {/* {lists &&
                                  lists.data && lists.data.filter(z => z.docTitle === "Medical Council Registration Certificate No").map((ele) => (
                                    <p>
                                      {" "}
                                      {ele.certExpiryDate}
                                    </p>
                                  ))} */}
                                  <p> {MCRCDate != "Invalid Date" && MCRCDate != "Invalid date" ? MCRCDate : "To be entered"}</p>
                                </td>
                              </tr>
                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>Aadhaar No.</strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  <p>{props && props.recData && props.recData.doctorCommercial && props.recData.doctorCommercial[0] && props.recData.doctorCommercial[0].adhaarno ? props.recData.doctorCommercial[0].adhaarno : "---"}</p>
                                </td>
                              </tr>

                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>
                                      Correspondence Address of the Consultant
                                    </strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  {(props &&
                                    props.recData &&
                                    props.recData.doctorCommercial &&
                                    props.recData.doctorCommercial[0] &&
                                    props.recData.doctorCommercial[0].Address === null) || (props &&
                                      props.recData &&
                                      props.recData.doctorCommercial &&
                                      props.recData.doctorCommercial[0] &&
                                      props.recData.doctorCommercial[0].State === null) || (props &&
                                        props.recData &&
                                        props.recData.doctorCommercial &&
                                        props.recData.doctorCommercial[0] &&
                                        props.recData.doctorCommercial[0].PinCode === null) ? (
                                    <p style={{ marginRight: "-4rem" }}> --- </p>
                                  ) : (
                                    <p>
                                      {" "}
                                      {props &&
                                        props.recData &&
                                        props.recData.doctorCommercial &&
                                        props.recData.doctorCommercial[0].Address}
                                      ,{" "}
                                      {props &&
                                        props.recData &&
                                        props.recData.doctorCommercial &&
                                        props.recData.doctorCommercial[0].State}
                                      ,
                                      {props &&
                                        props.recData &&
                                        props.recData.doctorCommercial &&
                                        props.recData.doctorCommercial[0].PinCode}
                                    </p>)}
                                </td>
                              </tr>

                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>
                                      Permanent Address of the
                                      Consultant
                                    </strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  {(props &&
                                    props.recData &&
                                    props.recData.doctorCommercial &&
                                    props.recData.doctorCommercial[0] &&
                                    props.recData.doctorCommercial[0].PermanentAddress === null) || (props &&
                                      props.recData &&
                                      props.recData.doctorCommercial &&
                                      props.recData.doctorCommercial[0] &&
                                      props.recData.doctorCommercial[0].PermanentState === null) || (props &&
                                        props.recData &&
                                        props.recData.doctorCommercial &&
                                        props.recData.doctorCommercial[0] &&
                                        props.recData.doctorCommercial[0].PermanentPinCode === null) ? (
                                    <p style={{ marginRight: "-4rem" }}> --- </p>
                                  ) : (
                                    <p>
                                      {props &&
                                        props.recData &&
                                        props.recData.doctorCommercial &&
                                        props.recData.doctorCommercial[0]
                                          .PermanentAddress}
                                      ,{" "}
                                      {props &&
                                        props.recData &&
                                        props.recData.doctorCommercial &&
                                        props.recData.doctorCommercial[0]
                                          .PermanentState}
                                      ,{" "}
                                      {props &&
                                        props.recData &&
                                        props.recData.doctorCommercial &&
                                        props.recData.doctorCommercial[0]
                                          .PermanentPinCode}
                                    </p>)}
                                </td>
                              </tr>
                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>Contact number</strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  <p>
                                    {props && props.recData && props.recData.doctorCommercial && props.recData.doctorCommercial[0].mobile ?
                                      "+91 " +
                                      props &&
                                      props.recData &&
                                      props.recData.doctorCommercial &&
                                      props.recData.doctorCommercial[0].mobile
                                      : "---"}
                                  </p>
                                </td>
                              </tr>

                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>E-mail</strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  <p>
                                    {props &&
                                      props.recData &&
                                      props.recData.doctorCommercial &&
                                      props.recData.doctorCommercial[0].email}
                                  </p>
                                </td>
                              </tr>

                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>Commencement Date</strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  <p>{commencementDate !== 'Invalid date' && commencementDate !== 'Invalid Date' ? commencementDate : "To be entered"}</p>
                                </td>
                              </tr>

                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>Term</strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  <p>
                                    {props &&
                                      props.recData &&
                                      props.recData.doctorCommercial &&
                                      props.recData.doctorCommercial[0].contractlength ?
                                      props &&
                                      props.recData &&
                                      props.recData.doctorCommercial &&
                                      props.recData.doctorCommercial[0]
                                        .contractlength : "---"}{" "}
                                  </p>
                                </td>
                              </tr>

                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>Expiry Date</strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  <p>{futureMonth !== 'Invalid Date' && futureMonth !== 'Invalid date' ? futureMonth : "To be entered"}</p>
                                </td>
                              </tr>

                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>Hospital Address</strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  <p>
                                    No. 3, Survey no. 152/1, 152/4, Doddathoguru
                                    Village, Electronic City Phase 1, Neeladri
                                    Road, Bengaluru South Taluk, Bengaluru –
                                    560100 ( Cloudnine E-City)
                                  </p>
                                </td>
                              </tr>

                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>
                                      Professional Indemnity Amount
                                    </strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  <p>
                                    {props &&
                                      props.recData &&
                                      props.recData.doctorCommercial &&
                                      props.recData.doctorCommercial[0]
                                        .indemnityvalue ?
                                      "Rs " + numberWithCommas(props &&
                                        props.recData &&
                                        props.recData.doctorCommercial &&
                                        props.recData.doctorCommercial[0]
                                          .indemnityvalue) +
                                      "/-"
                                      : "---"}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>Indemnity Expiry Date</strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  <p>
                                    {indemnityExpiryDate != "Invalid Date" && indemnityExpiryDate != "Invalid date" ? indemnityExpiryDate : "To be entered"}
                                    {/* {props &&
                                                                        props.recData &&
                                                                        props.recData.doctorCommercial &&
                                                                        props.recData.doctorCommercial[0]
                                                                            .indemnityexpiry} */}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>
                                      Department (area of service to be
                                      provided)
                                    </strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  <p>
                                    {props &&
                                      props.recData &&
                                      props.recData.doctorCommercial &&
                                      props.recData.doctorCommercial[0]
                                        .departmentlist ?
                                      props &&
                                      props.recData &&
                                      props.recData.doctorCommercial &&
                                      props.recData.doctorCommercial[0]
                                        .departmentlist : "---"}
                                  </p>
                                </td>
                              </tr>

                              <tr>
                                <td valign="bottom">
                                  <p>
                                    <strong>Bank Name </strong>
                                  </p>

                                  <p>
                                    <strong>Account No. &amp; </strong>
                                  </p>

                                  <p>
                                    <strong>IFSC Code</strong>
                                  </p>
                                </td>
                                <td valign="bottom">
                                  {props &&
                                    props.recData &&
                                    props.recData.doctorCommercial &&
                                    props.recData.doctorCommercial[0] &&
                                    props.recData.doctorCommercial[0].bankname === null ? (
                                    <p style={{ marginRight: "-4rem" }}> --- </p>
                                  ) : (
                                    <p>
                                      {" "}
                                      {props &&
                                        props.recData &&
                                        props.recData.doctorCommercial &&
                                        props.recData.doctorCommercial[0].bankname}
                                    </p>)}
                                  {props &&
                                    props.recData &&
                                    props.recData.doctorCommercial &&
                                    props.recData.doctorCommercial[0] &&
                                    props.recData.doctorCommercial[0].bankaccno === null ? (
                                    <p style={{ marginRight: "-4rem" }}> --- </p>
                                  ) : (
                                    <p>
                                      {" "}
                                      {props &&
                                        props.recData &&
                                        props.recData.doctorCommercial &&
                                        props.recData.doctorCommercial[0]
                                          .bankaccno}
                                    </p>)}
                                  {props &&
                                    props.recData &&
                                    props.recData.doctorCommercial &&
                                    props.recData.doctorCommercial[0] &&
                                    props.recData.doctorCommercial[0].bankifsc === null ? (
                                    <p style={{ marginRight: "-4rem" }}> --- </p>
                                  ) : (
                                    <p>
                                      {" "}
                                      {props &&
                                        props.recData &&
                                        props.recData.doctorCommercial &&
                                        props.recData.doctorCommercial[0].bankifsc}
                                    </p>)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        &nbsp;
                        <hr className="style6" />
                        &nbsp;
                        <div style={{ pageBreakBefore: "always" }}>&nbsp;</div>
                        <div className="sch2">
                          <p className="subtitleAgmt"><strong>SCHEDULE – 2</strong></p>

                          <p className="sectitleAgmt"><strong>PART 1</strong></p>

                          <p className="secsubtitleAgmt"><strong>(Description of Medical Services)</strong></p>

                          <p>The Consultant shall perform all the medical services during the term of this agreement including:</p>

                          <p>(a) <strong>{props &&
                            props.recData &&
                            props.recData.vacancyData &&
                            props.recData.vacancyData[0].departmentLabel}</strong></p>

                          <hr className="style6" style={{ width: "25%", background: "grey" }} />

                          <p className="sectitleAgmt"><strong>PART 2</strong></p>

                          <p className="secsubtitleAgmt"><strong>(Description of Professional Fee)</strong></p>
                          {console.log(props.recData.fixedMg, "props.recData.fixedMg")}
                          {props && props.recData && props.recData.fixedMg ?
                            props.recData.fixedMg.map((elem, Index) =>
                              <>
                                <p>
                                  <strong>A.{Index + 1}.1.</strong> <strong>Minimum Guarantee Payment For {elem.Center}:</strong> In consideration of the Consultant providing services to the Hospital, the Company agrees to pay the Consultant an amount of <strong>Rs. {elem.settlementterm}/-</strong> (Rupees {inWords(amount)}) per month (“Minimum Guarantee Amount”) or the “Calculated Earnings” whichever is higher subject to statutory deductions. The Minimum Guarantee Amount will be paid for {elem.mgperiod} <strong>({yearinWords(Mgterm)})</strong> months, commencing from the Effective Date. If the Agreement continues to be in force thereafter, no minimum guaranteed amounts will be paid to the Consultant, unless the Company agrees otherwise. The Minimum Guaranteed Payments for each calendar month or part will be paid as part of the standard cycle of payment as followed by the Company for Consultant.
                                </p>
                                <p>
                                  <strong>A.{Index + 1}.2. </strong>The calculation of the earnings
                                  (“Calculated Earnings”) shall be done on {elem.settlementperiod ? elem.settlementperiod : ''} basis and difference amount (Calculated Earnings minus Minimum Guarantee), if any, shall be paid at the end of {elem.settlementpaymentperiod ? elem.settlementpaymentperiod == 'Yearly' ? 'the year' : elem.settlementpaymentperiod == 'Half Yearly' ? 'half of the year' : elem.settlementpaymentperiod == 'Quaterly' ? 'the quarter' : elem.settlementpaymentperiod == 'Monthly' ? 'the month' : elem.settlementpaymentperiod : ''}.{" "}
                                </p>
                              </>
                            )
                            : ""}


                          <p><strong>A.3. Calculated Earnings:</strong></p>

                          <div className="listAgmt">
                            <AgreementComponent
                              opcomp={
                                props &&
                                props.recData &&
                                props.recData.doctorOPParameter
                              }
                            />
                            <AgreementMgip
                              ipcomp={
                                props &&
                                props.recData &&
                                props.recData.doctorIPParameter &&
                                props.recData.doctorIPParameter
                              }
                            />
                          </div>

                          <p style={{ textAlign: "center" }}>
                            <strong>……………………………</strong>
                          </p>

                          <p>
                            In case you are required to travel on the Company’s
                            business, the Company will make appropriate
                            arrangements, or you will be reimbursed expenses as
                            permissible under the Company’s rules.
                          </p>
                        </div>
                        &nbsp;
                        <hr className="style6" />
                        &nbsp;
                        <div style={{ pageBreakBefore: "always" }}>&nbsp;</div>
                        <div className="sch3">
                          <p className="subtitleAgmt">
                            <strong>SCHEDULE – 3</strong>
                          </p>

                          <p className="secsubtitleAgmt">
                            <strong>
                              DOCUMENTS TO BE SUBMITTED BY THE CONSULTANT
                            </strong>
                          </p>

                          <p>
                            This list is indicative and not exhaustive, and the
                            Company reserves the right to request for additional
                            information about the Consultant as may be required
                            from time-to-time.{" "}
                          </p>

                          <div className="listAgmt">
                            {props &&
                              props.recData &&
                              props.recData.doctordoc && props.recData.doctordoc.find(z => z.docTitle = "Medical Council Registration Certificate No") && props.recData.doctordoc.find(z => z.docTitle = "Medical Council Registration Certificate No").length > 0 ? <p>1. State Registration Certificates                               <i
                                class="fa fa-asterisk"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "6px",
                                }}
                                aria-hidden="true"
                              ></i> </p> : <p>1. State Registration Certificates
                            </p>}
                            {props &&
                              props.recData &&
                              props.recData.doctordoc && props.recData.doctordoc.find(z => z.docTitle = 'MBBS Degree Certificate') && props.recData.doctordoc.find(z => z.docTitle = 'MBBS Degree Certificate').length > 0 ? <p>2. Photocopies Of Degree Certificates                               <i
                                class="fa fa-asterisk"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "6px",
                                }}
                                aria-hidden="true"
                              ></i></p> : <p>2. Photocopies Of Degree Certificates
                            </p>}


                            <p>3. Experience Letter (Testimonials) <i
                              class="fa fa-asterisk"
                              style={{
                                color: "red",
                                fontSize: "10px",
                                marginLeft: "6px",
                              }}
                              aria-hidden="true"
                            ></i></p>
                            {props &&
                              props.recData &&
                              props.recData.doctordoc && props.recData.doctordoc.find(z => z.docTitle = 'Passport Size Photograph') && props.recData.doctordoc.find(z => z.docTitle = 'Passport Size Photograph').length > 0 ?
                              <p>4. Passport Size Photographs                               <i
                                class="fa fa-asterisk"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "6px",
                                }}
                                aria-hidden="true"
                              ></i></p> : <p>4. Passport Size Photographs </p>
                            }
                            {props &&
                              props.recData &&
                              props.recData.doctordoc && props.recData.doctordoc.find(z => z.docTitle = 'PAN Card') && props.recData.doctordoc.find(z => z.docTitle = 'PAN Card').length > 0 ?
                              <p>5. Photocopy of PAN Card <i
                                class="fa fa-asterisk"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "6px",
                                }}
                                aria-hidden="true"
                              ></i></p> : <p>5. Photocopy of PAN Card </p>
                            }

                            {props &&
                              props.recData &&
                              props.recData.doctordoc && props.recData.doctordoc.find(z => z.docTitle = 'AADHAAR Card') && props.recData.doctordoc.find(z => z.docTitle = 'AADHAAR Card').length > 0 ?
                              <p>6. Photocopy of Aadhaar Card <i
                                class="fa fa-asterisk"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "6px",
                                }}
                                aria-hidden="true"
                              ></i></p> : <p>6. Photocopy of Aadhaar Card </p>
                            }


                            {props &&
                              props.recData &&
                              props.recData.doctordoc && props.recData.doctordoc.find(z => z.docTitle = 'Cancelled Cheque (Bank Details)') && props.recData.doctordoc.find(z => z.docTitle = 'Cancelled Cheque (Bank Details)').length > 0 ?
                              <p>7. Cancelled Cheque Leaf <i
                                class="fa fa-asterisk"
                                style={{
                                  color: "red",
                                  fontSize: "10px",
                                  marginLeft: "6px",
                                }}
                                aria-hidden="true"
                              ></i></p> : <p>7. Cancelled Cheque Leaf  </p>
                            }
                            {props &&
                              props.recData &&
                              props.recData.doctordoc && props.recData.doctordoc.find(z => z.docTitle = 'Indemnity Insurance Copy') && props.recData.doctordoc.find(z => z.docTitle = 'Indemnity Insurance Copy').length > 0 ?
                              <p>
                                8. Photocopy of Valid Professional Indemnity Insurance <i
                                  class="fa fa-asterisk"
                                  style={{
                                    color: "red",
                                    fontSize: "10px",
                                    marginLeft: "6px",
                                  }}
                                  aria-hidden="true"
                                ></i>
                              </p> : <p>
                                8. Photocopy of Valid Professional Indemnity Insurance
                              </p>
                            }
                          </div>
                        </div>
                        &nbsp;
                        <hr className="style6" />
                        &nbsp;
                        <div style={{ pageBreakBefore: "always" }}>&nbsp;</div>
                        <div className="sch3">
                          <p className="subtitleAgmt">
                            <strong>ANNEXURE-Platinum</strong>
                          </p>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <table border="1" cellspacing="0" cellpadding="0">
                              <tbody>
                                <tr>
                                  <td valign="top">
                                    <p>
                                      <strong>Gynecology Procedures</strong>
                                    </p>
                                  </td>
                                  <td valign="top">
                                    <p>
                                      <strong>Platinum</strong>
                                    </p>
                                  </td>
                                  <td valign="top">
                                    <p>
                                      <strong>Pay Out</strong>
                                    </p>
                                  </td>
                                </tr>

                                {annexure &&
                                  annexure.map((z) => (
                                    <tr>
                                      <td nowrap="" valign="top">
                                        <p>{z.procedurename}</p>
                                      </td>
                                      <td nowrap="" valign="top">
                                        <p>{z.platinum}</p>
                                      </td>
                                      <td nowrap="" valign="top">
                                        <p>{z.payout}</p>
                                      </td>
                                    </tr>
                                  ))}

                                {/* <tr>
                                  <td nowrap="" valign="top">
                                    <p>Vaginal Hysterectomy</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>88000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>25000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Myomectomy (1 Day package)</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>71500</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>25000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Laparotomy (2 days)</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>71500</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>20000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>
                                      Laparotomy +Myomectomy + Ovarian drilling
                                      (2 Days)
                                    </p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>82500</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>25000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>
                                      Laparotomy with excission of Bilateral
                                      Chocolate cyst
                                    </p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>82500</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>25000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Lap Ectopic</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>77000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>20000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Diagnoistic laparoscopy</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>55000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>12000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Laparocsopic ovarian cystectomy</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>66000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>15000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Lap sterilization with MTP (1 Day)</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>49500</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>15000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Cervical Encirclage (1 day)</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>49500</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>10000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Hysterolaparoscopy</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>44000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>10000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Episiotomy wound repair</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>22000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>5000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Repair of 3degree perineal tear</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>22000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>5000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>D&amp;E</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>33000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>10000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Bartholin Cyst I&amp;D</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>33000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>10000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>
                                      Cervix punch biopsy + cervical
                                      cauterization
                                    </p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>33000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>10000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>
                                      Hysterolaparoscopy with ovarian cystectomy
                                    </p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>49500</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>10000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Lap Sterilization</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>44000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>10000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>MTP</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>33000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>10000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>D&amp;C</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>33000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>10000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>I&amp;D</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>33000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>10000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Tubectomy</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>33000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>10000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>D&amp;C with Polypectomy</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>38500</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>10000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Mid Trimester Abortion</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>49500</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>10000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Lap Hysterectomy</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>99000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>25000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Lap Myomectomy</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>99000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>25000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>
                                      Lap Ovarian Cystectomy (Dermoid/Chocalate)
                                    </p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>66000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>15000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Lap Cholecystectomy</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>88000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>20000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Lap Umbilical Hernia Repair</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>88000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>20000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Diagnoistic Hysteroscopy</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>27500</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>5000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Hysteroscopic Polypectomy</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>38500</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>10000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>Hysteroscopic Septal Resection</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>49500</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>10000</p>
                                  </td>
                                </tr>

                                <tr>
                                  <td nowrap="" valign="top">
                                    <p>
                                      Diagnostic Hysterolaparoscopy+Tubal
                                      Canulation
                                    </p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>44000</p>
                                  </td>
                                  <td nowrap="" valign="top">
                                    <p>10000</p>
                                  </td>
                                </tr> */}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Container>
                      <br />
                      <Container
                        fluid
                        className="cardAgmt"
                        style={{ border: "white" }}
                      >
                        <p className="subtitleAgmt">
                          MEDICAL STAFF BYLAWS Cloudnine hospitals [January
                          2017]
                        </p>

                        <p>
                          Preamble: This Medical Staff Byelaws define and codify
                          the broad framework or charter of rules and
                          regulations, policies and procedures, mutual rights
                          and obligations of the Hospital and the
                          Consultant/medical staff in relation to their practice
                          in the Hospital/s managed or operated by Cloudnine
                          hospitals
                        </p>

                        <p>
                          The Medical Staff Bylaws shall be construed, read and
                          interpreted in conjunction with the binding Medical
                          Professional Services Agreement (hereinafter referred
                          to as the Services Agreement) entered into between
                          Cloudnine hospitals (for and on behalf of hospitals)
                          and the consultant. In the event of any inconsistency
                          or contradiction between the provisions of the Medical
                          Staff Byelaws vis-à-vis the Services Agreement on any
                          subject matter, then the provisions mentioned in the
                          latter( Services Agreement) shall always prevail over
                          the former (Medical Staff Byelaws).{" "}
                        </p>

                        <p>I. DEFINITIONS </p>

                        <p>
                          a. A center head is a person appointed by the
                          Management of Cloudnine Hospitals from time to time
                          conferred with necessary authority to administer the
                          day to day administration of the Hospital.{" "}
                        </p>

                        <p>
                          b. Central Medical Quality (CMQ): shall mean a
                          committee constituted by the management to supervise
                          and manage medical quality at each of the Hospitals.
                          The constitution, roles and responsibilities of the
                          CMQ are more fully described in Clinical Manual,
                          Medical Administration.{" "}
                        </p>

                        <p>
                          c. Clinical Manual, Medical Administration –is a
                          comprehensive codified document explaining rules,
                          responsibilities, protocols, byelaws and polices of
                          the hospital applicable to the Medical Staff{" "}
                        </p>

                        <p>
                          d. Medical director means a member of the Medical
                          Staff appointed by the management of Cloudnine
                          Hospitals to manage the day to day medical/clinical
                          administration of the hospital. The roles and
                          responsibilities of are as mentioned in this document{" "}
                        </p>

                        <p>
                          e. Committees of the Hospital: shall mean all such
                          committees of the Hospital as may be constituted by
                          the management from time to time to address hospital
                          and medical staff related issues that may arise from
                          time to time. The constitution, composition, tenure,
                          powers, duties, roles and responsibilities of the
                          Committee and its members are more fully mentioned in
                          the Clinical Manual, Medical Administration{" "}
                        </p>

                        <p>
                          f. Consultant means the medical staff member directly
                          responsible for patient care at the Hospital
                          possessing requisite academic qualification and
                          experience, more fully mentioned in the Clinical
                          Manual, Medical Administration and privileged by the
                          Medical Practices Committee (MPC). The basic
                          requirement to be appointed as a consultant the doctor
                          should have completed MD/MS/DNB in their specialty at
                          least 5 years before the date of application or should
                          have completed DGO/ DA/ DCH 8 years before the date of
                          application. This stipulation can be waived at the
                          discretion of the Chairman or DMS.
                        </p>

                        <p>
                          g. Quality Assurance Department (QAD) is a department
                          constituted by the management to formulate and oversee
                          the implementation of the polices at each of the
                          hospitals of Cloudnine. The constitution, composition,
                          tenure, powers, duties, roles and responsibilities of
                          the department are more fully mentioned in the
                          Clinical Manual, Medical Administration{" "}
                        </p>

                        <p>
                          h. Hospital shall mean Cloudnine Hospital{" "}
                          <strong>
                            “No. 3, Survey no. 152/1, 152/4, Doddathoguru
                            Village, Electronic City Phase 1, Neeladri Road,
                            Bengaluru South Taluk, Bengaluru – 560100 (
                            Cloudnine E-City)”
                          </strong>{" "}
                          (A unit of Cloudnine Hospitals) and includes the
                          following:{" "}
                        </p>

                        <p>
                          (i) Primary Hospital. For the purposes of this Medical
                          Staff Byelaws and with respect to any consultant full
                          time, part time or visiting, as the case may be, a
                          Primary Hospital shall mean such hospital/facility of
                          Cloudnine where the consultant has agreed to render
                          substantial portion of his/her professional services
                          and charges for his/her professional services are
                          accrued and paid from such hospital.{" "}
                        </p>

                        <p>
                          (ii) Associate Hospital/Facilities For the purposes of
                          this Medical Staff Byelaws and with respect to any
                          consultant rendering his/her services at the hospital
                          either full time, part time or visiting, as the case
                          may be, an Associate Hospital/Facility shall mean and
                          include all such other hospitals and facility/ies
                          (other than the primary hospital) controlled and or
                          managed by Cloudnine where any consultant is required
                          to render his/her professional services on a
                          temporary/long term basis. For the purposes of Medical
                          Staff Byelaws the term “Facilities” shall mean to
                          include the existing clinics, community hospitals,
                          referral hospitals, day care centers, research
                          institutes managed and controlled directly by
                          Cloudnine or to be established in future directly or
                          in association with any other institutions under any
                          arrangement with such other institution.{" "}
                        </p>

                        <p>
                          Medical Staff means all the Consultants (full time,
                          part time, visiting, and junior), Registrars and
                          Medical Officers conferred with practicing rights in
                          the Hospital, including the following categories:{" "}
                        </p>

                        <p>
                          (i) Full Time Consultant: means and includes a medical
                          practitioner possessing the requisite academic
                          qualifications, credentialed and privileged (more
                          fully described in Clinical Manual, Medical
                          Administration) to perform such invasive and/or
                          noninvasive medical procedures at the Hospital either
                          individually or forming a part of consultant’s group
                          rendering his/her professional services at the
                          hospitals controlled and managed by Cloudnine, having
                          agreed to render his/her professional services of not
                          less than 48 hours in aggregate in any week of 7 days
                          having the following rights and restrictions:{" "}
                        </p>

                        <p>
                          Right of outpatient consultation and treatment within
                          the hospital premises Right to admit patients for
                          invasive or noninvasive treatment and procedures under
                          his/her care at the hospital. Shall not have any
                          rights to his/her private practice in any clinics,
                          teaching or non-teaching hospitals, medical colleges,
                          clinics and other medical institutions other than the
                          facilities managed by Cloudnine in any capacity
                          without obtaining Cloudnine’s specific express consent
                          in writing.{" "}
                        </p>

                        <p>
                          (ii) Part time consultant means and include a medical
                          practitioner possessing the requisite academic
                          qualifications, credentialed and privileged (more
                          fully described in Clinical Manual, Medical
                          Administration) to perform such invasive and/or
                          noninvasive medical procedures at the Hospital either
                          individually or forming a part of the consultant’s
                          group rendering his/her professional services at the
                          hospitals controlled and managed by Cloudnine having
                          agreed to render his/her professional services of not
                          less than 12 hours and not more than 48 hours in
                          aggregate in any week of 7 days having the following
                          rights and restrictions. The number of hours mentioned
                          before can be changed with the consent of the
                          Cloudnine administration.
                        </p>

                        <p>
                          a. Right to outpatient consultation and treatment
                          within the hospital premises b. Right to admit
                          patients for invasive or noninvasive treatment and
                          procedures under his/her care at the Hospital. c.
                          Rights to his/her private practice in any clinics,
                          teaching or non-teaching hospitals, medical colleges,
                          clinics and other medical institutions other than the
                          facilities managed by Cloudnine in any capacity.
                          However, such outside Hospital practice shall not have
                          any conflict of interest.{" "}
                        </p>

                        <p>
                          2. Visiting consultant means and includes a medical
                          practitioner possessing the requisite academic
                          qualifications, credentialed and privileged (more
                          fully described in Clinical Manual, Medical
                          Administration) to perform such invasive and/or non
                          invasive medical procedures at the Hospital either
                          individually or forming a part of consultant’s group
                          rendering his/her professional services at the
                          hospitals controlled and managed by Cloudnine having
                          agreed to render his/her professional services as and
                          when required by the hospital or suo motto having the
                          following rights and restrictions{" "}
                        </p>

                        <p>
                          a. Right to admit patients for invasive or non
                          invasive treatment and procedures under his/her care
                          at the Hospital. b. Rights to his/her private practice
                          in any clinics, teaching or non teaching hospitals,
                          medical colleges, clinics and other medical
                          institutions other than the facilities managed by
                          Cloudnine in any capacity. However, such outside
                          Hospital practice shall not have any conflict of
                          interest. c. Shall not have the right to outpatient
                          consultation and treatment within the hospital
                          premises, for any patient other than from his/her own
                          practice{" "}
                        </p>

                        <p>
                          3. Associate consultant means and includes a medical
                          practitioner possessing the requisite academic
                          qualifications, credentialed and privileged (more
                          fully described in Clinical Manual, Medical
                          Administration) to perform such invasive and/or
                          noninvasive medical procedures at the Hospital either
                          individually or forming a part of consultant’s group
                          rendering his/her professional services at the
                          hospitals controlled and managed by Cloudnine having
                          agreed to render his/her professional services as and
                          when required by the hospital or suo motto having the
                          following rights and restrictions. These doctors are
                          consultants who do not have independent admitting
                          rights and will need to under the supervision of a
                          consultant.
                        </p>

                        <p>
                          3. Junior Consultant means and includes a medical
                          practitioner possessing the requisite necessary
                          academic qualifications, credentialed and privileged
                          (more fully described in Clinical Manual, Medical
                          Administration) to perform such invasive and/or
                          noninvasive medical procedures at the hospital either
                          individually or forming a part of consultants group
                          rendering his/ her professional services at the
                          hospitals controlled and managed by Cloudnine having
                          agreed to render his/her professional services only
                          under the supervision/guidance of a consultant.
                          Working hours should not be less than 208 hours in
                          month.
                        </p>

                        <p>
                          4. Registrars means and include a medical practitioner
                          possessing the requisite academic qualifications,
                          credentialed and privileged (more fully described in
                          Clinical Manual, Medical Administration) to perform
                          such invasive and/or noninvasive medical procedures at
                          the hospital either individually or forming a part of
                          consultant’s group rendering his/her professional
                          services at the hospitals controlled and managed by
                          Cloudnine having agreed to render his/her professional
                          services only under the supervision/guidance of a
                          consultant. Working hours should not be less than 208
                          hours in month.
                        </p>

                        <p>
                          5. Fellow means and include a medical practitioner
                          possessing the requisite academic qualifications,
                          credentialed and privileged (more fully described in
                          Clinical Manual, Medical Administration) to perform
                          such invasive and/or noninvasive medical procedures at
                          the hospital either individually or forming a part of
                          consultant’s group rendering his/her professional
                          services at the hospitals controlled and managed by
                          Cloudnine having agreed to render his/her professional
                          services only under the supervision/guidance of a
                          consultant and is enrolled in a Fellowship training
                          program.{" "}
                        </p>

                        <p>
                          6. Medical officer means and include a medical
                          practitioner possessing the requisite academic
                          qualifications, credentialed and privileged (more
                          fully described in Clinical Manual) to perform such
                          invasive and/or noninvasive medical procedures at the
                          hospital either individually or forming a part of
                          consultant’s group rendering his/her professional
                          services at the hospitals controlled and managed by
                          Cloudnine having agreed to render his/her professional
                          services only under the supervision /guidance of a
                          consultant.{" "}
                        </p>

                        <p>
                          7. Primary consultant: shall mean a consultant either
                          full time/part time/visiting consultant as the case
                          may be who is primarily responsible and accountable to
                          the hospital and to the patient in connection with
                          admission and treatment of a patient including for the
                          acts and omissions of the associate
                          consultants/medical registrar/ medical staff
                          (hereinafter referred to as “authorised persons”
                          acting under his/her instruction, supervision and
                          guidance.{" "}
                        </p>

                        <p>
                          8. Referral consultant: shall mean a consultant either
                          full time/part time/visiting consultant of the
                          hospital as the case may be whose opinion is sought by
                          the primary consultant as it is beyond his/her scope
                          of training.{" "}
                        </p>

                        <p>
                          ii. Management means the Board of Directors of
                          Cloudnine hospital, or it’s designate.{" "}
                        </p>

                        <p>II SELECTION OF MEDICAL STAFF </p>

                        <p>A. CREDENTIALING </p>

                        <p>
                          a. All Consultants possessing minimum eligibility
                          criterion (as mentioned in Clinical Manual, Medical
                          Administration)) and interested in obtaining
                          practicing rights in the Hospital shall submit an
                          application, along with other relevant documents as
                          determined by the Management from time to time to the
                          Group Medical Director through the Regional
                          director/Center head/ Medical director/Human Resource
                          Department of the hospital as the case may be for
                          evaluation and approval.{" "}
                        </p>

                        <p>
                          b. The first privileging of the consultants will be
                          granted by the GMD and the same will be ratified after
                          six months in the MPC which will be attended by the
                          GMD or his authorized representative.{" "}
                        </p>

                        <p>
                          c. Medical Practices Committee (MPC) shall confirm all
                          subsequent practicing privileges including renewals.{" "}
                        </p>

                        <p>
                          d. The Center head shall prepare Consultant’s
                          credentials and practicing privileges and place it
                          before the Credentialing Committee (CC), for their
                          confirmation. Any differences between the Center head
                          and the credentialing committee shall be forwarded to
                          the Director of Medical services (DMS) for final
                          approval{" "}
                        </p>

                        <p>
                          e. All Medical Staff members of the hospital shall be
                          entitled to exercise only those medical practicing
                          privileges specifically confirmed to them by the CC.{" "}
                        </p>

                        <p>
                          f. The CC shall investigate, evaluate and recommend to
                          the DMS the entitled medical practicing privileges of
                          the applicant based upon the applicant’s education,
                          training, experience, demonstrated competence, health
                          status, references and other relevant information. The
                          applicant shall have the burden of establishing his
                          qualifications, and competence.{" "}
                        </p>

                        <p>
                          g. The validity of the approval for practicing
                          privileges is 2 years and the renewal may be confirmed
                          by the CC{" "}
                        </p>

                        <p>
                          h. The New Medical Staff Members, or locums will be
                          granted temporary privileges by the Medical director,
                          if needed, following a Consultant joining the Hospital
                          until the applicant can be formally confirmed by the
                          CC at the next meeting. In case of locums, the
                          temporary privileges are valid for a maximum period of
                          three months from date granted to the locum consultant
                          by the Medical director before the locum consultant is
                          allowed to practice in the hospital. For locum
                          Consultants, it is the responsibility of the inviting
                          consultant to forward the locum consultants
                          credentials to the Medical director, for the granting
                          of temporary privileges. The locum consultant must
                          sign the Medical Bye laws and is bound by this
                          document.{" "}
                        </p>

                        <p>
                          i. Applications for medical practicing privileges
                          shall list in writing the privileges and state any
                          relevant training and/or experience appropriate to the
                          request. This request shall be made in the specialty
                          specific privileging form{" "}
                        </p>

                        <p>
                          j. Any consultant may expand the scope of their
                          practicing privileges, utilizing the procedure
                          described above.{" "}
                        </p>

                        <p>
                          k. In the case of an emergency, any Medical Staff
                          member, to the degree permitted by his or her license,
                          shall be permitted and assisted to do everything
                          possible to save the life of a patient, using every
                          facility of the hospital necessary. When the emergency
                          situation no longer exists, such Medical Staff member
                          must request the Medical director to temporarily grant
                          the privileges necessary to continue to treat the
                          patient, or transfer the patient to an appropriate
                          member of the Medical Staff, or to an outside
                          consultant. For the purpose of this situation, an
                          emergency is defined as a condition in which there is
                          danger of serious permanent harm to a patient, or in
                          which the life of a patient is in imminent danger or
                          any delay in administering treatment would add to that
                          danger.{" "}
                        </p>

                        <p>
                          l. Once the CC grants practicing privileges to a
                          Consultant, the Consultant will be considered as a
                          Medical Staff member and the privileges shall remain
                          valid for a 2 year period. This can be terminated if
                          the consultant performs in any way that is detrimental
                          to patient care after due process.
                        </p>

                        <p>
                          m. Notwithstanding the above, the decision to grant or
                          renew the privileges to the Medical staff who (i)
                          absents him/her self from the hospital for a
                          continuous period of sixty (60) days or more due to a
                          medical illness or (ii) who has passed their
                          sixty-fifth birthday shall be subject to the approval
                          of the DMS based on the information provided about the
                          current health and medical reports of the said
                          practitioner. In this regard the decision of the DMS
                          is final.{" "}
                        </p>

                        <p>
                          n. Participation of Medical staff members in the
                          “Controlled Clinical Trials” (CCT) or Clinical
                          Research (CR) shall be in accordance with the
                          “Controlled Clinical Trials” (CCT) or Clinical
                          Research (CR) manual adopted by the Hospital from time
                          to time.{" "}
                        </p>

                        <p>III. REMEDIAL ACTIONS: RULES AND REGULATIONS </p>

                        <p>
                          a. Any Medical Staff member, employee, patient (or
                          their proxy), the Medical director or Center head may
                          request an inquiry regarding a consultant’s conduct or
                          medical care when said party believes a consultant has
                          acted in a way that is:{" "}
                        </p>

                        <p>I. Detrimental to patient care or safety; or </p>

                        <p>II. Against hospital policy; or </p>

                        <p>III. Disruptive to hospital operations. </p>

                        <p>
                          b. All complaints shall be in writing and addressed to
                          the Center head or the DMS and handled as follows:{" "}
                        </p>

                        <p>
                          i. The Center head and the Medical director will
                          review all complaints, as described hereinabove and
                          discuss with the Consultant in question and ask for
                          written accounts from all parties involved. In such an
                          event all such concerned parties shall provide all the
                          necessary information and documents in their knowledge
                          and possession to Center head and the Medical director
                          and they shall forward all related information to the
                          DMS or to the person or committee nominated/designated
                          by the DMS from time to time for this purpose. If
                          involved parties refuse to provide written details,
                          then the Center head/ Medical director will provide
                          such details, based on their investigation and the
                          Medical director reserves the right to initiate
                          appropriate disciplinary actions against such erring
                          members.{" "}
                        </p>

                        <p>
                          Based on the available facts and circumstances, if the
                          DMS or the person/ committee nominated/designated by
                          the DMS is of the opinion that, the matter needs a
                          detailed further investigation then, the same shall be
                          forwarded to the CMQ for a detailed report on the
                          allegation. CMQ shall furnish their report to the DMS.
                          If the findings of the CMQ are suggestive of a
                          prima-facie case then the DMS shall forward the case
                          to the CC for adjudication. However, the report of CMQ
                          being a confidential report meant for the use of
                          DMS/CC, the same shall not be called in question or
                          compelled to be made public by any of the concerned
                          party.{" "}
                        </p>

                        <p>
                          ii. The CC with a minimum quorum of will hear each
                          complaint and submit its report and their
                          recommendation to the Medical director who shall
                          forward the recommendations of the CC to the DMS.
                          Depending upon the recommendation of the CC and the
                          gravity of the situation, the DMS shall decide
                          appropriate course of action which may include the
                          termination of services of the consultant.
                          Notwithstanding the difference of opinion among the
                          members of the CC, the decision of the DMS is final
                          and cannot be called in question by the consultant
                          charged with the allegation or any member of the CC.{" "}
                        </p>

                        <p>
                          iii. Any Consultant whose credentials are under review
                          for any complaint against the said consultant by the
                          CC shall be entitled to request that the CC
                          investigate the matter within 30 days. If the involved
                          Consultant disagrees with either the findings, or
                          actions recommended by the CC, said Consultant may
                          appeal to the DMS within 30 days of such findings of
                          the CC.{" "}
                        </p>

                        <p>
                          iv. The Medical director may temporarily suspend all
                          or any portion of the clinical privileges of a
                          Consultant, prior to CC review, whenever such action
                          must, in the judgment of the Medical director, be
                          taken immediately to safeguard patient safety. The
                          complaint in question will then be taken up by the CC,
                          as described in III above within 48 hours. Appeals: A
                          Consultant may appeal against the decision of CC to
                          the DMS, within 15 days of such decision of the CC in
                          the event of such Consultant does not agree with the
                          CC’s decision with respect to his/her revocation or
                          change in practicing privileges. The DMS will address
                          such appeal and adjudicate within 30 days of receipt
                          of such appeal and the same shall be binding on the
                          Consultant.{" "}
                        </p>

                        <p>V. DUTIES OF Medical directors: </p>

                        <p>
                          1. There will be only one functional medical director
                          for a one year period. If there are more than one,
                          this role will be done on a rotational basis.
                        </p>

                        <p>
                          2. They will need to be informed of all medically
                          related issues on a daily basis including nurse to
                          patient ratio for the last 24 hours, patient
                          complaints, doctor complaints and doctor noncompliance
                          of policies. These should be informed to the Director
                          medical services on a daily basis
                        </p>

                        <p>
                          3. They will be responsible for creating the CME
                          calendar for the month. There will need to be at least
                          one CME meeting a month. The M&amp;M meeting will need
                          to be held every 3 months and can replace one of the
                          CME meetings.
                        </p>

                        <p>
                          4. They will be responsible for ensuring the
                          completion and submission of doctor (consultant and
                          junior) duty rosters to the center heads.
                        </p>

                        <p>
                          5. They will be responsible for helping the center
                          heads resolve all medical care related complaints in a
                          timely manner. The closure of these complaints need to
                          be informed to the Director, Medical services.
                        </p>

                        <p>
                          6. They will be responsible for talking to doctors who
                          are not compliant with hospital policies. In the event
                          there is a conflict of interest the Director of
                          Medical services will do the needful.
                        </p>

                        <p>
                          7. The unit medical directors are encouraged to come
                          up with clinical audits. The Director of Medical
                          services will help with this process whenever
                          required.{" "}
                        </p>

                        <p>
                          8. The unit medical directors with the help of the
                          center heads and the nursing in charge should ensure
                          that all mandatory committee meetings are held and the
                          minutes with action taken are documented and stored.
                        </p>

                        <p>
                          9. The unit medical directors with the help of the
                          center heads and the nursing in charge should ensure
                          that all end of the month reports are sent to the
                          quality team by the 2<sup>nd</sup> of every month.
                        </p>

                        <p>
                          10. The following are the reports that need to be sent
                          on a monthly basis
                        </p>

                        <p>
                          - All NABH QI data including corrective and preventive
                          action (CAPA) taken
                        </p>

                        <p>
                          - All clinical audits done or ongoing with current
                          status
                        </p>

                        <p>- All Incident reports data with details and CAPA</p>

                        <p>
                          - Report of all CMEs conducted with attendance data
                        </p>

                        <p>
                          - M&amp;M report if it is conducted that month with
                          brief report of discussion and CAPA
                        </p>

                        <p>- All patient complaints with CAPA</p>

                        <p>
                          - Report of any disciplinary issues related to doctors
                          and nurses
                        </p>

                        <p>
                          11. They will be involved in the credentialing and
                          privileging the consultants in their units with the
                          help of HRD
                        </p>

                        <p>VI. ORGANIZATION OF THE MEDICAL STAFF </p>

                        <p>
                          a. Each medical staff member shall be a member of a
                          hospital department, as defined by their respective
                          medical specialty. The departments shall consist of
                          the following:{" "}
                        </p>

                        <p>
                          OBG and Surgery : To include all surgery
                          subspecialties{" "}
                        </p>

                        <p>OB/GYN : To include all OB/GYN subspecialties </p>

                        <p>
                          Pediatric Medicine : To include all Pediatrics and
                          NICU{" "}
                        </p>

                        <p>Anesthesia and Radiology</p>

                        <p>
                          b. Each department shall have a co-ordinator, who
                          shall be nominated by Medical director.
                        </p>

                        <p>
                          The functions of each department shall be to assure
                          the delivery of high quality patient care in their
                          respective department by implementation of the
                          policies formulated by the Central Quality Assurance
                          Department (QAD). In the event of a need for an
                          amendment to the existing departmental policy then the
                          proposal for an amendment shall be routed through
                          Medical director to the QAD and QAD shall decide on
                          the necessity for the proposed amendment and decide
                          accordingly. The decision of the QAD is final and
                          binding. All amendments shall take effect only after
                          the approval of the QAD.{" "}
                        </p>

                        <p>
                          Each department shall hold meeting for the department
                          at regular intervals to discuss the issues pertaining
                          to the department. However, the interval between two
                          such departmental meetings shall not exceed 2(one)
                          calendar month. All the business transacted at the
                          departmental meeting shall be minuted and a copy of
                          the same shall be forwarded to the Medical director
                          and center head. The DMS shall have the right to
                          inspect the minutes and take copies without any prior
                          notice.{" "}
                        </p>

                        <p>
                          All the material issues discussed at the department
                          meetings requiring the consideration of the Medical
                          director/DMS / Management shall be immediately brought
                          to the notice of Medical director. If the referred
                          issues are not addressed/no response is received
                          within a reasonable time (however, not later than 2
                          months) then the Department shall bring the same to
                          the notice of the DMS
                        </p>

                        <p>VIII MEDICAL STAFF MEETINGS </p>

                        <p>
                          As and when the situation so warrants, the DMS in
                          consultation with the Medical director shall have the
                          right to call for a meeting of the Medical staff{" "}
                        </p>

                        <p>IX OBLIGATIONS OF MEDICAL STAFF MEMBERS </p>

                        <p>
                          The following are obligations of all Medical Staff
                          members. Failure to perform these obligations shall
                          constitute a default/misconduct by any medical staff.
                          Any repeated default may result in the revocation of
                          clinical privileges at the Hospital, at the discretion
                          of the Management.{" "}
                        </p>

                        <p>a. General: </p>

                        <p>
                          i. Admitting Privileges: Only members of the Medical
                          Staff may admit patients to the Hospital and serve as
                          the Consultant. A Consultant shall at all times be
                          designated for each patient receiving care in the
                          Hospital.{" "}
                        </p>

                        <p>
                          ii. Availability: subject to the extent of services
                          agreed to be rendered by a consultant (i.e Full
                          time/Part time/Visiting as the case may be) the
                          Consultants must be available at all times, except
                          when he/she has made arrangements for another Medical
                          Staff member to replace him or her. A primary
                          practitioner will be considered unavailable if he or
                          she has not responded ( by telephone or otherwise ) to
                          a call from the Hospital within 30 minutes and the
                          Medical director/ Center head will then be notified to
                          appoint a replacement consultant.{" "}
                        </p>

                        <p>
                          iii. Emergency Department Call: All Medical Staff
                          members, except the visiting consultants, are expected
                          to participate in the on-call rotation for the
                          Emergency Department. On call request will be
                          considered unperformed if a consultant has not
                          responded (by telephone or otherwise) within 30
                          minutes. Call shall be optional for the consultant who
                          has crossed the age of 60 years, or if granted a
                          waiver from call, by the CC.{" "}
                        </p>

                        <p>
                          iv. Continuing Medical Education: All Medical Staff
                          are required to accumulate a minimum of 24 hours of
                          continuing medical education credits every calendar
                          year. The CME requirement will begin on January 1,
                          following a Consultant’s signing of the service
                          contract. If a Consultant fails to comply with this
                          CME requirement, the matter will be forwarded to the
                          CC for review.{" "}
                        </p>

                        <p>
                          v. Clinical Practice Guidelines: All Medical Staff
                          shall conform to the Clinical Practice Guidelines
                          adopted by the hospital from time to time.{" "}
                        </p>

                        <p>
                          vi. Participate in induction imparted by the hospital
                          before commencing his/her clinical practice at the
                          hospital. In case of conflicting medical opinions by
                          the consultants the same shall be escalated to CMS for
                          seeking expert/second opinion. Participate in various
                          committees of hospital. Shall adhere to the grooming
                          and dress code norms as may be adopted by the hospital
                          from time to time Except for furnishing
                          recommendations to the hospital administration for
                          procurement of drugs, implants, equipments and other
                          consumables, the medical staff shall not involve in
                          promotion of any particular drug, implant, equipment
                          as the case may be . No indulgence in unfair/unethical
                          trade practices Shall adhere to the safety policy as
                          adopted by the hospital in specific and Medical code
                          of ethics in general Each and every document required
                          to be signed by the primary consultant (not limited to
                          the prescription, case progress notes etc.,) shall be
                          promptly signed by the respective primary consultant.
                          However, in the event of any document being signed by
                          a person other than the primary consultant but under
                          the express authority of the primary consultant then
                          the primary consultant shall subsequently verify and
                          endorse the correctness of the contents of the
                          document signed by the authorised person.
                          Notwithstanding the fact that, the primary consultant
                          is not in agreement with the medical opinion expressed
                          by his authorised person, still be accountable and
                          liable for the same. However, this fact shall be
                          brought to the notice of the Medical director without
                          any delay and shall extend all support to the Medical
                          director/ Center head to minimize the impact arising
                          out of the act or omission of his/her authorised
                          person. Maintain Membership of an organization in good
                          standing in their specialty.{" "}
                        </p>

                        <p>
                          Not be convicted of, or have pled nolo contendere to
                          any criminal activity.{" "}
                        </p>

                        <p>
                          Participate in Hospital’s quality improvement,
                          utilization review and risk management programs.{" "}
                        </p>

                        <p>
                          Comply with hospitals procedures and policies to
                          monitor and control the expenses of the hospital in
                          order to promote cost effective services.{" "}
                        </p>

                        <p>
                          Comply with hospitals corporate responsibility program
                          and standard of conduct which is part of that program.{" "}
                        </p>

                        <p>
                          Cooperate in good faith any investigation or assist
                          the hospital to investigate any complaints made by the
                          patients or hospital personnel and to resolve such
                          complaints in a reasonable time with appropriate
                          action.{" "}
                        </p>

                        <p>
                          Participate in training and educational programs
                          applicable to the service provided as mandated by the
                          hospital.{" "}
                        </p>

                        <p>
                          Consultants shall also comply with all the hospital
                          and professional licensure and reimbursement laws,
                          regulations, rules and policies, including without
                          limitations all applicable standards and
                          recommendations of the National Board of Accreditation
                          for hospitals and health care workers.{" "}
                        </p>

                        <p>
                          Consultant agrees to participate with all the
                          insurance companies and TPA that Cloudnine partners
                          with time to time. Consultant shall follow all the
                          rules of the Cloudnine billing policy.{" "}
                        </p>

                        <p>
                          Participate in letter and spirit in the hospital’s
                          Infection Control Program.{" "}
                        </p>

                        <p>
                          Implement all clinical programs that are rolled out
                          for various specialties from time to time by
                          Cloudnine.
                        </p>

                        <p>
                          Any Consultant who is allegedly on substance abuse
                          will submit to the processes established by Cloudnine
                          to confirm/ rule out substance abuse. Failure to
                          submit to such processes shall be grounds for
                          termination.{" "}
                        </p>

                        <p>
                          The Medical director shall call upon any or several
                          consultants involved in the management of a case to be
                          present at the Mortality and Morbidity (M&amp;M)
                          meeting or any similar forum for discussion. Failure
                          to appear for such discussions without due reason or
                          prior intimation is sufficient ground for disciplinary
                          action.{" "}
                        </p>

                        <p>
                          In accordance with the statutory provisions ,
                          pre-natal sex determination shall not be conducted in
                          Cloudnine hospitals by any medical staff.{" "}
                        </p>

                        <p>
                          The examination of the opposite gender shall be done
                          only in presence of a chaperone of the same gender as
                          the patient, or a family member of the patient under
                          examination. Appropriate precautions shall be taken
                          while examining the patients of the opposite gender.
                          Personal beliefs/views about a patient’s lifestyle,
                          culture, gender, belief, age, social status or
                          perceived economic worth must never prejudice the
                          treatment of the patient under the Medical staff’s
                          care.{" "}
                        </p>

                        <p>
                          Patient/family as the case may be to be fully involved
                          in all decision making with respect to patients
                          treatment /procedure{" "}
                        </p>

                        <p>
                          All referrals should be sent to the consultant on call
                          unless specific conditions warrant otherwise and this
                          shall be documented in the patient notes.{" "}
                        </p>

                        <p>
                          The Medical Staff shall participate in the roles
                          assigned in various codes like code blue{" "}
                        </p>

                        <p>
                          The Medical staff is also responsible to follow all
                          policies, manuals, guidelines and protocols etc.
                          issued from time to time.{" "}
                        </p>

                        <p>
                          The medical staff is bound by the Prevention of sexual
                          harassment (POSH) policy of the hospitals.
                        </p>

                        <p>Admission and Discharge of Patients: </p>

                        <p>
                          i. Provisional diagnosis: Patients shall not be
                          admitted to the Hospital until a provisional diagnosis
                          of the patient, or a valid reason for admission has
                          been made.{" "}
                        </p>

                        <p>
                          ii. Necessary information: It is the duty of the
                          consultant to provide such available written
                          information as may be necessary to assist in the
                          protection of a patient from self-harm or for the
                          protection of others.{" "}
                        </p>

                        <p>
                          iii. Consent for admission and treatment: Prior to
                          initiating any non-emergent operation or procedure, or
                          to admitting a patient to the hospital, the primary
                          consultant shall obtain the general consent for
                          admission and treatment from the patient and/or the
                          patient’s family. Except in emergency situations, such
                          treatment shall not be ordered until proper consent
                          has been obtained. Further, the Consultant must
                          explain to the patient and/or patient’s family:1) the
                          procedure to be performed, the potential complication
                          and potential benefits of the procedure, or 2) the
                          reasons for admitting the patient.{" "}
                        </p>

                        <p>
                          In emergency situations where a patient is
                          indisposed/legally unable to give consent and a
                          guardian is not readily available, consent for medical
                          procedures must be obtained from the Medical director.
                        </p>

                        <p>
                          iv. Discharge Orders: Patients will be discharged only
                          on order of the consultant and /or his designee. If a
                          patient leaves the hospital without an order and
                          against the primary consultant’s advice, the patient
                          should be asked to sign an “Against Medical Advice”
                          form.{" "}
                        </p>

                        <p>
                          The Primary Consultant then must make note of the
                          incident and the surrounding circumstances in the
                          patient’s medical record.{" "}
                        </p>

                        <p>
                          v. Day-passes/transfer to outside facilities: Day
                          passes or transfers to outside facilities of patients
                          for tests/evaluations/treatment not available in
                          Hospital are permitted. These passes should be
                          supported by an order of the primary consultant in the
                          patient’s medical record.{" "}
                        </p>

                        <p>Orders: </p>

                        <p>
                          1. All orders for admission, treatment and performance
                          of test or other procedures shall be in writing/
                          electronically in the HER, in the patient’s medical
                          record. Under unavoidable circumstances, verbal order
                          shall be routed through the in-house medical staff of
                          the hospital and the same shall be authenticated by
                          the ordering consultant within 24 hours from the time
                          of order.{" "}
                        </p>

                        <p>
                          2. All orders must be timed, date, and signed by the
                          primary consultant{" "}
                        </p>

                        <p>
                          3. Admitting orders must contain admitting diagnosis,
                          and desired ward (i.e. ICU/CCU vs. Non ICU ward room).{" "}
                        </p>

                        <p>
                          4. The primary consultant’s orders must be written
                          legibly. Orders that are illegible or improperly
                          written will not be carried out until clarified by the
                          primary consultant.{" "}
                        </p>

                        <p>Medical Records – Inpatient </p>

                        <p>
                          i. The Primary Consultant is responsible for the
                          following components in patient medical record:{" "}
                        </p>

                        <p>
                          1. A brief current history and physical examination by
                          the primary consultant or any member of his team shall
                          be recorded and appropriate orders written within 30
                          minutes after admission of the patient. The consultant
                          must countersign the detailed initial admission record
                          within 24 hours of admission.{" "}
                        </p>

                        <p>
                          2. Operative Report(s): Operative reports shall be
                          completed immediately following surgery. The reports
                          shall include the pre-operative and post-operative
                          diagnoses, an account of the findings at surgery and
                          the relevant details of the surgical technique.
                          Complications and whether any specimens were sent to
                          pathology should also be noted{" "}
                        </p>

                        <p>
                          3. Anesthetic Record: An anesthetic record shall be
                          completed by the primary anesthetist on all patients
                          receiving regional block or general anesthesia. This
                          record should include a pre anesthetic assessment,
                          clinical findings on physical exam, relevant lab tests
                          and overall impression of anesthetic risk. Details
                          regarding delivery of anesthesia, ET tube, regional
                          block location, etc. should also be mentioned. All
                          drugs or fluids given, along with time, dosage and
                          route should be noted. Periodic vital signs should be
                          recorded. Any intra-operative or post-anesthetic
                          complications should be noted.{" "}
                        </p>

                        <p>
                          4. Progress Note (daily): Patient progress notes shall
                          be recorded at least twice by the treating time and at
                          least once by the primary consultant daily at the time
                          of observation. They shall reflect a pertinent,
                          chronological report of the patient’s course in the
                          hospital, changes in the patient’s condition, the
                          results of treatment and any briefing of patient or
                          family.{" "}
                        </p>

                        <p>
                          5. Discharge Summary: A discharge summary form shall
                          be completed for all patients’ hospitalized. The
                          discharge summary should reflect the reason for
                          hospitalization, the significant findings, procedures
                          performed and treatment rendered, the condition at
                          discharge and any instructions (including discharge
                          medications) given to the patient and/or family. In
                          case of discharge against medical advice then the same
                          shall be explicitly mentioned in the discharge summary
                          including the associated risks of going against
                          medical advice.{" "}
                        </p>

                        <p>
                          6. Referral and Referral Consultant’s Report: all
                          referrals shall be in accordance with the referral
                          protocol/policy adopted by the hospital. The Referral
                          consultant’s report shall document a review of the
                          patient’s record, pertinent findings and his/her
                          opinion on the referred subject and recommendations.
                          All referrals should be seen within the specified time
                          limit mentioned hereinafter and the medication ordered
                          should be informed /discussed with the primary
                          consultant{" "}
                        </p>

                        <p>Time limits </p>

                        <p>
                          (i) For emergency consultation like life threatening
                          conditions – immediate (ii) For urgent- like unstable
                          vitals – within 4 hours from the time of referral
                          (iii) For routine – within 24 hours (iv) pre-operative
                          consultation shall be recorded prior to surgery. .{" "}
                        </p>

                        <p>
                          In the event of a request by the patient/patient’s
                          kin/ medical staff/ Center head for the change of a
                          consultant (primary/referral) as the case may be, then
                          all such requests shall be forwarded to the Medical
                          director and the Medical director subject to
                          acceptance by the new consultant shall make necessary
                          arrangements to transfer the patient’s care to the new
                          consultant.{" "}
                        </p>

                        <p>
                          ii. Completion of Medical Records: All medical staff
                          shall strictly adhere to the Electronic/ written
                          Medical Records policy adopted by the hospital of
                          his/her attachment and the entries in the same shall
                          be completed in all respects on or before discharge of
                          the patient. If a Consultants’ medical records are
                          incomplete within the stipulated period, the Medical
                          director will be alerted. The Medical director will in
                          turn notify the Consultant and ensure compliance.{" "}
                        </p>

                        <p>
                          iii. All medical records are the property of the
                          hospital and shall not be removed or copied from the
                          hospital except by court order/written request of the
                          patient/patient’ next kin/patient’s authorized
                          representative statutory authorities (to the extent
                          permissible under law).In the case of readmission of a
                          patient, all previous record shall be available for
                          use by the primary consultant.{" "}
                        </p>

                        <p>
                          iv. All medical records of MTP shall be hidden from
                          view unless the CMS authorizes the same.{" "}
                        </p>

                        <p>
                          v. Medical information may be released internally
                          without patient consent only for the purpose of
                          patient care, peer review, quality improvement
                          activities, and research.{" "}
                        </p>

                        <p>Medical Records – Outpatient </p>

                        <p>
                          i. Each outpatient report should include the diagnosis
                          and treatment plan of the consultant, supported by
                          current and historical information, objective physical
                          examination findings plus laboratory and/or radiology
                          information that support the diagnosis and treatment
                          plan. The patient notes shall also reflect any
                          preventive action and nutritional advice given{" "}
                        </p>

                        <p>
                          ii. If there is a consistent pattern of incomplete
                          outpatient medical records by a consultant, the
                          Medical director will consider remedial action{" "}
                        </p>

                        <p>
                          iii. Release of Outpatient Medical Records: Issues
                          regarding custody and release of outpatient medical
                          records shall be treated the same as inpatient medical
                          records.{" "}
                        </p>

                        <p>Use of Hospital Services </p>

                        <p>
                          i. All drugs and medications administered to patients
                          shall be only those listed in the hospital formulary.
                          If a drug, or its equivalent, is not available on the
                          formulary, then the hospital pharmacist should be
                          requested to obtain the medication. Drugs prescribed
                          outside the formulary should be approved by the
                          Medical director if it is for one time use.
                          Consultants may request addition / deletions as per
                          their requirements to the Medical director, who will
                          then initiate the process for change in the formulary
                          as per policy.{" "}
                        </p>

                        <p>
                          ii. Only if the pharmacist is unsuccessful in
                          obtaining the desired medication, shall the medication
                          be obtained from outside the hospital. Hospital
                          formulary is dynamic in nature and will be updated
                          regularly as per the protocol adopted for the same.
                          The prescriptions must be as per protocol.{" "}
                        </p>

                        <p>
                          iii. All medicines procured from outside as prescribed
                          herein above must be in a container clearly identified
                          as to patient’s name, number, and strength. It must be
                          given to the hospital for safekeeping and
                          administration. It should only be administered if
                          supported by a proper written order and verified by
                          the consultant.{" "}
                        </p>

                        <p>
                          iv. Consultants will utilize hospital diagnostic
                          services (laboratory, radiology, etc.,) as warranted
                          by the patient’s medical condition, and not refer to
                          outside facilities for tests or diagnostic procedures
                          which are available in the hospital. For services not
                          available at the hospital, the consultant shall refer
                          to outside facilities where the hospital have entered
                          into any reciprocal arrangement for such referral
                          diagnostic tests.{" "}
                        </p>

                        <p>
                          v. Consultant will refer to other Medical Staff
                          Specialist for opinions and treatment on patient
                          matters outside of their respective specialty area, on
                          all hospital patients requiring such referrals, unless
                          a patient requests otherwise. Consultant may refer to
                          outside specialists if a specific specialty is not
                          available in the Hospital with the concurrence of the
                          Chief of Medical services.{" "}
                        </p>

                        <p>
                          vi. Consultants shall participate in the training
                          programs organized for any new software/appliance/
                          instrument either for patient care or for
                          administrative use as and when introduced{" "}
                        </p>

                        <p>X: AMENDMENTS </p>

                        <p>
                          No amendments to this byelaw shall take effect without
                          the explicit consent of the management in writing.
                        </p>

                        <hr className="style6" />

                        <p className="subtitleAgmt">
                          <strong>ACKNOWLEDGEMENT</strong>
                        </p>

                        <p>
                          I acknowledge the receipt of Company’s ‘medical Staff
                          bylaws’ and I have read and understand that being a
                          consultant of the Company I require to abide by all
                          standards and policies contained under this bylaws.
                        </p>

                        <p>
                          I agree to maintain all company information
                          confidentially and shall not disclose to any third
                          person, unless in the ordinary course of business.
                        </p>

                        <p>
                          <span className="ackAgmt">Name:</span> Dr.{" "}
                          {props &&
                            props.recData &&
                            props.recData.doctorCommercial &&
                            props.recData.doctorCommercial[0].fullname}
                        </p>

                        <p>
                          <span className="ackAgmt">Department:</span>{" "}
                          {props &&
                            props.recData &&
                            props.recData.doctorCommercial &&
                            props.recData.doctorCommercial[0].departmentlist}
                        </p>

                        <p>
                          <span className="ackAgmt">Signature:</span>{" "}
                          _____________________________
                        </p>

                        <p>
                          <span className="ackAgmt">Date:</span>{" "}
                          _________________________________
                        </p>
                      </Container>
                    </Card.Body>
                  </Card>
                </div>

                {/* <footer className="footerCss"> */}
                <footer></footer>
              </div>
            </Container>
            <Row style={{ margin: "1rem 2rem" }} id="section2">
              <Col md={2}>
                {/* <button class="hidden-print" onClick={window.print()}>Print</button> */}
                <Button
                  className="hidden-print"
                  color="secondary"
                  startIcon={<SimCardDownloadIcon />}
                  variant="contained"
                  id="btn"
                  onClick={printAll}
                >
                  Generate PDF
                </Button>
              </Col>
              {/* <Col md={2}>
                {users[0] && users[0].users_role_id === 3 ? (
                  <>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        confirmAgreement(props.recData.onBoardData[0].id);
                      }}
                    >
                      {" "}
                      Confirm{" "}
                    </Button>
                  </>
                ) : (
                  ""
                )}
              </Col> */}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default FixedMG;
