import React from 'react'
import { Paper } from '@material-ui/core'
import MaterialTable from 'material-table'
import EditIcon from '@mui/icons-material/Edit';

function ListRequisition({ rows, editRow, viewRow, deleteRow, downloadExcel, filterCenters, filterDatas }) {
    return (
        <>
            <Paper>
                <MaterialTable
                    columns={[
                        { title: "Request Id", field: "requestId", cellStyle: { padding: "5px 2px", textAlign:'center' } },
                        { title: "Doctor Name", field: "name", cellStyle: { padding: "5px 2px", textAlign:'center' } },
                        {
                            title: "LT Email ID",
                            field: "lt_email_id",
                            render: (row) => (
                                <>
                                    {row.lt_email_id?row.lt_email_id:"--"}
                                </>
                            ), cellStyle: { padding: "5px 2px", textAlign:'center' }
                        },
                        {
                            title: "Personal Email ID",
                            field: "email",
                            customFilterAndSearch: (term, rowData) => rowData.email ? rowData.email.includes(term) : "",
                            render: (row) => (
                                <>
                                    {row.email ? row.email : "--"}
                                    {/* <EditIcon
                                        style={{ fontSize: "20px", color: "#5f5fe7", cursor: "pointer" }}
                                        onClick={(row) => editRow(row)}
                                    /> */}
                                </>
                            ), cellStyle: { padding: "5px 2px", textAlign:'center' }
                        },
                        {
                            title: "Center", field: "centerName", cellStyle: { padding: "5px 2px", textAlign:'center' },
                            lookup: filterCenters
                        },
                        {
                            title: "Request Type",
                            field: "requestType",
                            render: (row) => row.requestType, cellStyle: { padding: "5px 2px", textAlign:'center' }
                        },
                        {
                            title: "Designation", field: "designationLabel", cellStyle: { padding: "5px 2px", textAlign:'center' },
                            lookup: filterDatas
                        },
                        {
                            title: "Pending By", field: "pendingBy", cellStyle: { padding: "5px 2px", textAlign:'center' },
                            lookup: {
                                "BH": "BH",
                                "Corporate HR": "Corporate HR",
                                "COO": "COO",
                                "CEO": "CEO",
                                "Doctor": "Doctor",
                                "DRM": "DRM",
                                "HR": "HR",
                                "Payout Team": "Payout Team",
                                "None": "None"
                            }
                        },
                        {
                            title: "Status", field: "currentStatus", cellStyle: { padding: "5px 2px", textAlign: 'center' },
                            lookup: {
                                "Initiated": "Initiated",
                                "Saved as Draft": "Saved as Draft",
                                "To be review the commercials": "To be review the commercials",
                                "Send back to edit Doctor's Personal Information": "Send back to edit Doctor's Personal Information",
                                "Doctor info to be filled": "Doctor info to be filled",
                                "Documents to be uploaded": "Documents to be uploaded",
                                "To be verified by Corporate HR": "To be verified by Corporate HR",
                                "To be verified by COO": "To be verified by COO",
                                "Sent back to review the commercials and re-submit": "Sent back to review the commercials and re-submit",
                                "To be verified by BH": "To be verified by BH",
                                "Send back to resubmit doctors document": "Send back to resubmit doctors document",
                                "Renewal Initiated": "Renewal Initiated",
                                "Agreement to be signed by Doctor": "Agreement to be signed by Doctor",
                                "Agreement to be signed by CEO": "Agreement to be signed by CEO",
                                "LT Id need to link": "LT Id need to link",
                                "Manual Signed Agreement to be upload": "Manual Signed Agreement to be upload",
                                "Onboarded": "Onboarded",
                                "Sent for Discontinue": "Sent for Discontinue",
                                "Deactivated": "Deactivated",
                                "PENDING": "PENDING",
                            },
                            render: (row) => row.isDeleted? 'Deleted': row.currentStatus
                        },

                        // {
                        //     title: "Status",
                        //     field: "isConfirmed",
                        //     lookup: {
                        //         1: "Onboarded",
                        //         0: "Onboarding in process",
                        //         true: "Onboarded",
                        //         false: "Onboarding in process",
                        //     },
                        // },
                    ]}
                    actions={[
                        {
                            icon: "edit",
                            tooltip: "Edit",
                            iconProps: { style: { fontSize: "24px", color: "rgb(129, 14, 54)" } },
                            onClick: (event, row) => editRow(row),
                        },
                        rowData =>(rowData.isDeleted?
                            {
                                icon: 'restore',
                                tooltip: "Restore",
                                iconProps: { style: { fontSize: "24px", color: "rgb(129, 14, 54)" } },
                                disabled: false,
                                onClick: (event, row) => deleteRow(row)
                            }
                        :
                            {
                                icon: 'delete',
                                tooltip: "Delete",
                                iconProps: { style: { fontSize: "24px", color:"rgb(129, 14, 54)" } },
                                disabled: false,
                                onClick: (event, row) => deleteRow(row)
                            }
                        ),
                        {
                            icon: 'visibility',
                            tooltip: "View Agreement",
                            iconProps: { style: { fontSize: "24px", color: "rgb(129, 14, 54)" } },
                            onClick: (event, row) => viewRow(row),
                        },
                        {
                            icon: () => <button type="button">Export</button>,// you can pass icon too
                            tooltip: "Export to Excel",
                            onClick: () => downloadExcel(),
                            isFreeAction: true
                        },
                    ]}
                    data={rows}
                    options={{
                        filtering: true,
                        sorting: true,
                        // exportButton: true,
                        pageSize: 5,
                        pageSizeOptions: [5, 10, 50, 100, 200, 500],
                        search: true,
                    }}
                    icons={{
                        Filter: "filter",
                    }}
                    title="Requisitions"
                />
            </Paper>
        </>
    )
}

export default ListRequisition