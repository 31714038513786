import React from 'react'
import { useState } from 'react'
import { useSelector } from "react-redux";
import AppSidebar from '../../Components/AppSidebar'
import XLSX from 'xlsx'
import moment from "moment";
import Navmain from '../../Components/Navbar'
import { AppBar, Dialog, Grid, IconButton, Toolbar, Slide, List } from '@material-ui/core'
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import ListIcon from "@material-ui/icons/List";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@mui/material'
import ListRequisition from './tables/ListRequisition'
import EditRequisition from './forms/EditRequisition'
import { useEffect } from 'react'
import axios from 'axios'
import Backdrop from "@material-ui/core/Backdrop";
import { toast } from "react-toastify";
import c9gif from '../../SampleFile/cloudnine.gif';
import { APIURL } from '../../CommomMethods/Fetch'
import Agreement from '../../Pages/Payout/NEW/Agreement';
import { onboardState } from '../../CommomMethods/States';
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styleSheet = makeStyles((theme) => ({
    iconButtonStyle: {
        float: "right",
    },
    marginforMaster: {
        marginTop: "30px",
    },
}));
const backDropStyle = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
}));
function ManageRequisitions() {
    const classes = styleSheet();
    const backClasses = backDropStyle();
    const [preview, setPreview] = useState(true);
    const [editing, setEditing] = useState(false);
    const [requisition, setRequisition] = useState({});
    const [rows, setRows] = useState([]);
    const [filterCenters, setFilterCenters] = useState([]);
    const [filterDatas, setfilterDatas] = useState([])
    const [backDropOpen, setBackDropOpen] = useState(false);
    const [data, setData] = useState(false);
    const [docData, setDocData] = useState([]);
    const [viewAgreement, setViewAgreement] = useState(false);
    const [categoty, setCategory] = useState("");
    const [generateLTButtonStatus, setGenerateLTButtonStatus] = useState(true);

    // delete
    const [isDeletedModalOpen, setIsDeletedModalOpen] = useState(false);
    const [deletedRequestDetails, setDeletedRequestDetails] = useState(null);
    const [deletedRequestRemarks, setDeletedRequestRemarks] = useState("");


    const userLogin = useSelector((state) => state.userLogin);
    const { user } = userLogin;

    // get all DoctorList
    useEffect(() => {
        requisitionListFetch();
        axios
            .get(APIURL() + "centers/")
            .then((res) => res.data)
            .then((rows) => {
                var centerFilter = rows.reduce((a, v) => ({ ...a, [v.name]: v.name }), {})
                console.log(centerFilter, 'centerslist')
                setFilterCenters(centerFilter);
            });
        axios.get(APIURL() + "designations/").then((res) => {
            var desFilters = res.data.data.reduce((a, v) => ({ ...a, [v.name]: v.name }), {})

            console.log(desFilters, 'designations')
            setfilterDatas(desFilters);
        });
    }, [data])

    const requisitionListFetch = async () => {
        try {
          const res = await axios.get(APIURL() + "admin/requisitions");
          if (res.status == 200) {
            setRows(res.data);
          }
        } catch (error) {
          console.log("requisitionListFetch error", error);
          toast.error("Something went wrong.");
        }
      };

    //populate single record in the Edit component
    const editRow = async (row) => {
        setPreview(false);
        setEditing(true);
        await axios.get(`${APIURL()}admin/requisitions/${row.id}`).then((res) => {
            setRequisition(row);
            console.log(row, "requisitions===========")
        })
    };

    //populate single record in the View component
    const viewRow = (row) => {
        console.log(row, "department===========")
        axios
            .get(APIURL() + "onboard/agreementView/" + row.doctorId)
            .then((res) => {
                if (res.data != null) {
                    console.log(res.data.data, "category-view");
                    setCategory(res.data.data.vacancyData[0].category);
                    setDocData(res.data.data);
                }
            });
        setViewAgreement(true);
    };

    const closeAgreement = () => {
        setViewAgreement(false);
        setDocData([]);
        setCategory("");
    };

    // Delete Requesition
    const deleteRow = (row) => {
        console.log("sadsadsad", row)
        setIsDeletedModalOpen(true);
        setDeletedRequestDetails(row);
        setDeletedRequestRemarks("");
        // var k = window.confirm("Are you sure want to delete this record ?");
    }
    const deleteAction = (row = deletedRequestDetails, remarks = deletedRequestRemarks) => {
        // console.log("sadsadsad", row)
        if(remarks === ""){
            toast.error("Please Put The Remarks.")
        }
        else{
            setData(false);
            let url = `${APIURL()}admin/requisitions/delete-requisition/${row.id}`;
            if(row.isDeleted){
                url = `${APIURL()}admin/requisitions/restore-requisition/${row.id}`;
            }
            axios.put(url, {
                requestId: row.id,
                doctorName: row.name,
                userId: user.user.id,
                role: user.user.roles,
                remarks: remarks,
            }).then((res) => {
                setData(true);
                if (res.status === 200) {
                    setIsDeletedModalOpen(false);
                    setDeletedRequestDetails(null);
                    setDeletedRequestRemarks("");
                    toast.success(res.data.message)
                } else {
                    toast.error(res.data.message)
                    setBackDropOpen(false)
                }
            }).catch((err) => {
                toast.error(err.data.message)
                setBackDropOpen(false)
            })
        }
    }

    // Update Onboarding Status
    const updateOnboardingStatus = async (data) => {
        axios.post(`${APIURL()}onboard/action`, {
            doctorId: data.doctorId,
            name: data.name,
            currentAction: data.status,
            currentActionBy: user.user.id,
            role: user.user.roles,
            requested_id: data.requested_id,
            nextAction: onboardState.init.state,
            remark: data.remarks,
            status: 1,
        }).then((res) => {
            setBackDropOpen(false)
            setPreview(true);
            setEditing(false);
            setRequisition({});
            setData(true);
            toast.success("Status Updated Successfully")
        }).catch((err) => {
            toast.error(err.data.message)
            setBackDropOpen(false)
        })
    }

    // Update Commencement Date
    const updateOnboardingCommencementDate = async (data) => {
        let payload = {
            doctorId: data.doctorId,
            name: data.name,
            userId: user.user.id,
            role: user.user.roles,
            requested_id: data.requested_id,
            remark: data.remarks,
        }
        if(data.doj){
            payload['doj'] = data.doj;
          }
          if(data.stampdate){
            payload['stampdate'] = data.stampdate //This is updating cdoj field.
          }
        axios.put(`${APIURL()}admin/requisitions/upadte-commencement-date/${data.requested_id}`, payload).then((res) => {
            setBackDropOpen(false)
            setPreview(true);
            setEditing(false);
            setRequisition({});
            setData(true);
            requisitionListFetch();
            toast.success(data.doj ? "Joining Date Updated Successfully." : "Commencement Date Updated Successfully.")
        }).catch((err) => {
            toast.error(err.data.message)
            setBackDropOpen(false)
        })
    }

    // Update Onboarding Designation / Department
    const updateOnboardingDepDeg = async (data) => {
        setData(false);
        axios.put(`${APIURL()}admin/requisitions/upadte-dep-des/${data.requested_id}`, {
            doctorId: data.doctorId,
            name: data.name,
            designationLabel: data.designationLabel,
            departmentLabel: data.departmentLabel,
            userId: user.user.id,
            role: user.user.roles,
            requested_id: data.requested_id,
            remark: data.remarks,
        }).then((res) => {
            setBackDropOpen(false)
            setPreview(true);
            setEditing(false);
            setRequisition({});
            setData(true);
            toast.success("Detail Updated Successfully")
        }).catch((err) => {
            toast.error(err.data.message)
            setBackDropOpen(false)
        })
    }

    // Send Again for Digio Signature
    const sendToDigio = async (data) => {
        setData(false);
        await axios.post(`${APIURL()}onboard/generate-agreement`, {
            doctorId: data.doctorId,
        }).then(async (res) => {
            await axios.post(`${APIURL()}onboard/action`, {
                doctorId: data.doctorId,
                name: data.name,
                currentAction: onboardState.rd.state,
                currentActionBy: user.user.id,
                role: user.user.roles,
                requested_id: data.requested_id,
                nextAction: onboardState.dr.state,
                remark: data.remarks,
                status: 1,
            }).then((res) => {
                setBackDropOpen(false)
                setPreview(true);
                setEditing(false);
                setRequisition({});
                setData(true);
                toast.success("Status Updated Successfully")
            }).catch((err) => {
                toast.error(err.data.message)
                setBackDropOpen(false)
            })
            setBackDropOpen(false)
            setPreview(true);
            setEditing(false);
            setRequisition({});
            setData(true);
            toast.success("Sent Successfully")
        }).catch((err) => {
            toast.error(err.data.message)
            setBackDropOpen(false)
        })
    }

    // update Doctor Details
    const updateDoctor = (id, doctor) => {
        setBackDropOpen(true)
        axios.put(`${APIURL()}admin/doctors/update-doctor/${id}`, {
            doctor: doctor,
            doctorId: id,
            userId: user.user.id,
            role: user.user.roles,
        }).then((res) => {
            setBackDropOpen(false)
            setPreview(true);
            setEditing(false);
            setRequisition({});
            toast.success(res.data.message)
        }).catch((err) => {
            toast.error(err.data.message)
            setBackDropOpen(false)
        })
    }

    // Download Excel 
    const downloadExcel = () => {
        var count = 1
        const newData = rows.map(row => {
            var status = 'Request Initiated'
            var newRow = {
                'SI. No': count,
                'Request Id': row.requestId,
                'Doctor Name': row.name,
                'Joining Date': row.doj && new Date(row.doj).toLocaleDateString('en-CA').split('T')[0],
                'Commencement Date': row.commencementdate && new Date(row.commencementdate).toLocaleDateString('en-CA').split('T')[0],
                'D365 Id': row.VendorAccountNumber,
                'Center': row.centerName,
                'Association Type': row.category,
                'Department': row.departmentLabel,
                'Designation': row.designationLabel,
                'Raised By': row.label,
                'Raised On': row.raisedOn,
                'Manual Agreement': row.manualAgreement == 1 ? "Yes" : "No",
                'Status': row.currentStatus,
            }
            delete row.tableData
            count++
            return newRow
        })
        console.log(newData, 'excelData')
        const workSheet = XLSX.utils.json_to_sheet(newData)
        const workBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workBook, workSheet, "requisitions")
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" })
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" })
        //Download
        XLSX.writeFile(workBook, "RequisitionList.xlsx")
    }
    const generateLtProfile = async(data) => {
        try {
            setGenerateLTButtonStatus(false);
            const response = await axios.post(APIURL() + "onboard/create-lt-profile", {
                doctorId: data.doctorId,
                requested_id: data.id,
                name: data.name,
                currentAction: "",
                currentActionBy: user.user.id,
                role: user.user.roles,
                nextAction: "",
                remark: "Generate LT Profile by Admin.",
                status: 1,
            });
            if(response.status == 200){
                setBackDropOpen(false)
                setPreview(true);
                setEditing(false);
                setRequisition({});
                setData(true);
                toast.success("Generated LT Profile.");
            }else{
                toast.error("Something went wrong.");
                setBackDropOpen(false);
            }
            setGenerateLTButtonStatus(true);
        } catch (error) {
            toast.error(error.data.message ?? "Something went wrong.");
            setBackDropOpen(false);
            setGenerateLTButtonStatus(true);
        }
    }
    const generateD365Vendor = async (data) => {
        try {
            setGenerateLTButtonStatus(false);
            const response = await axios.post(APIURL() + "onboard/create-d365-vendor", {
                doctorId: data.doctorId,
                requested_id: data.id,
                name: data.name,
                currentAction: "",
                currentActionBy: user.user.id,
                role: user.user.roles,
                nextAction: "",
                remark: "Generated D365 Vendor by Admin.",
                status: 1,
            });
            if(response.status == 200){
                setBackDropOpen(false)
                setPreview(true);
                setEditing(false);
                setRequisition({});
                setData(true);
                toast.success("Generated D365 Vendor.");
            }else{
                toast.error("Something went wrong.");
                setBackDropOpen(false);
            }
            setGenerateLTButtonStatus(true);
        } catch (error) { 
            toast.error(error.data.message ?? "Something went wrong.");
            setBackDropOpen(false);   
            setGenerateLTButtonStatus(true);
        }
    }
    return (
        <>
            <AppSidebar />
            <div className="main-content side-content pt-0 mg-b-30">
                <div className="container-fluid">
                    <div className="inner-body">
                        <Navmain />
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={8}
                                lg={8}
                                xl={8}
                                className={classes.marginforMaster}
                            >
                                <Typography variant="h6">Manage Requisition</Typography>
                                {preview ? (
                                    <Typography variant="body2">
                                        Masters / Manage Requisition / Requisition List
                                    </Typography>
                                ) : editing ? (
                                    <Typography variant="body2">
                                        Masters / Manage Requisition / Edit Requisition
                                    </Typography>
                                ) : (
                                    <Typography variant="body2">
                                        Masters / Manage Requisition / Add Requisition
                                    </Typography>
                                )}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={4}
                                lg={4}
                                xl={4}
                                className={classes.marginforMaster}
                            >
                                <div className={classes.iconButtonStyle}>
                                    <IconButton
                                        color="primary"
                                        onClick={() => {
                                            setPreview(true);
                                            setEditing(false);
                                            setRequisition({});
                                        }}
                                    >
                                        <ListIcon />
                                    </IconButton>
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                {preview ? (
                                    <ListRequisition
                                        rows={rows}
                                        filterCenters={filterCenters}
                                        filterDatas={filterDatas}
                                        editRow={editRow}
                                        viewRow={viewRow}
                                        deleteRow={deleteRow}
                                        downloadExcel={downloadExcel}
                                        options={{
                                            rowStyle: rowData => ({
                                               color: rowData.isDeleted === 1 ? '#AAA' : '#000'
                                          })
                                   }}
                                    />
                                ) : editing ? (
                                    <EditRequisition
                                        editing={editing}
                                        setEditing={setEditing}
                                        setPreview={setPreview}
                                        requisition={requisition}
                                        filterDatas={filterDatas}
                                        updateOnboardingStatus={updateOnboardingStatus}
                                        updateOnboardingCommencementDate={updateOnboardingCommencementDate}
                                        updateOnboardingDepDeg={updateOnboardingDepDeg}
                                        sendToDigio={sendToDigio}
                                        generateLtProfile={generateLtProfile}
                                        generateD365Vendor={generateD365Vendor}
                                        generateLTButtonStatus={generateLTButtonStatus}
                                        setGenerateLTButtonStatus={setGenerateLTButtonStatus}
                                    />
                                ) : ''}
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Backdrop
                    className={backClasses.backdrop}
                    open={backDropOpen}
                >
                    <img src={c9gif} alt="loading..." />
                </Backdrop>
                <Dialog
                    fullScreen
                    open={viewAgreement}
                    TransitionComponent={Transition}
                >
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="end"
                                style={{ marginLeft: "99%" }}
                                color="inherit"
                                onClick={closeAgreement}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <List>
                        {docData && docData.agreementTemplate ?
                            <Agreement
                                recData={docData}
                                catagory={categoty}
                            />
                            : ""}
                    </List>
                </Dialog>

                {/* Deleted MODAL */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => { setIsDeletedModalOpen(false); }}
        aria-labelledby="customized-dialog-title"
        open={isDeletedModalOpen}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => { setIsDeletedModalOpen(false); }}
          style={{ textAlign: "center" }}
        >
          {deletedRequestDetails?.isDeleted?'Restore':'Delete'} The Request
        </DialogTitle>
        <DialogContent dividers>
          <label>Remarks </label>
          <textarea
            placeholder="Remarks"
            value={deletedRequestRemarks}
            type="text"
            onChange={(e)=>{setDeletedRequestRemarks(e.target.value)}}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={()=>deleteAction()}
            color="secondary"
            variant="contained"
            size="small"
          >
            Yes, {deletedRequestDetails?.isDeleted?'Restore':'Delete'}
          </Button>
          <Button
            autoFocus
            onClick={() => { setIsDeletedModalOpen(false); }}
            color="primary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
            </div>
        </>
    )
}

export default ManageRequisitions