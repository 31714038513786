import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, Table, Toast } from "react-bootstrap";
import moment from "moment";
// import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Navbar from "../../Components/Navbar";
import AppSidebar from "../../Components/AppSidebar";
import Sidebar from "../../Components/Sidebar";
import DoctorSidebar from "../../Components/DoctorSidebar";
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { agreementState } from "../../CommomMethods/States";
import axios from "axios";
import { Paper } from "@material-ui/core";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { listusers } from "../../actions/userActions";
import { documentlist } from "../../actions/documentActions";
// import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@mui/material";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import Iframe from "react-iframe";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DateRangeIcon from "@material-ui/icons/DateRange";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

// import MuiDialogTitle from "@material-ui/core/DialogTitle";
// import MuiDialogContent from "@material-ui/core/DialogContent";
// import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import MaterialTable from "material-table";
// import { Paper } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { onboardState } from "../../CommomMethods/States";
import { toast } from "react-toastify";
// import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";

import { APIURL } from "../../CommomMethods/Fetch";
import FixedfullTime from "../Payout/NEW/FixedfullTime";
import FixedMG from "../Payout/NEW/fixedMG";
import Agreement from "../Payout/NEW/Agreement";
import VcChennai from "../Payout/NEW/VcChennai";
import VisitingCon from "../Payout/NEW/VisitingCon";
import ConfirmDialog from "../../Components/ConfirmDialog";
import { Backdrop } from "@material-ui/core";
import c9gif from '../../SampleFile/cloudnine.gif';
import { dateFormatter, numberWithCommas, numberToEnglish } from "../../actions/createPositionActions";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },

  buttonProgress: {
    // backgroundColor: "red",
    color: "white",
    position: "absolute",
    top: "50%",
    left: "20%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "fixed",
    backgroundColor: "#810e36",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  buttonProgress: {
    // backgroundColor: "red",
    color: "white",
    position: "absolute",
    top: "50%",
    left: "20%",
    marginTop: -12,
    marginLeft: -12,
  },
}));
const stylesmodal = makeStyles({
  root: {
    marginTop: "18px",
  },
});
const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
function ViewStatus(props) {
  const tables = myTableStyle();
  const backClasses = backDropStyle();
  const dispatch = useDispatch();
  const styleClasses = stylesmodal();
  const [show, setShow] = useState(false);
  const [remark, setRemark] = useState("");
  const [doctorid, setdoctorId] = useState(0);
  const [step, setStep] = useState(1);
  const [rows, setRows] = useState([]);
  const [datas, setDatas] = useState([]);
  const [centers, setCenters] = useState([]);
  const [id, setId] = useState("");
  const [rid, setrId] = useState("");
  const [orow, setOrow] = useState({});
  const [open, setOpen] = React.useState(false);
  const [openr, setOpenr] = React.useState(false);
  const [lists, setList] = useState([]);
  const [uploadData, setUploadData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [openRemark, setOpenRemark] = React.useState(false);
  const [viewRemark, setViewRemark] = useState("");
  const [urlData, setUrlData] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [viewAgreement, setViewAgreement] = useState(false);
  const [categoty, setCategory] = useState("");
  const [docData, setDocData] = useState([]);
  const [agreementrow, setAgreementRow] = useState({});
  const [openstamp, setopenstamp] = useState(false);
  const [stampdate, setStampdate] = React.useState(new Date());
  const [backDropOpen, setBackDropOpen] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subtitle: "",
  });

  const [openReject, setOpenRejectmodal] = React.useState(false);
  const [openApprove, setOpenApprovemodal] = React.useState(false);
  const [review, setReview] = useState("");
  const [approve, setapprove] = useState(null);
  const [rej, setRej] = useState([]);
  const [muiTableKey, setMuiTableKey] = React.useState(0);
  const [showOldCommercial, setShowOldCommercial] = useState(false);
  const [showCommercial, setShowCommercial] = useState(false);
  const [docid, setDocid] = useState("");
  const [doctorDetail, setDoctorDetail] = useState([]);
  const [doctorOpDetail, setDoctorOpDetail] = useState([]);
  const [doctorIpCommercial, setDoctorIpCommercial] = useState([]);
  const [doctorOpDetailEdit, setDoctorOpDetailEdit] = useState([]);
  const [doctorIpDetail, setDoctorIpDetail] = useState([]);
  const [doctorMgDetails, setDoctorMgDetails] = useState([]);
  const [doctorFixedDetails, setDoctorFixedDetails] = useState([]);
  const [TotalPayOutValue, setTotalPayOutValue] = useState([]);
  const [doctorOpCommercial, setDoctorOpCommercial] = useState([]);
  const [doctorOpCommercial_archived, setDoctorOpCommercial_archived] = useState([]);
  const [doctorIpCommercial_archived, setDoctorIpCommercial_archived] = useState([]);
  const [doctorIPParameter_archived, setDoctorIPParameter_archived] = useState([]);
  const [doctorOPParameter_archived, setDoctorOPParameter_archived] = useState([]);
  const [doctorMgDetails_archived, setDoctorMgDetails_archived] = useState([]);
  const [doctorFixedDetails_archived, setDoctorFixedDetails_archived] = useState([]);
  const [isJoiningDate, setIsJoiningDate] = useState(false);
  const [joiningdate, setJoiningdate] = React.useState(new Date());

  // var showdet ={};
  const classes = useStyles();

  const [docdetail, setdetails] = useState([])

  // const showdocumentdetail = (ele) => {debugger
  //   // if(ele.url==='api/onboard/'+ele.doctorId)

  //     console.log(showdet)
  //   }



  const handlemClickOpen = (id) => {
    dispatch(documentlist(id));
    setId(id);
    setOpen(true);

    axios.get(APIURL() + "onboard/" + id)
      .then((response) => {
        console.log(response, "response");
        setdetails(response.data.doctorCommercial)
      })
  };

  //console.log(JSON.stringify(res1.doctorCommercial),"ababab"));
  // axios.get(APIURL() +"onboard/"+ id)
  // .then((res1) => 
  // JSON.stringify(res1.doctorCommercial));

  // axios.get(APIURL() +"onboard/"+ id)
  // .then((res1) => {
  //   var docdetail=res1.data;
  //   //console.log(docdetail);
  //   docdetail.forEach((elem) => {
  //   console.log(JSON.stringify(elem),"detaiillss")});
  // });
  //};
  const handlemClose = () => {
    setOpen(false);
  };

  const handlerClickOpen = (row) => {
    // setrId(id);
    setOrow(row);
    setOpenr(true);
  };
  const handlerClickOpenDoc = (id) => {
    setrId(id);
    setOpenr(true);
    // const obj = {
    //   isVerified: 1,
    //   verifiedBy:users[0] && users[0].users_role_id  ,
    //   verificationRemark:remark
    // };
    // var k = window.confirm("Are you sure want to delete this record ?");
    // if (k) {
    //   axios.put(APIURL()+"documents/documents/" + id, obj).then(res => {
    //   })
    // }
  };
  const handlerClose = () => {
    setOpenr(false);
  };

  const handleSubmitReject = () => {
    const obj = {
      doctorId: orow.doctorId,
      name: orow.name,
      currentAction: onboardState.ch.state,
      currentActionBy: user.user.id,
      role: users[0] && users[0].users_role_id,
      requested_id: users[0] && users[0].users_role_id,
      nextAction: onboardState.hr.state,
      remark: remark,
      status: 1,
    };

    axios.post(APIURL() + "onboard/action/", obj).then((res) => {
      toast.success("Rectification successfully submitted");
    });
    window.location.reload();
  };
  const Remark = (e) => {
    setRemark(e.target.value);
  };
  const back = useRef(null);
  const next = useRef(null);
  const reviewAndSubmit = useRef(null);
  const handleClose = () => setShow(false);
  const handleShow = (doctorid) => {
    setShow(true);

    dispatch(documentlist(doctorid));
  };
  const listDoc = useSelector((state) => state.listDoc);
  const { list } = listDoc;

  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;

  const userList = useSelector((state) => state.userList);
  const { users } = userList;
  let userid = users[0] && users[0].id;

  // useEffect(() => {
  //   dispatch(documentlist());
  // }, [dispatch]);
  const viewDocFile = (url, filename) => {
    setOpenDialog(true);

    fetch(APIURL() + "documents/download/" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        setUrlData(url);
      });
    });
  };

  function clickNext() {
    if (step == 1) {
      back.current.className = "standardButton rejectButton";
    } else if (step == 3) {
      next.current.className += " hide";
      back.current.className += " hide";
      reviewAndSubmit.current.className = "standardButton acceptButton";
    }
    setStep((s) => s + 1);
  }

  function clickBack() {
    if (step == 2) {
      back.current.className += " hide";
    }
    setStep((s) => s - 1);
  }
  function clickReviewAndSubmit() { }

  const formStyle = {
    borderRadius: "5px",
    border: "1px solid black",
  };
  const headerStyle = {
    backgroundColor: "#94248E",
    margins: "0px",
    padding: "10px",
    color: "white",
    borderRadius: "5px",
    borderBottom: "3px black solid",
  };
  const bodyStyle = {
    borderRadius: "5px",
  };

  const handleDownloadFile = (url, filename) => {
    fetch(APIURL() + "documents/download/" + url, {
      method: "GET",
      headers: {
        "Content-Type": "application/pdf",
      },
    }).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
      //window.location.href = response.url;
    });
  };

  var centerRecord = [];
  useEffect(() => {
    if (userid && userid !== undefined) {
      axios.get(APIURL() + "usercentermap/" + userid).then((res) => {
        setCenters(res.data);
        res.data.centers.forEach((element) => {
          centerRecord.push(element);
        });
      });
      axios
        .get(APIURL() + "onboard/HrStatusById/" + userid)
        .then((res) => res.data)
        .then((rows) => {
          console.log(rows, userid, "chk point for ch");
          setDatas(rows.data);
          // setRows(rows.data.filter((z) => z.currentActionBy == userid));
        });
    }
  }, [userid]);


  console.log(list, docdetail, "da-taa");
  //alert(JSON.stringify(user));
  useEffect(() => {
    axios
      .get(APIURL() + "documents/list/" + user.user.id)
      .then((res) => res.data)
      .then((rows) => {
        setList(rows.data);
        var k = rows.data;
        var l = [];
        k.forEach((element) => {
          l.push(element.docTitle);
        });
        setUploadData(l);

        //  axios.get(APIURL() +"onboard/"+ user.user.id)
        // .then((res1) => console.log(JSON.stringify(res1.doctorCommercial),"ababab"));


      });
  }, [refresh]);
  const handleComments = (e) => {
    setReview(e.target.value);
  };
  const handleRejectDialog = (row) => {
    //setDocid(row.doctorId);
    setOpenRejectmodal(true);
    setRej(row);
  };
  const handleApproveDialog = (row) => {
    //setDocid(row.doctorId);
    if(row.OnextAction === onboardState.ch.state && (!joiningdate || !stampdate || !row.cdoj || !row.doj)){
      toast.warning("Commencement Date and Joining Date is required");
      return
    }else{
      setOpenApprovemodal(true);
      setapprove(row);
    }
  };

  const handleApprove = async (row) => {
    //commencementdate
    console.log(approve, "row0datta");
    setOpenApprovemodal(false);

    const updateData = {
      doctorId: approve.doctorId,
      name: approve.doctorName,
      currentAction: approve.OnextAction,
      currentActionBy: user.user.id,
      role: users[0] && users[0].users_role_id,
      requested_id: approve.id,
      nextAction: onboardState.dr_info.state,
      remark: review,
      status: 1,
    };
    if (user.user.activityAssigments.filter((elem) => elem.activityId === 16).find(item => item.RoleId === user.user.roles) && approve.OnextAction == onboardState.commercial_review.state) {
      updateData.nextAction = approve.manualAgreement === 1 ? onboardState.dr_info.state : onboardState.ch.state
    } else {
      if (approve.commencementdate == null || approve.commencementdate == "0000-00-00") {
        setBackDropOpen(false);
        setConfirmDialog({
          isOpen: true,
          title: "Alert",
          subtitle: "Please Add Commencement Date and Try Again",
        });
        return;
      }
    }
    setBackDropOpen(true);
    await axios.post(APIURL() + "onboard/action/", updateData).then(async (res) => {
      // await axios.post(APIURL() + "onboard/generate-agreement/", updateData).then((res) => {
      setBackDropOpen(false);
      window.location.reload();
      // })
    });
  };

  const handleReject = async (row) => {
    //commencementdate
    console.log(rej, "row0datta");
    setOpenRejectmodal(false);
    setBackDropOpen(true);
    const updateData = {
      doctorId: rej.doctorId,
      name: rej.doctorName,
      currentAction: onboardState.rd_revision.state,
      currentActionBy: user.user.id,
      role: users[0] && users[0].users_role_id,
      requested_id: rej.id,
      nextAction: onboardState.init.state,
      remark: review,
      status: 1,
    };
    await axios.post(APIURL() + "onboard/action/", updateData).then(async (res) => {
      setBackDropOpen(false);
      window.location.reload();
    });
  };

  function viewDocuments(id) {
    setdoctorId(id);
  }

  const handleApproveDoc = (row) => {
    const updateData = {
      isVerified: "APPROVED",
      verificationRemark: "2",
      verifiedBy: users[0] && users[0].users_role_id,
    };
    axios
      .put(APIURL() + "documents/documents/" + row.id, updateData)
      .then((res) => { });
    window.location.reload();
  };

  const handleRemarkOpen = (remark) => {
    setViewRemark(remark);
    setOpenRemark(true);
  };
  const handleRemarkOpenDoc = (remark) => {
    setViewRemark(remark);
    setOpenRemark(true);
  };
  const handleCloseRemark = () => {
    setOpenRemark(false);
  };
  useEffect(() => {
    dispatch(listusers());
  }, [dispatch]);

  const viewAgreementDoc = (row) => {
    console.log(row, "view-agreement-ch");
    setAgreementRow(row);
    axios
      .get(APIURL() + "onboard/agreementView/" + row.doctorId)
      .then((res) => {
        if (res.data != null) {
          console.log(res.data.data, "category-view");
          setCategory(res.data.data.vacancyData[0].category);
          setDocData(res.data.data);
        }
      });
    setViewAgreement(true);
  };

  const viewSignedAgreementDoc = async (row) => {
    console.log(row, "view-agreement-ch");
    // setAgreementRow(row);
    await axios.get(APIURL() + "onboard/download-agreement/" + row.digio_doc_id, {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
      },
    }).then((res) => {
      console.log(res)
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = row.doctorName + "_Agreement.pdf"
      link.href = url;
      // link.setAttribute('download', 'Doctor Agreement.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
  };

  const closeAgreement = () => {
    setViewAgreement(false);
    setAgreementRow({});
    setDocData([]);
    setCategory("");
  };

  const handleStamp = ({row, isJoining = false}) => {
    if (row && row.cdoj && row.cdoj !== "Invalid date") {
      setStampdate(row.cdoj)
    }
    if(row && row.doj && row.doj !== "Invalid date"){
      if(!row.onboarded_status){
        setStampdate(row.doj)
      }
      setJoiningdate(row.doj);
    }
    setIsJoiningDate(isJoining);
    setId(row.doctorId);
    setopenstamp(true);
    setDocData(row);
  };
  const handleStampClose = () => {
    setopenstamp(false);
    setStampdate(new Date())
    setJoiningdate(new Date())
    setDocData([]);
  };

  const handleChangeStamp = (a) => {
    if(isJoiningDate){
      setJoiningdate(a);
    }else{
      setStampdate(a);
    }
  };
  const handleStampSubmit = () => {
    // console.log(stampdate, id, "@@@@@@");
    if(!joiningdate){
      toast.warning("Joining Date is required");
      return
    }
    else if(!stampdate){
      toast.warning("Commencement Date is required");
      return
    }
    setBackDropOpen(true);
    let b_data = { req_id: docData.req_id, doc_name: docData.doctorName};
    if(isJoiningDate){
      b_data["doj"] = joiningdate;
      b_data["cdoj"] = joiningdate;
    }
    else{
      b_data["cdoj"] = stampdate;
    }
    axios
      .put(APIURL() + "ProfileDetails/updateCommencement/" + id, b_data)
      .then((res) => {
        setopenstamp(false);
        setBackDropOpen(false);
        toast.success(isJoiningDate ? "Date Of Joining Added." : res.data.message);
        window.location.reload();
      }).catch((e)=>{
        toast.error("Something went wrong.")
      })
  };

  const getDocdetail = async (id) => {
    axios
      .get(APIURL() + "onboard/previewOne/" + id)
      .then((res) => {
        setDoctorDetail(res.data);
        setDoctorIpDetail(res.data.doctorIPParameter);
        setDoctorOpDetail(res.data.doctorOPParameter);
        setDoctorOpDetailEdit(res.data.doctorOPParameter);
        setDoctorOpCommercial(res.data.doctorOpCommercial);
        setDoctorIpCommercial(res.data.doctorIpCommercial);
        setDoctorMgDetails(res.data.doctorMgDetails);
        setDoctorFixedDetails(res.data.doctorFixedDetails);
        setDoctorOpCommercial_archived(res.data.doctorOpCommercial_archived)
        setDoctorIpCommercial_archived(res.data.doctorIpCommercial_archived)
        setDoctorIPParameter_archived(res.data.doctorIPParameter_archived)
        setDoctorOPParameter_archived(res.data.doctorOPParameter_archived)
        setDoctorMgDetails_archived(res.data.doctorMgDetails_archived)
        setDoctorFixedDetails_archived(res.data.doctorFixedDetails_archived)
        let TotalRev = 0
        let TotalPayOutOP = 0
        let TotalPayOutIP = 0
        let TotalMGorFixedValue = 0
        let TotalPayout = 0
        if (res.data.doctorIPParameter && res.data.doctorIPParameter.length > 0) {
          res.data.doctorIPParameter.map((elem) => {
            TotalRev = TotalRev + parseFloat(elem.ipTotalAmount)
            TotalPayOutIP = TotalPayOutIP + parseFloat(elem.payout)
          })
        }
        if (res.data.doctorOPParameter && res.data.doctorOPParameter.length > 0) {
          res.data.doctorOPParameter.map((elem) => {
            TotalRev = TotalRev + parseFloat(elem.oprevenue)
            TotalPayOutOP = TotalPayOutOP + parseFloat(elem.oppayout)
          })
        }
        if (res.data.doctorMgDetails && res.data.doctorMgDetails.length > 0) {
          res.data.doctorMgDetails.map((elem) => {
            TotalMGorFixedValue = TotalMGorFixedValue + parseFloat(elem.minguranteepermonth)
          })
          if (TotalMGorFixedValue > (TotalPayOutIP + TotalPayOutOP)) {
            TotalPayout = TotalMGorFixedValue
          } else {
            TotalPayout = (TotalPayOutIP + TotalPayOutOP)
          }
        }
        if (res.data.doctorFixedDetails && res.data.doctorFixedDetails.length > 0) {
          res.data.doctorFixedDetails.map((elem) => {
            TotalMGorFixedValue = TotalMGorFixedValue + parseFloat(((elem.professionalfeepermonth).toString()).replaceAll(',', '')) + parseFloat(((elem.additionalfeevalue).toString()).replaceAll(',', ''))
          })
          TotalPayout = TotalMGorFixedValue + (TotalPayOutIP + TotalPayOutOP)
        }
        setTotalPayOutValue([{
          "TotalRevenue": TotalRev,
          "TotalIP": TotalPayOutIP,
          "TotalOP": TotalPayOutOP,
          "TotalMGorFixedValue": TotalMGorFixedValue,
          "Payout": TotalPayout
        }])
      })
      .catch((error) => { });
  }

  const handlerOpemCommercialSummery = (rowop) => {
    if (rowop.doctorId) {
      setDocid(rowop.doctorId);
      getDocdetail(rowop.doctorId);
    }

    setShowCommercial(true);
  };

  const handlerCloseCommercialSummery = () => {
    setShowOldCommercial(false);
    setShowCommercial(false);
  };

  const handlerViewOldCommercial = () => {
    let val = !showOldCommercial
    setShowOldCommercial(val)
  };

  const TableTitle = ({ text }) => (
    <Typography
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "black",
        textDecoration: "underline",
        fontWeight: "bold",
        fontSize: "15px"
      }}
    >
      {text}
    </Typography>
  );
  console.log(user.user, "viewsatus")
  return (
    <>
      {
        user.user.roles == 4 ? <DoctorSidebar />
          :
          user.user.roles !== 0 ? <Sidebar />
            : <AppSidebar />
      }

      <div className="main-content side-content pt-0 mg-b-30">
        <div className="container-fluid">
          <div className="inner-body">
            <Navbar />
            {user.user.activityAssigments.filter((elem) => elem.activityId === 7 || elem.activityId === 1 || elem.activityId === 2 || elem.activityId === 12 || elem.activityId === 13 || elem.activityId === 15 || elem.activityId === 16).find(item => item.RoleId === user.user.roles) ?
              <form>
                <br />
                <Card style={formStyle}>
                  <Card.Body style={bodyStyle}>
                    {/* <MaterialTable
                    columns={[
                      { title: "Doctor Name", field: "name" },
 
                      {
                        title: "Current Action",
                        field: "currentAction",
                        filtering: false,
                        render: (row) => (
                          <div style={{ width: "140px" }}>
                            {row.currentAction === onboardState.init.state ? (
                              <span style={{ color: "red" }}>
                                {" "}
                                {onboardState.init.message}
                              </span>
                            ) : row.currentAction ===
                              onboardState.upload.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.upload.message}
                              </span>
                            ) : row.currentAction === onboardState.hr.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.hr.currmessage}
                              </span>
                            ) : row.currentAction === onboardState.ch.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch.currmessage}
                              </span>
                            ) : row.currentAction === onboardState.rd.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.rd.currmessage}
                              </span>
                            ) : row.currentAction ===
                              onboardState.done.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.done.currmessage}
                              </span>
                            ) : row.currentAction ===
                              onboardState.ch_doc.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch_doc.currmessage}
                              </span>
                            ) : row.currentAction === onboardState.completed ? (
                              <span style={{ color: "blue" }}>"COMPLETED"</span>
                            ) : row.currentAction ===
                              onboardState.ch_renew.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch_renew.message}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ),
                      },
                      {
                        title: "Next Action",
                        field: "nextAction",
                        filtering: false,
                        render: (row) => (
                          <div style={{ width: "140px" }}>
                            {row.nextAction === onboardState.init.state ? (
                              <span style={{ color: "red" }}>
                                {" "}
                                {onboardState.init.message}
                              </span>
                            ) : row.nextAction === onboardState.upload.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.upload.message}
                              </span>
                            ) : row.nextAction === onboardState.hr.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.hr.message}
                              </span>
                            ) : row.nextAction === onboardState.ch.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch.message}
                              </span>
                            ) : row.nextAction === onboardState.rd.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.rd.message}
                              </span>
                            ) : row.nextAction === onboardState.done.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.done.message}
                              </span>
                            ) : row.nextAction === onboardState.ch_doc.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch_doc.message}
                              </span>
                            ) : row.nextAction === onboardState.completed ? (
                              <span style={{ color: "blue" }}>"COMPLETED"</span>
                            ) : row.nextAction ===
                              onboardState.ch_renew.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch_renew.message}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ),
                      },
                      {
                        title: "View Document",
                        field: "Action",
                        filtering: false,
                        render: (row) => (
                          <div style={{ width: "140px" }}>
                            {row.nextAction === onboardState.ch.state ? (
                              <InsertDriveFileIcon
                                style={{ color: "#810e36" }}
                                aria-label="close"
                                onClick={() => {
                                  handlemClickOpen(row.doctorId);
                                }}
                              >
                                <VisibilityIcon />
                              </InsertDriveFileIcon>
                            ) : (
                              <InsertDriveFileIcon
                                style={{ color: "#810e36" }}
                                aria-label="close"
                                onClick={() => {
                                  handlemClickOpen(row.doctorId);
                                }}
                              >
                                <VisibilityIcon />
                              </InsertDriveFileIcon>
                            )}
                          </div>
                        ),
                      },
                      
                      {
                        title: "Approve/Reject",
                        field: "Action",
                        filtering: false,
                        render: (row) =>
                          row.nextAction === onboardState.ch_doc.state ? (
                            <div style={{ whiteSpace: "nowrap" }}>
                              <Button
                                size="small"
                                style={{
                                  backgroundColor: "#810e36",
                                  color: "white",
                                }}
                                variant="contained"
                                onClick={() => {
                                  handleApprove(row);
                                }}
                              >
                                Approve
                              </Button>
                              <Button
                                style={{ marginLeft: "5px" }}
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  handlerClickOpen(row);
                                }}
                              >
                                Review
                              </Button>
                            </div>
                          ) : row.nextAction === onboardState.completed ? (
                            <span className="badge badge-success">
                              Approved
                            </span>
                          ) : (
                            <span
                              className="badge"
                              style={{
                                color: "white",
                                backgroundColor: "grey",
                              }}
                            >
                              No Action Required
                            </span>
                          ),
                      },
                    ]}
                    data={rows.data}
                    options={{
                      // filtering: true,
                      sorting: true,
                      exportButton: true,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: true,
                    }}
                    title="Details"
                  /> */}

                    <div>
                      <MaterialTable
                        columns={[
                          { title: "Request Id", field: "id", cellStyle: { textAlign: 'center' } },
                          {
                            title: "Request Type",
                            field: "requestType",
                            render: (row) => row.requestType,
                            cellStyle: { textAlign: 'center' }
                          },
                          { title: "Doctor Name", field: "doctorName", cellStyle: { textAlign: 'center' } },
                          { title: "Comments", field: "remark" },

                          {
                            title: "Current Action",
                            field: "OcurrentAction",
                            cellStyle: { textAlign: 'center' },
                            filtering: false,
                            render: (row) => (
                              <span style={{ color: row.currentStatus === 'PENDING' ? "red" : row.currentStatus === 'COMPLETED' || row.currentStatus === 'Onboarded' ? "green" : 'blue' }}>
                                {Object.values(onboardState).find((ele) => ele.state == row.OcurrentAction) ? Object.values(onboardState).find((ele) => ele.state == row.OcurrentAction).message : ""}
                              </span>
                            ),
                          },
                          {
                            title: "Next Action",
                            field: "nextAction",
                            cellStyle: { textAlign: 'center' },
                            filtering: false,
                            render: (row) => (
                              <span style={{ color: row.currentStatus === 'PENDING' ? "red" : row.currentStatus === 'COMPLETED' || row.currentStatus === 'Onboarded' ? "green" : 'blue' }}>
                                {Object.values(onboardState).find((ele) => ele.state == row.OnextAction) ? Object.values(onboardState).find((ele) => ele.state == row.OnextAction).message : ""}
                              </span>
                            ),
                          },
                          {
                            title: "View Documents",
                            field: "Action",
                            hidden: user.user.roles === 7 ? true : false,
                            cellStyle: { textAlign: 'center' },
                            filtering: false,
                            render: (row) => (
                              row.OnextAction === onboardState.ch.state ? (
                                <InsertDriveFileIcon
                                  style={{ color: "#810e36", cursor: "pointer" }}
                                  aria-label="close"
                                  onClick={() => {
                                    handlemClickOpen(row.doctorId);
                                  }}
                                >
                                  <VisibilityIcon />
                                </InsertDriveFileIcon>
                              ) : (
                                <InsertDriveFileIcon
                                  style={{ color: "#810e36", cursor: "pointer"  }}
                                  aria-label="close"
                                  onClick={() => {
                                    handlemClickOpen(row.doctorId);
                                  }}
                                >
                                  <VisibilityIcon />
                                </InsertDriveFileIcon>
                              )
                            ),
                          },
                          {
                            title: "Estimation Details",
                            field: "Action",
                            filtering: false,
                            cellStyle: { textAlign: 'center' },
                            render: (row) => (
                              <i
                                className="fas fa-address-card"
                                style={{ fontSize: "20px", color: "#810e36", cursor: "pointer" }}
                                onClick={() => handlerOpemCommercialSummery(row)}
                              />
                            ),
                          },
                          {
                            title: "View Agreement",
                            field: "Action",
                            filtering: false,
                            cellStyle: { textAlign: 'center' },
                            render: (rows) => (
                              rows.OnextAction === onboardState.completed ?
                                <IconButton
                                  style={{
                                    color: "#810e36",
                                    cursor: "pointer",
                                  }}
                                  aria-label="close"
                                  onClick={() => {
                                    viewAgreementDoc(rows);
                                  }}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                                :
                                <IconButton
                                  style={{
                                    color: "#810e36",
                                    cursor: "pointer",
                                  }}
                                  aria-label="close"
                                  onClick={() => {
                                    viewAgreementDoc(rows);
                                  }}
                                >
                                  <VisibilityIcon />
                                </IconButton>

                            ),
                          },
                          {
                            title: "Commencement Date",
                            field: "commencementdate",
                            hidden: user.user.roles === 7 ? true : false,
                            cellStyle: { textAlign: 'center' },
                            // filtering: false,
                            render: (row) =>
                              row && row.cdoj && row.cdoj != "Invalid date" ? (
                                <>
                                  {moment(row.cdoj.substr(0, 10)).format(
                                    "DD-MM-YYYY"
                                  )}
                                  {row.OnextAction === onboardState.ch.state ?
                                    <EditCalendarIcon
                                      style={{
                                        color: "#810e36",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        if(row.onboarded_status){
                                          handleStamp({row: row, isJoining: false});
                                        }
                                      }}
                                    ></EditCalendarIcon>
                                    : ''}
                                </>
                              ) : 
                         "---"
                          },
                          {
                            title: "Date Of Joining",
                            field: "doj",
                            hidden: user.user.roles === 7 ? true : false,
                            cellStyle: { textAlign: 'center' },
                            render: (row) =>
                              row && row.doj && row.doj != "Invalid date" ? (
                                <>
                                  {moment(row.doj.substr(0, 10)).format(
                                    "DD-MM-YYYY"
                                  )}
                                  {row.OnextAction === onboardState.ch.state && !row.onboarded_status?
                                    <EditCalendarIcon
                                      style={{
                                        color: "#810e36",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        handleStamp({row: row, isJoining: true});
                                      }}
                                    ></EditCalendarIcon>
                                    : ''}
                                </>
                              ) : row.OnextAction === onboardState.ch.state?(
                                <DateRangeIcon
                                      style={{
                                        color: "#810e36",
                                        cursor: "pointer",
                                      }}
                                      disabled={row.OnextAction !== onboardState.ch.state}
                                      onClick={() => {
                                        handleStamp({row: row, isJoining: true});
                                      }}
                                    ></DateRangeIcon>
                              ):"-",
                          },
                          // {
                          //   title: "Approve/Reject",
                          //   field: "Action",
                          //   filtering: false,
                          //   render: (row) =>
                          //     row.nextAction === onboardState.ch_doc.state &&
                          //     row.commencementdate ? (
                          //       <div style={{ whiteSpace: "nowrap" }}>
                          //         <Button
                          //           size="small"
                          //           style={{
                          //             backgroundColor: "#810e36",
                          //             color: "white",
                          //           }}
                          //           variant="contained"
                          //           onClick={() => {
                          //             handleApprove(row);
                          //           }}
                          //         >
                          //           Approve
                          //         </Button>
                          //         <Button
                          //           style={{ marginLeft: "5px" }}
                          //           size="small"
                          //           color="primary"
                          //           variant="contained"
                          //           onClick={() => {
                          //             handlerClickOpen(row);
                          //           }}
                          //         >
                          //           Review
                          //         </Button>
                          //       </div>
                          //     ) : row.nextAction === onboardState.completed ? (
                          //       <span className="badge badge-success">
                          //         Approved
                          //       </span>
                          //     ) : (
                          //       <span
                          //         className="badge"
                          //         style={{
                          //           color: "white",
                          //           backgroundColor: "grey",
                          //         }}
                          //       >
                          //         No Action Required
                          //       </span>
                          //     ),
                          // },
                          {
                            title: "Approve/Reject",
                            field: "Action",
                            filtering: false,
                            cellStyle: { textAlign: 'center' },
                            render: (row) =>
                              row.OnextAction === onboardState.ch.state && user.user.roles == 2 ? (
                                <div style={{ whiteSpace: "nowrap" }}>
                                  <Button
                                    size="small"
                                    style={{
                                      backgroundColor: "#810e36",
                                      color: "white",
                                    }}
                                    variant="contained"
                                    onClick={() => {
                                      handleApproveDialog(row);
                                    }}
                                  >
                                    Approve
                                  </Button>
                                  <Button
                                    style={{ marginLeft: "5px" }}
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                      handleRejectDialog(row);
                                    }}
                                  >
                                    Revise
                                  </Button>
                                </div>
                              )
                                : user.user.activityAssigments.filter((elem) => elem.activityId === 16).find(item => item.RoleId === user.user.roles) && row.OnextAction == onboardState.commercial_review.state && user.user.roles == 7 ? (
                                  <div style={{ whiteSpace: "nowrap" }}>
                                    <Button
                                      size="small"
                                      style={{
                                        backgroundColor: "#810e36",
                                        color: "white",
                                      }}
                                      variant="contained"
                                      onClick={() => {
                                        handleApproveDialog(row);
                                      }}
                                    >
                                      Approve
                                    </Button>
                                    <Button
                                      style={{ marginLeft: "5px" }}
                                      size="small"
                                      color="primary"
                                      variant="contained"
                                      onClick={() => {
                                        handleRejectDialog(row);
                                      }}
                                    >
                                      Revise
                                    </Button>
                                  </div>
                                )
                                  : row.OcurrentAction == onboardState.ch_doc_rej.state && row.OnextAction === onboardState.hr.state ? (
                                    <span className="badge badge-danger">Sent for revision</span>
                                  )
                                    : row.OnextAction === onboardState.completed ? (
                                      <span className="badge badge-success">
                                        Approved
                                      </span>
                                    ) : row.OnextAction === onboardState.hr.state ? (
                                      <span className="badge" style={{ color: "white", backgroundColor: "grey", }}>
                                        Awaiting for HR verification
                                      </span>
                                    ) : (
                                      <span
                                        className="badge"
                                        style={{
                                          color: "white",
                                          backgroundColor: "grey",
                                        }}
                                      >
                                        No Action Required
                                      </span>
                                    ),
                          },
                        ]}
                        data={datas.filter(elem => elem.currentStatus !== 'PENDING')}
                        options={{
                          filtering: true,
                          sorting: true,
                          exportButton: true,
                          pageSize: 5,
                          pageSizeOptions: [5, 10, 50, 100, 200, 500],
                          search: true,
                          searchText: props && props.location && props.location.state && props.location.state.data && props.location.state.data.doctorName ? props.location.state.data.doctorName : ''
                        }}
                        actions={[
                          {
                            icon: () => <ClearAllIcon />,
                            tooltip: "Clear All Filters",
                            isFreeAction: true,
                            onClick: (event) => {
                              setMuiTableKey(muiTableKey + 1); // set new key causing remount
                            }
                          }
                        ]}
                        icons={{
                          Filter: "filter",
                        }}
                        title="Details"
                      />
                    </div>
                  </Card.Body>
                </Card>
              </form>
              :
              <div>
                <h2 style={{ marginTop: "200px", display: 'flex', justifyContent: 'center', color: "#d11d53" }}>You Are Not Authorized To See This Page</h2>
              </div>
            }

          </div>
        </div>
        <Modal
          size="lg"
          scrollable
          dialogClassName="modalStandard"
          centered
          aria-labelledby="example-modal-sizes-title-lg"
          show={show}
          onHide={handleClose}
        >
          <Modal.Header className="modalHeader" closeButton>
            <Modal.Title className="modalTitle">Requested Id</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* {addpos.data && addpos.data.map((ele,index) =>( */}
            <Table style={{ width: "100%" }} borderless>
              <thead>
                <tr
                  style={{
                    textAlign: "center",
                    borderBottom: "1px solid rgb(200, 200, 200)",
                  }}
                >
                  <th className="col-2">Document Name</th>

                  <th> Documents</th>
                </tr>
              </thead>
              <tbody>
                {list.data &&
                  list.data.map((ele) => (
                    <tr
                      onClick={() => handleShow(ele.id)}
                      id="color"
                      style={{
                        textAlign: "center",
                        borderBottom: "1px solid rgb(200, 200, 200)",
                      }}
                    >
                      {/* <td>{ele.id}.</td> */}
                      <td>{ele.docTitle}</td>
                      <td>
                        <i
                          className="fas fa-file"
                          onClick={() =>
                            handleDownloadFile(ele.url, ele.docTitle)
                          }
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center"></Modal.Footer>
        </Modal>
      </div>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlemClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlemClose}
          style={{ textAlign: "center" }}
        >
          Uploaded Documents
        </DialogTitle>
        <DialogContent dividers>
          <Card style={formStyle}>
            <Card.Header style={headerStyle}></Card.Header>
            <Card.Body
              style={bodyStyle}
            // style={{ overflowY: "auto", height: "300px" }}
            >
              <Table style={{ width: "100%" }} borderless>
                <thead>
                  <tr
                    style={{
                      textAlign: "center",
                      backgroundColor: "hsla(340, 97%, 26%, 0.55)",
                      color: "white",
                      borderBottom: "1px solid rgb(200, 200, 200)",
                    }}
                  >
                    <th className="col-2">Document Title</th>
                    <th className="col-2"> Download Document</th>
                    <th className="col-2"> View Document</th>
                    <th className="col-2">Document Details</th>
                    {/* <th className="col-2"> Actions </th> */}
                  </tr>
                  {/* )} */}
                </thead>
                <tbody>
                  {list &&
                    list.data &&
                    list.data.map((ele) => (
                      <tr>
                        <td style={{ textAlign: "center" }}>{ele.docTitle}</td>
                        <td style={{ cursor: "pointer", textAlign: "center" }}>
                          {" "}
                          <i
                            className="fas fa-download"
                            onClick={() =>
                              handleDownloadFile(ele.url, ele.docTitle)
                            }
                            title={"Download"}
                            style={{ color: "#810e36", fontSize: "22px" }}
                          />
                        </td>
                        <td style={{ cursor: "pointer", textAlign: "center" }}>
                          {" "}
                          <InsertDriveFileIcon
                            onClick={() => viewDocFile(ele.url, ele.docTitle)}
                            title={"View"}
                            style={{ color: "#810e36", fontSize: "22px" }}
                          />
                        </td>


                        <td style={{ textAlign: "center" }}>
                          <p style={{ color: "#810e36", fontSize: "10px" }} >{
                            ele.docTitle == 'Medical Council Registration Certificate No' ?
                              <>
                                <p style={{ color: "#810e36", fontSize: "10px" }}>{docdetail && docdetail.length > 0 && docdetail[0].mcrcval ? docdetail[0].mcrcval : '---'}</p>
                                <p style={{ color: "#810e36", fontSize: "10px" }}>{docdetail && docdetail.length > 0 && docdetail[0].mcrcval && ele.certExpiryDate ? "Expiry: " + ele.certExpiryDate : ''}</p>
                              </>
                              : ele.docTitle == 'PAN Card' ?
                                docdetail && docdetail.length > 0 && docdetail[0].panno ? docdetail[0].panno : '---'
                                : ele.docTitle == 'Aadhaar Card' ?
                                  docdetail && docdetail.length > 0 && docdetail[0].adhaarno ? docdetail[0].adhaarno : '---'
                                  : ele.docTitle == 'Indemnity Insurance Copy' ?
                                    <p style={{ color: "#810e36", fontSize: "10px" }}>{docdetail && docdetail.length > 0 && docdetail[0].indemnityexpiry ? "Expiry: " + dateFormatter(docdetail[0].indemnityexpiry) : ''}</p>
                                    : ele.docTitle == 'Cancelled Cheque (Bank Details)' ?
                                      <>
                                        <p style={{ color: "#810e36", fontSize: "10px" }}>{docdetail && docdetail.length > 0 && docdetail[0].bankname ? docdetail[0].bankname : ''}</p>
                                        <p style={{ color: "#810e36", fontSize: "10px" }}>{docdetail && docdetail.length > 0 && docdetail[0].bankaccno ? "A/C: " + docdetail[0].bankaccno : ''}</p>
                                      </>
                                      : ele.docTitle == 'Address Proof' ? docdetail && docdetail.length > 0 && docdetail[0].State && docdetail[0].PinCode && docdetail[0].City && docdetail[0].Address ? docdetail[0].City + ', ' + docdetail[0].State : '---'
                                        : '---'
                          }</p>
                        </td>



                        {/* <td style={{ cursor: "pointer", textAlign: "center" }}>
                          {ele.isVerified === "APPROVED" ? (
                            "APPROVED"
                          ) : ele.isVerified === "REJECTED" ? (
                            "REJECTED"
                          ) : (
                            <div style={{ whiteSpace: "nowrap" }}>
                              <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  handleApproveDoc(ele);
                                }}
                              >
                                Approve
                              </Button>

                              <Button
                                style={{ marginLeft: "5px" }}
                                size="small"
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                  handlerClickOpenDoc(ele.id);
                                }}
                              >
                                Reject
                              </Button>
                            </div>
                          )}
                        </td> */}
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handlemClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handlerClose}
        aria-labelledby="customized-dialog-title"
        open={openr}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Review
        </DialogTitle>
        <DialogContent dividers>
          <label>
            <b>Remark</b>
          </label>
          <textarea
            type="text"
            onChange={Remark}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleSubmitReject}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={handlerClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleCloseRemark}
        aria-labelledby="customized-dialog-title"
        open={openRemark}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerClose}
          style={{ textAlign: "center" }}
        >
          Remark
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{viewRemark}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleCloseRemark}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenDialog(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        disableBackdropClick
      >
        <DialogTitle
          id="customized-dialog-title"
          // onClose={handlerClickcloseCommercials}
          style={{ textAlign: "center" }}
        ></DialogTitle>
        <DialogContent dividers></DialogContent>
        <Iframe
          url={urlData}
          width="900px"
          height="800px"
          id="myId"
          className="myClassname"
          display="initial"
          position="relative"
        />

        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen
        scroll="body"
        open={viewAgreement}
        onClose={handleClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        TransitionComponent={Transition}
      >
        <DialogContent>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="end"
                  style={{ marginLeft: "99%" }}
                  color="inherit"
                  onClick={closeAgreement}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <List>
              {docData && docData.agreementTemplate ?
                <Agreement
                  history={agreementrow}
                  recData={docData}
                  catagory={categoty}
                />
                :
                categoty === "Fixed Consultant" || categoty === 'Retainer' ? (
                  <FixedfullTime
                    history={agreementrow}
                    recData={docData}
                    catagory={categoty}
                  />
                ) : categoty === "Minimum Guarentee(MG)" ? (
                  <FixedMG
                    history={agreementrow}
                    recData={docData}
                    catagory={categoty}
                  />
                ) : categoty === "Visiting Consultant" ? (
                  <VisitingCon
                    history={agreementrow}
                    recData={docData}
                    catagory={categoty}
                  />
                ) : categoty === "Visiting Consultant Chennai" ? (
                  <VcChennai
                    history={agreementrow}
                    recData={docData}
                    catagory={categoty}
                  />
                ) : (
                  "hi"
                )}
            </List>
          </DialogContentText>
        </DialogContent>
      </Dialog >

      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleStampClose}
        aria-labelledby="customized-dialog-title"
        open={openstamp}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleStampClose}
          style={{ textAlign: "center" }}
        >
          {isJoiningDate ? "Joining Date" : "Commencement Date"}
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4} lg={4} sm={12} xl={4}>
              <label>
                <b>Enter {isJoiningDate ? "Joining" : "Commencement"} Date:</b>
              </label>
              {/* </Grid>
          <Grid item xs={12} md={6} lg={6} sm={12} xl={6}> */}
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  disableToolbar
                  variant="inline"
                  format="dd/MM/yyyy"
                  autoOk
                  label={isJoiningDate ? "Joining Date" : "Commencement Date"}
                  // disablePast
                  id="stampdate"
                  value={isJoiningDate ? joiningdate : stampdate}
                  onChange={handleChangeStamp}
                  className={styleClasses.root}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  fullWidth
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleStampSubmit}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
          <Button
            autoFocus
            onClick={handleStampClose}
            color="secondary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* APPROVE MODAL */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenApprovemodal(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openApprove}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenApprovemodal(false);
          }}
          style={{ textAlign: "center" }}
        >
          Approve
        </DialogTitle>
        <DialogContent dividers>
          <label>Comments </label>
          <textarea
            placeholder="Comments"
            type="text"
            onChange={handleComments}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
            required
          />
        </DialogContent>
        <DialogActions>
          {approve && approve.OnextAction === onboardState.ch.state &&
          <p>*The commencement Date of the Doctor is :  <b>{approve?.cdoj && approve?.cdoj != "Invalid date" ? moment(approve.cdoj.substr(0, 10)).format("DD-MM-YYYY"): 'Not Selected Yet!'}</b></p>}
          <Button
            autoFocus
            onClick={handleApprove}
            color="secondary"
            variant="contained"
            size="small"
          >
            Approve
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setOpenApprovemodal(false);
            }}
            color="primary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* REJECT MODAL */}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={() => {
          setOpenRejectmodal(false);
        }}
        aria-labelledby="customized-dialog-title"
        open={openReject}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => {
            setOpenRejectmodal(false);
          }}
          style={{ textAlign: "center" }}
        >
          Review
        </DialogTitle>
        <DialogContent dividers>
          <label>Comments </label>
          <textarea
            placeholder="Comments"
            type="text"
            onChange={handleComments}
            style={{ color: "black", border: "1px solid black" }}
            className="form-control"
            required
          />
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleReject}
            color="secondary"
            variant="contained"
            size="small"
          >
            Review
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setOpenRejectmodal(false);
            }}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        onClose={handlerCloseCommercialSummery}
        aria-labelledby="customized-dialog-title"
        open={showCommercial}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handlerCloseCommercialSummery}
          style={{ textAlign: "center" }}
        >
          <h5
            style={{
              margin: ".4rem",
              textAlign: "center",
              padding: "7px",
              backgroundColor: "#810e36",
              color: "white",
            }}
          >
            <b>Commercials Summary</b>
          </h5>
        </DialogTitle>
        <DialogContent dividers>
          {showOldCommercial ?
            <>
              <Paper className={tables.root} style={{ border: "1px solid" }}>
                <h5 className="p-2" style={{ fontWeight: "bold", borderBottom: "1px solid" }}>Commercials Summary Old</h5>

                <MaterialTable
                  columns={[
                    { title: "Center Name", field: "center", render: (rowop) => (<div>{rowop.center ? rowop.center : "---"}</div>) },
                    { title: "Item Group", field: "itemgroup", render: (rowop) => (<div>{rowop.itemgroup ? rowop.itemgroup : "---"}</div>) },
                    { title: "Item Name", field: "itemName", render: (rowop) => (<div>{rowop.itemName ? rowop.itemName : "---"}</div>) },
                    { title: "Commercial Term", field: "commercialterm", render: (rowop) => (<div>{rowop.commercialterm ? rowop.commercialterm : "---"}</div>) },
                  ]}
                  data={doctorOpCommercial_archived}
                  options={{
                    // filtering: true,
                    sorting: true,
                    paging: false,
                    search: false,
                    rowopStyle: {
                      fontSize: 12,
                    },
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                      fontSize: 12,
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px"
                    },
                  }}
                  title={<TableTitle text="Out-Patient Commercial" />}
                />
                <MaterialTable
                  columns={[
                    { title: "Center Name", field: "Center", render: (rowop) => (<div>{rowop.Center ? rowop.Center : "---"}</div>) },
                    { title: "Group (LOS)", field: "group", render: (rowop) => (<div>{rowop.group ? rowop.group : "---"}</div>) },
                    { title: "Item Group", field: "itemGroup", render: (rowop) => (<div>{rowop.itemGroup ? rowop.itemGroup : "---"}</div>) },
                    { title: "Item Name", field: "itemName", render: (rowop) => (<div>{rowop.itemName ? rowop.itemName : "---"}</div>) },
                    { title: "Room Category", field: "roomCategory", render: (rowop) => (<div>{rowop.roomCategory ? rowop.roomCategory : "---"}</div>) },
                    { title: "MOP", field: "ipmodeofpayment", render: (rowop) => (<div>{rowop.ipmodeofpayment ? rowop.ipmodeofpayment : "---"}</div>) },
                    { title: "Commercial Term", field: "commercialTerm", render: (rowop) => (<div>{rowop.commercialTerm ? rowop.commercialTerm : "---"}</div>) },
                  ]}
                  // data={doctorDetail.doctorIPParameter}
                  data={doctorIPParameter_archived}
                  options={{
                    // filtering: true,
                    sorting: true,
                    paging: false,
                    search: false,
                    rowopStyle: {
                      fontSize: 12,
                    },
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                      fontSize: 12,
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px"
                    },
                  }}
                  title={<TableTitle text="In-Patient Commercials" />}
                />
                {doctorMgDetails_archived && doctorMgDetails_archived.length > 0 ?
                  doctorMgDetails_archived.map((item) =>
                    <MaterialTable
                      columns={[
                        { title: "Minimum Guarantee Payment: The Company agrees to pay the Consultant an amount of Rs. " + numberWithCommas(item.minguranteepermonth) + " (Rs. " + numberToEnglish((item.minguranteepermonth).replaceAll(',', '')) + ") per month (“Minimum Guarantee Amount”) or the “Calculated Earnings” whichever is higher subject to statutory deductions. ", field: "mgCommercial" },
                      ]}
                      // data={doctorDetail.doctorIPParameter}
                      data={
                        [
                          {
                            mgCommercial: 'The Minimum Guarantee Amount will be paid for ' + item.mgperiod + ' months'
                          },
                          {
                            mgCommercial: 'The earnings shall be calculated on ' + item.settlementperiod + ' basis and difference amount (Calculated Earnings minus Minimum Guarantee), if any, shall be paid at the end of ' + item.settlementpaymentperiod + '.'
                          },
                        ]
                      }
                      options={{
                        // filtering: true,
                        sorting: true,
                        paging: false,
                        search: false,
                        headerStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px",
                          fontSize: 12,
                          fontWeight: 400
                        },
                        rowopStyle: {
                          fontSize: 12,
                        },
                        cellStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px"
                        },
                      }}
                      title={<TableTitle text={"Minimum Guarentee (MG) Commercial for " + item.Center} />}
                    />
                  )
                  : ''}
                {doctorFixedDetails_archived && doctorFixedDetails_archived.length > 0 ?
                  doctorFixedDetails_archived.map((item) =>
                    <MaterialTable
                      columns={[
                        { title: "Monthly Fixed Fee: The Company will pay an all-inclusive amount of Rs. " + numberWithCommas((item.professionalfeepermonth).replaceAll(',', '')) + " (Rs. " + numberToEnglish((item.professionalfeepermonth).replaceAll(',', '')) + ") per month. ", field: "fixedCommercial", width: "100%" },
                      ]}
                      // data={doctorDetail.doctorIPParameter}
                      data={
                        [
                          {
                            fixedCommercial: item.additionalfeevalue != '0' ? "Additional Fee for " + item.additionalfeename + ": The Company will pay an additional amount of Rs. " + numberWithCommas(((item.additionalfeevalue).toString()).replaceAll(',', '')) + " (Rs. " + numberToEnglish(((item.additionalfeevalue).toString()).replaceAll(',', '')) + ") per month. " : ""
                          },
                        ]
                      }
                      options={{
                        // filtering: true,
                        sorting: false,
                        paging: false,
                        search: false,
                        headerStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px",
                          fontSize: 12,
                          fontWeight: 400
                        },
                        rowopStyle: {
                          fontSize: 12,
                        },
                        cellStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px"
                        },
                      }}
                      title={<TableTitle text={"Full Time Consultant Commercial for " + item.Center} />}
                    />
                  )
                  : ''}
              </Paper>
              <br />
              <br />
            </>
            :
            <>
              <Paper className={tables.root} style={{ border: "1px solid" }}>
                <h5 className="p-2" style={{ fontWeight: "bold", borderBottom: "1px solid" }}>Commercials Summary</h5>

                <MaterialTable
                  columns={[
                    { title: "Center Name", field: "center", render: (rowop) => (<div>{rowop.center ? rowop.center : "---"}</div>) },
                    { title: "Item Group", field: "itemgroup", render: (rowop) => (<div>{rowop.itemgroup ? rowop.itemgroup : "---"}</div>) },
                    { title: "Item Name", field: "itemName", render: (rowop) => (<div>{rowop.itemName ? rowop.itemName : "---"}</div>) },
                    { title: "Commercial Term", field: "commercialterm", render: (rowop) => (<div>{rowop.commercialterm ? rowop.commercialterm : "---"}</div>) },
                  ]}
                  data={doctorOpCommercial}
                  options={{
                    // filtering: true,
                    sorting: true,
                    paging: false,
                    search: false,
                    rowopStyle: {
                      fontSize: 12,
                    },
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                      fontSize: 12,
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px"
                    },
                  }}
                  title={<TableTitle text="Out-Patient Commercial" />}
                />
                <MaterialTable
                  columns={[
                    { title: "Center Name", field: "Center", render: (rowop) => (<div>{rowop.Center ? rowop.Center : "---"}</div>) },
                    { title: "Group (LOS)", field: "group", render: (rowop) => (<div>{rowop.group ? rowop.group : "---"}</div>) },
                    { title: "Item Group", field: "itemGroup", render: (rowop) => (<div>{rowop.itemGroup ? rowop.itemGroup : "---"}</div>) },
                    { title: "Item Name", field: "itemName", render: (rowop) => (<div>{rowop.itemName ? rowop.itemName : "---"}</div>) },
                    { title: "Room Category", field: "roomCategory", render: (rowop) => (<div>{rowop.roomCategory ? rowop.roomCategory : "---"}</div>) },
                    { title: "MOP", field: "ipmodeofpayment", render: (rowop) => (<div>{rowop.ipmodeofpayment ? rowop.ipmodeofpayment : "---"}</div>) },
                    { title: "Commercial Term", field: "commercialTerm", render: (rowop) => (<div>{rowop.commercialTerm ? rowop.commercialTerm : "---"}</div>) },
                  ]}
                  // data={doctorDetail.doctorIPParameter}
                  data={doctorIpDetail}
                  options={{
                    // filtering: true,
                    sorting: true,
                    paging: false,
                    search: false,
                    rowopStyle: {
                      fontSize: 12,
                    },
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                      fontSize: 12,
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px"
                    },
                  }}
                  title={<TableTitle text="In-Patient Commercials" />}
                />
                {doctorMgDetails && doctorMgDetails.length > 0 ?
                  doctorMgDetails.map((item) =>
                    <MaterialTable
                      columns={[
                        { title: "Minimum Guarantee Payment: The Company agrees to pay the Consultant an amount of Rs. " + numberWithCommas(item.minguranteepermonth) + " (Rs. " + numberToEnglish((item.minguranteepermonth).replaceAll(',', '')) + ") per month (“Minimum Guarantee Amount”) or the “Calculated Earnings” whichever is higher subject to statutory deductions. ", field: "mgCommercial" },
                      ]}
                      // data={doctorDetail.doctorIPParameter}
                      data={
                        [
                          {
                            mgCommercial: 'The Minimum Guarantee Amount will be paid for ' + item.mgperiod + ' months'
                          },
                          {
                            mgCommercial: 'The earnings shall be calculated on ' + item.settlementperiod + ' basis and difference amount (Calculated Earnings minus Minimum Guarantee), if any, shall be paid at the end of ' + item.settlementpaymentperiod + '.'
                          },
                        ]
                      }
                      options={{
                        // filtering: true,
                        sorting: true,
                        paging: false,
                        search: false,
                        headerStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px",
                          fontSize: 12,
                          fontWeight: 400
                        },
                        rowopStyle: {
                          fontSize: 12,
                        },
                        cellStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px"
                        },
                      }}
                      title={<TableTitle text={"Minimum Guarentee (MG) Commercial for " + item.Center} />}
                    />
                  )
                  : ''}
                {doctorFixedDetails && doctorFixedDetails.length > 0 ?
                  doctorFixedDetails.map((item) =>
                    <MaterialTable
                      columns={[
                        { title: "Monthly Fixed Fee: The Company will pay an all-inclusive amount of Rs. " + numberWithCommas((item.professionalfeepermonth).replaceAll(',', '')) + " (Rs. " + numberToEnglish((item.professionalfeepermonth).replaceAll(',', '')) + ") per month. ", field: "fixedCommercial", width: "100%" },
                      ]}
                      // data={doctorDetail.doctorIPParameter}
                      data={
                        [
                          {
                            fixedCommercial: item.additionalfeevalue != '0' ? "Additional Fee for " + item.additionalfeename + ": The Company will pay an additional amount of Rs. " + numberWithCommas(((item.additionalfeevalue).toString()).replaceAll(',', '')) + " (Rs. " + numberToEnglish(((item.additionalfeevalue).toString()).replaceAll(',', '')) + ") per month. " : ""
                          },
                        ]
                      }
                      options={{
                        // filtering: true,
                        sorting: false,
                        paging: false,
                        search: false,
                        headerStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px",
                          fontSize: 12,
                          fontWeight: 400
                        },
                        rowopStyle: {
                          fontSize: 12,
                        },
                        cellStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px"
                        },
                      }}
                      title={<TableTitle text={"Full Time Consultant Commercial for " + item.Center} />}
                    />
                  )
                  : ''}
              </Paper>
              <br />
              <br />
            </>
          }
        </DialogContent>
        <DialogActions>
          {/* <Button
            autoFocus
            onClick={updateRecord}
            color="primary"
            variant="contained"
            size="small"
          >
            Update Data
          </Button> */}
          {doctorOpCommercial_archived && doctorOpCommercial_archived.length > 0 || doctorIpCommercial_archived && doctorIpCommercial_archived.length > 0 || doctorIPParameter_archived && doctorIPParameter_archived.length > 0 || doctorOPParameter_archived && doctorOPParameter_archived.length > 0 || doctorMgDetails_archived && doctorMgDetails_archived.length > 0 || doctorFixedDetails_archived && doctorFixedDetails_archived.length > 0 ?
            <Button
              // autoFocus
              onClick={handlerViewOldCommercial}
              color="primary"
              variant="contained"
              size="small"
            >
              {showOldCommercial ? "Current Commercials" : "Old Commercials"}
            </Button>
            : null}
          <Button
            autoFocus
            onClick={handlerCloseCommercialSummery}
            color="secondary"
            variant="contained"
            size="small"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        className={backClasses.backdrop}
        open={backDropOpen}
      >
        <img src={c9gif} alt="loading..." />
      </Backdrop>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}

export default ViewStatus;
