import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Container, Row, Col, Card } from "react-bootstrap";
import BillingPieSummary from "../../Components/BillingPieSummary";
import userImg from "./../../images/user.png";
import profileImg from "./../../images/profile.jpg";
import axios from "axios";
import { DateHelper } from "../../CommomMethods/DateHelper"
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Navbar from "../../Components/Navbar";
import { documentlist } from "../../actions/documentActions";
import DoctorSidebar from "../../Components/DoctorSidebar";
import Timeline from "../../Components/Timeline";
import { onboardById } from "../../actions/onboardActions";
import { agreementviewById } from "../../actions/agreementActions";
import { agreementState, onboardState } from "../../CommomMethods/States";
import DialogContent from "@material-ui/core/DialogContent";
//mui
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { APIURL } from "../../CommomMethods/Fetch";
import { numberWithCommas, numberToEnglish, dateFormatter } from "../../actions/createPositionActions"
const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "20%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
}));
function DoctorProfileStatus({ reqTableData }) {
  const tables = myTableStyle();
  const [newVis, setNewVis] = useState("hiddenDiv");
  const [ongVis, setOngVis] = useState("hiddenDiv");
  const [compVis, setCompVis] = useState("hiddenDiv");
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalShow, setModalShow] = React.useState(false);
  const [qualification, setQualification] = React.useState("");
  const [lists, setList] = React.useState([]);
  const [employers, setEmployer] = React.useState([]);
  const [previousEmployer, setPreviousEmployer] = React.useState("");
  const [doctorDetail, setDoctorDetail] = React.useState([]);
  const [doctorOpDetail, setDoctorOpDetail] = React.useState([]);
  const [doctorIpDetail, setDoctorIpDetail] = React.useState([]);
  const [doctorOpCommercial, setDoctorOpCommercial] = React.useState([]);
  const [doctorIpCommercial, setDoctorIpCommercial] = React.useState([]);
  const [doctorMgDetails, setDoctorMgDetails] = React.useState([]);
  const [doctorFixedDetails, setDoctorFixedDetails] = React.useState([]);
  const [CenterArray, setCenterArray] = React.useState([]);
  const [rowData, setRowData] = useState({});
  const labelStyle = { paddingBottom: "5px", color: "black" };
  const infoStyle = {
    padding: "5px",
    border: "2px solid rgb(133, 133, 133, 0.4)",
    fontWeight: "lighter",
    borderRadius: "5px",
  };
  // const percentage = 66;
  // const info = "Clearing Exceptions";
  const onboardId = useSelector((state) => state.onboardId);
  const { oboard } = onboardId;

  const agreementView = useSelector((state) => state.agreementView);
  const { agreementview } = agreementView;

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, user } = userLogin;

  const listDoc = useSelector((state) => state.listDoc);
  const { list } = listDoc;


  const cardStyle = {
    background: "transparent",
    border: "0px",
  };
  // const infoStyle = {
  //     border: '2px solid #858585',
  // }
  const containerStyle = {
    margin: "0px",
  };
  const subTitle = {
    fontSize: "13px",
  };
  const percentage = 66;
  const info = "Clearing Exceptions";

  function tabChange(stat) {
    if (stat == "new") {
      if (newVis == "hiddenDiv") {
        setNewVis("visibleDiv");
      } else {
        setNewVis("hiddenDiv");
      }
    }
  }
  useEffect(() => {
    dispatch(onboardById(user.user.id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(agreementviewById(user.user.id));
  }, [dispatch]);

  useEffect(() => {
    dispatch(documentlist(user.user.id));
  }, [dispatch]);


  const addEmployer = () => {
    const obj = {
      doctorId: user.user.id,
      previousemployer: previousEmployer,
    };
    axios.post(APIURL() + "onboard/action/", obj).then((res) => {
      window.location.reload();
      // toast.success("Successfully Confirmed");
    });
  };

  useEffect(() => {
    dispatch(documentlist(user.user.id));
  }, [dispatch]);

  const addQualification = () => {
    const obj = {
      doctorId: user.user.id,
      addQualification: qualification,
    };
    axios.post(APIURL() + "onboard/action/", obj).then((res) => {
      window.location.reload();
      // toast.success("Successfully Confirmed");
    });
  };

  useEffect(() => {
    axios
      .get(APIURL() + `users/checkdocid/${user.user.id}`)
      .then((res) => {
        setRowData(res.data.data);
      })
      .catch((err) => {
        // toast.error("Please try after sometime!");
      });
    axios
      .get(APIURL() + "addqualification/getbyid/" + user.user.id)
      .then((res) => {
        console.log(res.data, "llll");
        setList(res.data.qualificationdetails)
      })
    axios
      .get(APIURL() + "onboard/previewOne/" + user.user.id)
      .then((res) => {
        setDoctorDetail(res.data);
        setDoctorIpDetail(res.data.doctorIPParameter);
        setDoctorOpDetail(res.data.doctorOPParameter);
        setDoctorOpCommercial(res.data.doctorOpCommercial);
        setDoctorIpCommercial(res.data.doctorIpCommercial);
        setDoctorMgDetails(res.data.doctorMgDetails);
        setDoctorFixedDetails(res.data.doctorFixedDetails);
        let CenterArray = []
        if (res.data.doctorMgDetails && res.data.doctorMgDetails.length > 0) {
          res.data.doctorMgDetails.map(item => {
            CenterArray.push(item.Center)
          })
        }
        if (res.data.doctorFixedDetails && res.data.doctorFixedDetails.length > 0) {
          res.data.doctorFixedDetails.map(item => {
            CenterArray.push(item.Center)
          })
        }
        setCenterArray(CenterArray)
      })
      .catch((error) => { });
  }, []);

  useEffect(() => {
    axios
      .get(APIURL() + "employer/" + user.user.id)
      .then((res) => res.data)
      .then((rows) => {
        setEmployer(rows);
      });
  }, []);
  const viewSignedAgreementDoc = async () => {
    console.log(rowData.digio_doc_id, "view-agreement-ch");
    // setAgreementRow(row);
    await axios.get(APIURL() + "onboard/download-agreement/" + rowData.digio_doc_id, {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
      },
    }).then((res) => {
      console.log(res)
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.target = "_blank";
      link.download = "Doctor Agreement.pdf"
      link.href = url;
      // link.setAttribute('download', 'Doctor Agreement.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
  };
  const TableTitle = ({ text }) => (
    <Typography
      style={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        color: "black",
        textDecoration: "underline",
        fontWeight: "bold",
        fontSize: "15px"
      }}
    >
      {text}
    </Typography>
  );
  return (
    <>
      <DoctorSidebar />
      <div className="main-content side-content pt-0">
        <div
          className="container-fluid"
          style={{ margin: "0 auto", backgroundColor: "white" }}
        >
          <div className="inner-body">
            <Navbar />

            <Grid container spacing={2} style={{ marginTop: "5px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  variant="h4"
                  gutterBottom
                  align="center"
                  style={{
                    color: "#810e36",
                    paddingTop: "20px",
                    borderBottom: "1px dashed rgb(0 150 63 / 52%)",
                    paddingBottom: "20px",
                  }}
                >
                  {"Profile Information"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Row style={{ margin: "1rem", float: "right" }}>
                  <button className="btn btn-primary" onClick={viewSignedAgreementDoc}> Download Agreement </button>
                </Row>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Paper elevation={24} style={{ padding: "20px", border: "1px solid" }}>
                  {list.data &&
                    list.data
                      .filter((z) => z.docTitle === "Passport Size Photograph")
                      .map((ele) => (
                        <img
                          class="img-responsive"
                          src={APIURL() + "documents/download/" + ele.url}
                          style={{
                            borderRadius: "49%",
                            marginRight: "1rem",
                            width: "500px",
                          }}
                        />
                      ))}
                  <Typography variant="h6" style={{ textAlign: "center" }}>
                    Dr.{" "}
                    {oboard.doctorCommercial &&
                      oboard.doctorCommercial[0] && oboard.doctorCommercial[0].fullname}
                  </Typography>
                  <Typography variant="subtitle2" style={{ textAlign: "center", marginTop: "4px" }}>
                    Designation: {oboard.doctorCommercial &&
                      oboard.doctorCommercial[0] && oboard.doctorCommercial[0].designationlist}
                  </Typography>
                  <Typography variant="subtitle2" style={{ textAlign: "center", marginTop: "4px" }}>
                    Department: {oboard.doctorCommercial &&
                      oboard.doctorCommercial[0] && oboard.doctorCommercial[0].departmentlist}
                  </Typography>
                  <Typography variant="subtitle2" style={{ textAlign: "center", marginTop: "4px" }}>
                    {/* Branch: {oboard.doctorCommercial &&
                      oboard.doctorCommercial[0] && oboard.doctorCommercial[0].businessunit} */}
                    Branch: {CenterArray && CenterArray.length > 0 ? CenterArray.join(', ') : '---'}
                  </Typography>
                  <Typography variant="subtitle2" style={{ textAlign: "center", marginTop: "4px" }}>
                    Term: {oboard.doctorCommercial &&
                      oboard.doctorCommercial[0] && oboard.doctorCommercial[0].contractlength} Months
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "20px", border: "1px solid" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        CONTACT
                      </h5>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Email :{" "}
                        {oboard.doctorDetails && oboard.doctorDetails.email}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Phone :{" "}
                        {oboard.doctorCommercial && oboard.doctorCommercial && oboard.doctorCommercial.length > 0 &&
                          oboard.doctorCommercial[0].mobile}
                      </Typography>{" "}
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Permanent Addr :{" "}
                        {oboard.doctorCommercial && oboard.doctorCommercial && oboard.doctorCommercial.length > 0 &&
                          oboard.doctorCommercial[0].PermanentAddress}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Present Addr :{" "}
                        {oboard.doctorCommercial && oboard.doctorCommercial && oboard.doctorCommercial.length > 0 &&
                          oboard.doctorCommercial[0].Address}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        pin code :{" "}
                        {oboard.doctorCommercial && oboard.doctorCommercial && oboard.doctorCommercial.length > 0 &&
                          oboard.doctorCommercial[0].PinCode}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "20px", border: "1px solid" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        PERSONAL DETAIL
                      </h5>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Personal Email :{" "}
                        {oboard.doctorDetails && oboard.doctorDetails.isConfirmed == 1 ?
                          oboard.doctorDetails && oboard.doctorDetails.email
                          : ""}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Aadhaar :{" "}
                        {oboard.doctorCommercial && oboard.doctorCommercial && oboard.doctorCommercial.length > 0 &&
                          oboard.doctorCommercial[0].adhaarno}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        PAN :{" "}
                        {oboard.doctorCommercial && oboard.doctorCommercial && oboard.doctorCommercial.length > 0 &&
                          oboard.doctorCommercial[0].panno}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        DOB :{" "}
                        {DateHelper(oboard.doctorCommercial && oboard.doctorCommercial && oboard.doctorCommercial.length > 0 &&
                          oboard.doctorCommercial[0].birthday.substring(0, 10))}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        DOJ :{" "}
                        {DateHelper(oboard.doctorCommercial && oboard.doctorCommercial && oboard.doctorCommercial.length > 0 &&
                          oboard.doctorCommercial[0].doj.substring(0, 10))}
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "20px", border: "1px solid" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        BANK DETAIL
                      </h5>
                      <Typography
                        variant="subtitle2"
                        style={{ textAlign: "center" }}
                      ></Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Bank name :
                        {oboard.doctorCommercial && oboard.doctorCommercial && oboard.doctorCommercial.length > 0 &&
                          oboard.doctorCommercial[0].bankname}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        IFSC :
                        {oboard.doctorCommercial && oboard.doctorCommercial && oboard.doctorCommercial.length > 0 &&
                          oboard.doctorCommercial[0].bankifsc}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Account No. :
                        {oboard.doctorCommercial && oboard.doctorCommercial && oboard.doctorCommercial.length > 0 &&
                          oboard.doctorCommercial[0].bankaccno}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "20px", border: "1px solid" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        OTHER DETAILS
                      </h5>
                      <Typography variant="overline" display="block" style={{ textAlign: "center" }}>About: {oboard.doctorCommercial && oboard.doctorCommercial[0] && oboard.doctorCommercial[0].aboutdoctor}</Typography>
                      <Typography variant="overline" display="block" style={{ textAlign: "center" }}
                      >
                        UG Degree: {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].medicaldegree}
                      </Typography>
                      <Typography variant="overline" display="block" style={{ textAlign: "center" }}
                      >
                        PG Degree: {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].pgdegree}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Indemnity Exp :
                        {oboard && oboard.doctorCommercial &&
                          oboard.doctorCommercial[0] && oboard.doctorCommercial[0].indemnityexpiry ? dateFormatter(oboard.doctorCommercial[0].indemnityexpiry) : "---"}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "20px", border: "1px solid" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        PREVIOUS EMPLOYER DETAILS
                      </h5>

                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        {employers && employers.employer && employers.employer.map((ele) =>
                          <>

                            <div>Designation: {ele.designation}</div>
                            <div>Organization: {ele.organization}</div>
                            <div>Work From: {ele.workfrom}</div>
                            <div>Work Till: {ele.workto}</div>
                            <div>Describe Job: {ele.describejob}</div>

                          </>

                        )}

                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "20px", border: "1px solid" }}
                    >
                      <h5 className="pb-2" style={{ textAlign: "center", borderBottom: "1px solid" }}>
                        ADDITIONAL QUALIFICATION DETAILS
                      </h5>

                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        {lists && lists.map((ele, Index) =>
                          <>
                            <div>Qualification {Index + 1}: {ele.qualification}</div>
                          </>
                        )}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  variant="h4"
                  gutterBottom
                  align="center"
                  style={{
                    color: "#810e36",
                    paddingTop: "20px",
                    borderBottom: "1px dashed rgb(0 150 63 / 52%)",
                    paddingBottom: "20px",
                  }}
                >
                  {"Commercials Summary"}
                </Typography>
              </Grid>
            </Grid>
            <DialogContent>
              <Paper className={tables.root} style={{ border: "1px solid" }}>
                {/* <h5 className="p-2" style={{ fontWeight: "bold", borderBottom: "1px solid" }}>Commercials Summary</h5> */}

                <MaterialTable
                  columns={[
                    { title: "Center Name", field: "center", render: (row) => (<div>{row.center ? row.center : "---"}</div>) },
                    { title: "Item Group", field: "itemgroup", render: (row) => (<div>{row.itemgroup ? row.itemgroup : "---"}</div>) },
                    { title: "Item Name", field: "itemName", render: (row) => (<div>{row.itemName ? row.itemName : "---"}</div>) },
                    { title: "Commercial Term", field: "commercialterm", render: (row) => (<div>{row.commercialterm ? row.commercialterm : "---"}</div>) },
                  ]}
                  data={doctorOpCommercial}
                  options={{
                    // filtering: true,
                    sorting: true,
                    paging: false,
                    search: false,
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px"
                    },
                  }}
                  title={<TableTitle text="Out-Patient Commercial" />}
                />
                <MaterialTable
                  columns={[
                    { title: "Center Name", field: "Center", render: (row) => (<div>{row.Center ? row.Center : "---"}</div>) },
                    { title: "Item Group", field: "itemGroup", render: (row) => (<div>{row.itemGroup ? row.itemGroup : "---"}</div>) },
                    { title: "Item Name", field: "itemName", render: (row) => (<div>{row.itemName ? row.itemName : "---"}</div>) },
                    { title: "Room Category", field: "roomCategory", render: (row) => (<div>{row.roomCategory ? row.roomCategory : "---"}</div>) },
                    { title: "MOP", field: "ipmodeofpayment", render: (row) => (<div>{row.ipmodeofpayment ? row.ipmodeofpayment : "---"}</div>) },
                    { title: "Commercial Term", field: "commercialTerm", render: (row) => (<div>{row.commercialTerm ? row.commercialTerm : "---"}</div>) },
                  ]}
                  // data={doctorDetail.doctorIPParameter}
                  data={doctorIpDetail}
                  options={{
                    // filtering: true,
                    sorting: true,
                    paging: false,
                    search: false,
                    headerStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px",
                    },
                    cellStyle: {
                      border: "1px solid rgba(224, 224, 224, 1)",
                      padding: "3px 10px"
                    },
                  }}
                  title={<TableTitle text="In-Patient Commercials" />}
                />
                {doctorMgDetails && doctorMgDetails.length > 0 ?
                  doctorMgDetails.map((item) =>
                    <MaterialTable
                      columns={[
                        { title: "Minimum Guarantee Payment: The Company agrees to pay the Consultant an amount of Rs. " + numberWithCommas(item.minguranteepermonth) + " (Rs. " + numberToEnglish((item.minguranteepermonth).replaceAll(',', '')) + ") per month (“Minimum Guarantee Amount”) or the “Calculated Earnings” whichever is higher subject to statutory deductions. ", field: "mgCommercial" },
                      ]}
                      // data={doctorDetail.doctorIPParameter}
                      data={
                        [
                          {
                            mgCommercial: 'The Minimum Guarantee Amount will be paid for ' + item.mgperiod + ' months'
                          },
                          {
                            mgCommercial: 'The earnings shall be calculated on ' + item.settlementperiod + ' basis and difference amount (Calculated Earnings minus Minimum Guarantee), if any, shall be paid at the end of ' + item.settlementpaymentperiod + '.'
                          },
                        ]
                      }
                      options={{
                        // filtering: true,
                        sorting: true,
                        paging: false,
                        search: false,
                        headerStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px",
                          fontWeight: 400
                        },
                        cellStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px"
                        },
                      }}
                      title={<TableTitle text={"Minimum Guarentee (MG) Commercial for " + item.Center} />}
                    />
                  )
                  : ''}
                {doctorFixedDetails && doctorFixedDetails.length > 0 ?
                  doctorFixedDetails.map((item) =>
                    <MaterialTable
                      columns={[
                        { title: "Monthly Fixed Fee: The Company will pay an all-inclusive amount of Rs. " + numberWithCommas((item.professionalfeepermonth).replaceAll(',', '')) + " (Rs. " + numberToEnglish((item.professionalfeepermonth).replaceAll(',', '')) + ") per month. ", field: "fixedCommercial", width: "100%" },
                      ]}
                      // data={doctorDetail.doctorIPParameter}
                      data={
                        [
                          {
                            fixedCommercial: item.additionalfeevalue != '0' ? "Additional Fee for " + item.additionalfeename + ": The Company will pay an additional amount of Rs. " + numberWithCommas(((item.additionalfeevalue).toString()).replaceAll(',', '')) + " (Rs. " + numberToEnglish(((item.additionalfeevalue).toString()).replaceAll(',', '')) + ") per month. " : ""
                          },
                        ]
                      }
                      options={{
                        // filtering: true,
                        sorting: false,
                        paging: false,
                        search: false,
                        headerStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px",
                          fontWeight: 400
                        },
                        cellStyle: {
                          border: "1px solid rgba(224, 224, 224, 1)",
                          padding: "3px 10px"
                        },
                      }}
                      title={<TableTitle text={"Full Time Consultant Commercial for " + item.Center} />}
                    />
                  )
                  : ''}
              </Paper>
              <br />
            </DialogContent>
            {/* <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  variant="h4"
                  gutterBottom
                  align="center"
                  style={{
                    color: "#810e36",
                    paddingTop: "20px",
                    borderBottom: "1px dashed rgb(0 150 63 / 52%)",
                    paddingBottom: "20px",
                  }}
                >
                  {"Onboard Information"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginTop: "5px" }}>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Paper
                  elevation={24}
                  style={{ height: "520px", padding: "10px" }}
                >
                  {/* <Typography variant="h5" style={{ textAlign: "center" }}>
                    DOCUMENT APPROVAL STATUS
                  </Typography> 

                  <MaterialTable
                    columns={[
                      {
                        title: "Current Action",
                        field: "currentAction",
                        filtering: false,
                        render: (row) => (
                          <div style={{ width: "140px" }}>
                            {row.currentAction === onboardState.init.state ? (
                              <span style={{ color: "green" }}>
                                {" "}
                                {onboardState.init.message}
                              </span>
                            ) : row.currentAction ===
                              onboardState.upload.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.upload.message}
                              </span>
                            ) : row.currentAction === onboardState.hr.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.hr.message}
                              </span>
                            ) : row.currentAction === onboardState.ch.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch.message}
                              </span>
                            ) : row.currentAction === onboardState.rd.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.rd.message}
                              </span>
                            ) : row.currentAction ===
                              onboardState.done.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.done.message}
                              </span>
                            ) : row.currentAction ===
                              onboardState.ch_doc.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch_doc.message}
                              </span>
                            ) : row.currentAction === onboardState.completed ? (
                              <span style={{ color: "blue" }}>"COMPLETED"</span>
                            ) : row.currentAction ===
                              onboardState.ch_renew.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch_renew.message}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ),
                      },
                      {
                        title: "Next Action",
                        field: "nextAction",
                        filtering: false,
                        render: (row) => (
                          <div style={{ width: "140px" }}>
                            {row.nextAction === onboardState.init.state ? (
                              <span style={{ color: "green" }}>
                                {" "}
                                {onboardState.init.message}
                              </span>
                            ) : row.nextAction === onboardState.upload.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.upload.message}
                              </span>
                            ) : row.nextAction === onboardState.hr.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.hr.message}
                              </span>
                            ) : row.nextAction === onboardState.ch.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch.message}
                              </span>
                            ) : row.nextAction === onboardState.rd.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.rd.message}
                              </span>
                            ) : row.nextAction === onboardState.done.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.done.message}
                              </span>
                            ) : row.nextAction === onboardState.ch_doc.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch_doc.message}
                              </span>
                            ) : row.nextAction === onboardState.completed ? (
                              <span style={{ color: "green" }}>"COMPLETED"</span>
                            ) : row.nextAction ===
                              onboardState.ch_renew.state ? (
                              <span style={{ color: "blue" }}>
                                {onboardState.ch_renew.message}
                              </span>
                            ) : (
                              ""
                            )}
                          </div>
                        ),
                      },
                    ]}
                    data={oboard.onBoardData}
                    options={{
                      sorting: true,
                      exportButton: false,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: false,
                    }}
                    title="DOCUMENT APPROVAL STATUS"
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Paper
                  elevation={24}
                  style={{ height: "520px", padding: "10px" }}
                >
                  {/* <Typography variant="h5" style={{ textAlign: "center" }}>
                    AGREEMENT APPROVAL STATUS
                  </Typography> 
                  <MaterialTable
                    columns={[
                      {
                        title: "Current Action",
                        field: "currentAction",
                        filtering: false,
                        render: (row) => (
                          <div style={{ width: "140px" }}>
                            {row.currentAction === agreementState.init.state ? (
                              <span style={{ color: "green" }}>
                                {" "}
                                {agreementState.init.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.rd_revision.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.rd_revision.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.hr_revision.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.hr_revision.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.dr_accepted.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.dr_accepted.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.hr_accepted.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.hr_accepted.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.ch_approved.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.ch_approved.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.ceo_approved.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.ceo_approved.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.rd_approved.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.rd_approved.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.dr_decline.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.dr_decline.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.ch_decline.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.ch_decline.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.rd_decline.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.rd_decline.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.hr_decline.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.hr_decline.message}
                              </span>
                            ) : row.currentAction ===
                              agreementState.completed ? (
                              <span style={{ color: "green" }}>"COMPLETED"</span>
                            ) : (
                              ""
                            )}
                          </div>
                        ),
                      },
                      {
                        title: "Next Action",
                        field: "nextAction",
                        filtering: false,
                        render: (row) => (
                          <div style={{ width: "140px" }}>
                            {row.nextAction === agreementState.init.state ? (
                              <span style={{ color: "red" }}>
                                {" "}
                                {agreementState.init.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.rd_revision.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.rd_revision.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.hr_revision.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.hr_revision.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.dr_accepted.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.dr_accepted.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.hr_accepted.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.hr_accepted.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.ch_approved.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.ch_approved.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.ceo_approved.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.ceo_approved.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.rd_approved.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.rd_approved.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.dr_decline.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.dr_decline.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.ch_decline.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.ch_decline.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.rd_decline.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.rd_decline.message}
                              </span>
                            ) : row.nextAction ===
                              agreementState.hr_decline.state ? (
                              <span style={{ color: "blue" }}>
                                {agreementState.hr_decline.message}
                              </span>
                            ) : row.nextAction === agreementState.completed ? (
                              <span style={{ color: "blue" }}>"COMPLETED"</span>
                            ) : (
                              ""
                            )}
                          </div>
                        ),
                      },
                    ]}
                    data={oboard.doctorAgreement}
                    options={{
                      sorting: true,
                      exportButton: false,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: false,
                    }}
                    title=" AGREEMENT APPROVAL STATUS"
                  />
                </Paper>
              </Grid>
            </Grid> */}
            <div className="col-xl-12 col-md-12 pt-4">
              <Row>
                <Col>
                  <Card.Body>
                    {user.user.activityAssigments.filter((elem) => elem.activityId === 8).find(item => item.RoleId === user.user.roles) ? (
                      <>
                        <Row style={{ margin: "1rem" }}>
                          <Col md={8}>
                            <label style={{ fontWeight: "bold" }}>
                              {" "}
                              Enter LT ID:
                            </label>
                            <input
                              type="text"
                              className="form-control "
                              style={{ border: "1px solid grey" }}
                            ></input>
                          </Col>
                        </Row>

                        <Row style={{ margin: "1rem", float: "right" }}>
                          <button className="btn btn-primary">Confirm</button>
                        </Row>
                      </>
                    ) : (
                      ""
                    )}
                  </Card.Body>
                </Col>
              </Row>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorProfileStatus;
