
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button, Container, Row, Col, Card } from 'react-bootstrap'
import BillingPieSummary from '../../Components/BillingPieSummary'
import userImg from './../../images/user.png'
import { DateHelper } from "../../CommomMethods/DateHelper"
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Navbar from '../../Components/Navbar'
import axios from "axios";
import { documentlist } from "../../actions/documentActions";
import HrSidebar from "../../Components/hrSidebar";
import Timeline from '../../Components/Timeline'
import { onboardById } from '../../actions/onboardActions'
import { useLocation } from "react-router-dom";
import { onboardState } from "../../CommomMethods/States";
//import image
import profileImg from "./../../images/profile.jpg";
//import from mui
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { APIURL } from "../../CommomMethods/Fetch";
import { dateFormatter } from "../../actions/createPositionActions";


function DoctorProfileHR({ reqTableData }) {
  const location = useLocation();
  const [newVis, setNewVis] = useState("hiddenDiv")
  const [ongVis, setOngVis] = useState("hiddenDiv")
  const [compVis, setCompVis] = useState("hiddenDiv")
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [lists, setList] = React.useState([]);
  const [employers, setEmployer] = React.useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalShow, setModalShow] = React.useState(false);
  const labelStyle = { paddingBottom: "5px", color: "black" };
  const infoStyle = { padding: "5px", border: "2px solid rgb(133, 133, 133, 0.4)", fontWeight: "lighter", borderRadius: "5px" };
  // const percentage = 66;
  // const info = "Clearing Exceptions";
  const onboardId = useSelector((state) => state.onboardId);
  const { oboard } = onboardId;
  const [doctorDetail, setDoctorDetail] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { loading, error, user } = userLogin;

  const listDoc = useSelector((state) => state.listDoc);
  const { list } = listDoc;

  console.log(oboard,"oboard((((")
  const cardStyle = {
    background: 'transparent',
    border: '0px',
  }
  // const infoStyle = {
  //     border: '2px solid #858585',
  // }
  const containerStyle = {
    margin: '0px',
  }
  const subTitle = {
    fontSize: "13px"
  }
  const percentage = 66;
  const info = "Clearing Exceptions";

  function tabChange(stat) {
    if (stat == "new") {
      if (newVis == "hiddenDiv") {
        setNewVis("visibleDiv")
      }
      else {
        setNewVis("hiddenDiv")
      }

    }
  }
  useEffect(() => {
    axios
      .get(APIURL() + "qualification/" + user.user.id)
      .then((res) => res.data)
      .then((rows) => {
        setList(rows);
      });
  }, []);

  useEffect(() => {
    axios
      .get(APIURL() + "employer/" + user.user.id)
      .then((res) => res.data)
      .then((rows) => {
        setEmployer(rows);
      });
  }, []);
  useEffect(() => {
    dispatch(onboardById(location && location.state && location.state.id));
  }, [dispatch])

  useEffect(() => {
    dispatch(documentlist(location && location.state && location.state.id));
  }, [dispatch])

  const [datatable, setDatatable] = React.useState({
    columns: [
      {
        label: 'RequestId',
        field: 'requestId',
        width: 100,
      },
      {
        label: 'Request Type',
        field: 'requestType',
        width: 100,
      },
      {
        label: 'Request Raised By',
        field: 'requestRaisedBy',
        sort: 'asc',
        width: 100,
      },
      {
        label: 'Date',
        field: 'date',
        sort: 'disabled',
        width: 100,
      },
      {
        label: 'Subject',
        field: 'subject',
        sort: 'disabled',
        width: 100,
      },
    ],

    rows: reqTableData

  });
  return (
    <>

      <HrSidebar />
      <div className="main-content side-content pt-0">
        <div className="container-fluid" style={{ margin: "0 auto", backgroundColor: "white" }}>
          <div className="inner-body">

            <Navbar />

            <br />
            <hr />


            <Grid container spacing={2} style={{ marginTop: "5px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  variant="h4"
                  gutterBottom
                  align="center"
                  style={{
                    color: "#810e36",
                    paddingTop: "20px",
                    borderBottom: "1px dashed rgb(0 150 63 / 52%)",
                    paddingBottom: "20px",
                  }}
                >
                  {"Profile Information"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Paper elevation={24} style={{ padding: "20px",height:"100%" }}>
                  {/* <img
                    src={profileImg}
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      margin: "0 auto",
                      display: "block",
                    }}
                  /> */}

                  {list.data && list.data.filter(z => z.docTitle === "Passport Size Photograph").map((ele) => (
                    <img class="img-responsive" src={APIURL() + "documents/download/" + ele.url} style={{ borderRadius: "49%", marginRight: "1rem", width: "500px",height:"60%" }} />
                  ))}
                  <Typography style={{ textAlign: "center", marginTop: "4px" }}>
                    {oboard.doctorCommercial &&
                      oboard.doctorCommercial[0].designationlist}
                  </Typography>
                  <Typography variant="h6" style={{ textAlign: "center",marginTop:"15%"  }}>
                    Dr.{" "}
                    {oboard.doctorCommercial &&
                      oboard.doctorCommercial[0].fullname}
                  </Typography>
                  {oboard.doctorDetails && oboard.doctorDetails.isConfirmed == 1 ?
                    <Typography
                      variant="subtitle2"
                      style={{ textAlign: "center" }}
                    >
                      LT Id- {oboard.doctorDetails && oboard.doctorDetails.LTId}
                    </Typography>
                  :null}
                  <Typography
                    variant="subtitle2"
                    style={{ textAlign: "center" }}
                  >
                    About {oboard.doctorCommercial &&
                      oboard.doctorCommercial[0].fullname}-  <i>{oboard.doctorCommercial &&
                        oboard.doctorCommercial[0].aboutdoctor}</i>
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ textAlign: "center" }}
                  >
                    {oboard.doctorCommercial &&
                      oboard.doctorCommercial[0].medicaldegree}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{ textAlign: "center" }}
                  >
                    {oboard.doctorCommercial &&
                      oboard.doctorCommercial[0].pgdegree}
                  </Typography>

                </Paper>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "20px" }}
                    >
                      <Typography variant="h5" style={{ textAlign: "center" }}>
                        CONTACT
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Email :{" "}
                        {oboard.doctorDetails && oboard.doctorDetails.email}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Phone :{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].mobile}
                      </Typography>{" "}
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Permanent Addr :{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].PermanentAddress}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Present Addr :{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].Address}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        pin code :{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].PinCode}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "20px" }}
                    >
                      <Typography variant="h5" style={{ textAlign: "center" }}>
                        PERSONAL DETAIL
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Personal Email :{" "}
                        {oboard.doctorDetails && oboard.doctorDetails.isConfirmed == 1 ? 
                          oboard.doctorDetails && oboard.doctorDetails.email
                          : ""}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Aadhaar :{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].adhaarno}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Pan Card :{" "}
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].panno}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        DOB :{" "}
                        {DateHelper(oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].birthday.substring(0, 10))}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        DOJ :{" "}
                        {DateHelper(oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].doj.substring(0, 10))}
                      </Typography>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "20px" }}
                    >
                      <Typography variant="h5" style={{ textAlign: "center" }}>
                        BANK DETAIL
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{ textAlign: "center" }}
                      ></Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        BANk name :
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].bankname}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        ifsc :
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].bankifsc}
                      </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        account no :
                        {oboard.doctorCommercial &&
                          oboard.doctorCommercial[0].bankaccno}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "10px" }}
                    >
                      <Typography variant="h5" style={{ textAlign: "center" }}>
                        OTHER DETAILS
                      </Typography>

                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        Indemnity Exp :
                        {oboard.doctorCommercial && oboard.doctorCommercial[0] && oboard.doctorCommercial[0].indemnityexpiry && 
                          oboard.doctorCommercial[0].indemnityexpiry}
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "10px" }}
                    >

                      <Typography
                        variant="h5" style={{ textAlign: "center" }}
                      >
                        PREVIOUS EMPLOYER DETAIL:
                   </Typography>
                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        {employers && employers.employer && employers.employer.map((ele) =>
                          <>

                            <div>Designation: {ele.designation}</div>
                            <div>Organization: {ele.organization}</div>
                            <div>Work From: {ele.workfrom}</div>
                            <div>Work Till: {ele.workto}</div>
                            <div>Describe Job: {ele.describejob}</div>

                          </>

                        )}

                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Paper
                      elevation={24}
                      style={{ height: "260px", padding: "10px" }}
                    >

                      <Typography
                        variant="h5" style={{ textAlign: "center" }}
                      >
                        QUALIFICATION DETAIL:
                     </Typography>

                      <Typography
                        variant="overline"
                        display="block"
                        style={{ textAlign: "center" }}
                      >
                        {lists && lists.qualification && lists.qualification.map((ele) =>
                          <>

                            <div>Higher Qualification: {ele.qualificationhigher} {ele.qualificationhigherop} from {ele.qualificationhigheruniversity}</div>
                            <div>PG Qualification: {ele.qualificationpg} {ele.qualificationpgyop} from {ele.qualificationpguniversity}</div>
                            <div>UG Qualification: {ele.qualificationug} {ele.qualificationugyop} from {ele.qualificationuguniversity}</div>

                          </>

                        )}
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  variant="h4"
                  gutterBottom
                  align="center"
                  style={{
                    color: "#810e36",
                    paddingTop: "20px",
                    borderBottom: "1px dashed rgb(0 150 63 / 52%)",
                    paddingBottom: "20px",
                  }}
                >
                  {"Commercial Information"}
                </Typography>
              </Grid>
            </Grid>


            <div className="col-xl-12 col-md-12 pt-4" >
              {/* <Row >
                                <Col xs={2} md={2}>
                                {list.data && list.data.filter(z => z.docTitle === "Passport Size Photograph").map((ele) => (
                                  
                                    <img class="img-responsive" src={"documents/download/"+ele.url} style={{ borderRadius: "49%", margin:"1rem" ,width:"500px" }} />
                                    ))} 
                                    <h4 style={{marginLeft:"3rem"}}>Dr. {oboard.doctorCommercial && oboard.doctorCommercial[0].fullname} </h4>
                                    
                                </Col>
                                <Col xs={8} md={{ span: 8, offset: 1 }}>
                                    <Row>
                                        <Col xs={6} md={6} style={{ padding: "10px" }}>
                                            <h6 style={labelStyle}>Name</h6>
                                            <h6 style={infoStyle}>Dr. {oboard.doctorCommercial && oboard.doctorCommercial[0].fullname}</h6>
                                        </Col>
                                        <Col xs={6} md={6} style={{ padding: "10px" }}>
                                            <h6 style={labelStyle}>ID</h6>
                                           
                                            <h6 style={infoStyle}>{user.user.id}</h6>
                                        </Col>
                                        <Col xs={6} md={6} style={{ padding: "10px" }}>
                                            <h6 style={labelStyle}>Phone Number</h6>
                                            <h6 style={infoStyle}>{oboard.doctorCommercial && oboard.doctorCommercial[0].mobile}</h6>
                                        </Col>
                                        <Col xs={6} md={6} style={{ padding: "10px" }}>
                                            <h6 style={labelStyle}>Official Email</h6>
                                            <h6 style={infoStyle}>{oboard.doctorCommercial && oboard.doctorCommercial[0].email}</h6>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6} md={6} style={{ padding: "10px" }}>
                                            <h6 style={{ paddingBottom: "5px" }}>Specialization</h6>
                                            <h6 style={{ padding: "5px", border: "2px solid rgb(133, 133, 133, 0.4)", fontWeight: "lighter", borderRadius: "5px" }}>{oboard.doctorCommercial && oboard.doctorCommercial[0].departmentlist}</h6>
                                        </Col>
                                        <Col xs={6} md={6} style={{ padding: "10px" }}>
                                            <h6 style={{ paddingBottom: "5px" }}>Current Designation</h6>
                                            <h6 style={{ padding: "5px", border: "2px solid rgb(133, 133, 133, 0.4)", fontWeight: "lighter", borderRadius: "5px" }}>{oboard.doctorCommercial && oboard.doctorCommercial[0].designationlist}</h6>
                                        </Col>
                                        <Col xs={6} md={6} style={{ padding: "10px" }}>
                                            <h6 style={{ paddingBottom: "5px" }}>Nature of Employment</h6>
                                            <h6 style={{ padding: "5px", border: "2px solid rgb(133, 133, 133, 0.4)", fontWeight: "lighter", borderRadius: "5px" }}>{oboard.doctorCommercial && oboard.doctorCommercial[0].designationlist}</h6>
                                        </Col>
                                        <Col xs={6} md={6} style={{ padding: "10px" }}>
                                            <h6 style={{ paddingBottom: "5px" }}>PAN Number</h6>
                                            <h6 style={{ padding: "5px", border: "2px solid rgb(133, 133, 133, 0.4)", fontWeight: "lighter", borderRadius: "5px" }}>{oboard.doctorCommercial && oboard.doctorCommercial[0].pancardno}</h6>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col xs={6} md={6} style={{ padding: "10px" , marginBottom:"1rem" }}>
                                            <h6 style={{ paddingBottom: "5px" }}>Bank Name</h6>
                                            <h6 style={{ padding: "5px", border: "2px solid rgb(133, 133, 133, 0.4)", fontWeight: "lighter", borderRadius: "5px" }}>{oboard.doctorCommercial && oboard.doctorCommercial[0].BankName}</h6>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row> */}


              {/* <Row>
                                <Col>
                                    <Card style={{ padding: "5px" }}>
                                        <Card.Header style={{ fontSize: "19px", fontWeight: "bold", textAlign: "center" }}>
                                            Doctor Onboard Information
							</Card.Header>
                                        <Card.Body>
                                            <Row>

                                                <Col md={6}>
                                                    <Card>
                                                        <Card.Header>
                                                            <h5 style={{margin:"1rem", textAlign:"center"}}>Document Approval Status</h5>
                                                        </Card.Header>
                                                        <Card.Body style={{ height: "180px", border: "2px solid rgb(0,0,0, 0.4)", borderRadius: "5px" }}>
                                                            <table style={{ width: "100%" }}>
                                                                <thead style={{ borderBottom: "2px solid black" }}>
                                                                    <th>Current Action</th>
                                                                    <th style={{ textAlign: "left" }}>Next Action</th>
                                                                </thead>
                                                                <tbody style={{ width: "100%", borderBottom: "2px solid black" }}>

                                                                {oboard.onBoardData && oboard.onBoardData.map((ele) => (
                                                                    <tr style={{ width: "100%", border: "1px solid grey" }}>
                                                                    <td>{ele.currentAction === onboardState.init.state ? onboardState.init.message : ele.currentAction === onboardState.upload.state ?onboardState.upload.message :  ele.currentAction === onboardState.hr.state ? onboardState.hr.message : ele.currentAction === onboardState.ch.state ?  onboardState.ch.message :ele.currentAction === onboardState.rd.state ?onboardState.ch.message :onboardState.completed.message}</td>
                                                                    <td>{ele.nextAction === onboardState.init.state ? onboardState.init.message : ele.nextAction === onboardState.upload.state ?onboardState.upload.message :  ele.nextAction === onboardState.hr.state ? onboardState.hr.message : ele.nextAction === onboardState.ch.state ?  onboardState.ch.message :ele.nextAction === onboardState.rd.state ?onboardState.ch.message :onboardState.completed.message}</td>
                                                                </tr>
                                                                ))}
                                                                                                                    
                                                                </tbody>

                                                            </table>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col md={6}>
                                                    <Card>
                                                        <Card.Header>
                                                            <h5 style={{margin:"1rem", textAlign:"center"}}>Agreement Approval Status</h5>
                                                        </Card.Header>
                                                        <Card.Body style={{ height: "180px", border: "2px solid rgb(0,0,0, 0.4)", borderRadius: "5px" }}>
                                                            <table style={{ width: "100%" }}>
                                                                <thead style={{ borderBottom: "2px solid black" }}>
                                                                    <th>Approval By</th>
                                                                    <th style={{ textAlign: "left" }}>Status</th>
                                                                </thead>
                                                                <tbody style={{ width: "100%", borderBottom: "2px solid black" }}>
                                                                    <tr style={{ width: "100%", border: "1px solid grey" }}>
                                                                        <td>HR Approval</td>
                                                                        <td>Approved</td>
                                                                    </tr>
                                                                    <tr style={{ width: "100%", border: "1px solid grey" }}>
                                                                        <td>Doctor Approval</td>
                                                                        <td>Approved</td>
                                                                    </tr>
                                                                    <tr style={{ width: "100%", border: "1px solid grey" }}>
                                                                        <td>CH Approval</td>
                                                                        <td>Approved</td>
                                                                    </tr>
                                                                    <tr style={{ width: "100%", border: "1px solid grey" }}>
                                                                        <td>RD Approval</td>
                                                                        <td>Approved</td>
                                                                    </tr>
                                                                </tbody>

                                                            </table>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>

                                            </Row>
                                            <Row style={{ margin: "1rem" }}>
                                                <Col md={8}>
                                                    <label style={{ fontWeight: "bold" }}> Enter LT ID:</label>
                                                    <input type="text" className="form-control " style={{ border: "1px solid grey" }}></input>
                                                </Col>
                                            </Row>

                                            <Row style={{ margin: "1rem", float: "right" }}>
                                                <button className="btn btn-primary">Confirm</button>
                                            </Row>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row> */}
              <Row>
                <Col md={12}>
                  <MaterialTable
                    columns={[
                      { title: "Item Group", field: "itemgroup" },

                      { title: "Commercial Term", field: "commercialterm" },
                    ]}
                    // data={doctorDetail.doctorIPParameter}
                    data={oboard.doctorOpCommercial}
                    options={{
                      // filtering: true,
                      sorting: true,
                      exportButton: true,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: true,
                    }}
                    title="OP Commercial"
                  />
                  <br />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <MaterialTable
                    columns={[
                      { title: "Item Group", field: "itemgroup" },
                      { title: "Room Category", field: "iproom" },
                      { title: "MOP", field: "ipmodeofpayment" },

                      { title: "Commercial Term", field: "commercialterm" },
                    ]}
                    // data={doctorDetail.doctorIPParameter}
                    data={oboard.doctorIpCommercial}
                    options={{
                      // filtering: true,
                      sorting: true,
                      exportButton: true,
                      pageSize: 5,
                      pageSizeOptions: [5, 10, 50, 100, 200, 500],
                      search: true,
                    }}
                    title="IP Commercial"
                  />
                </Col>
              </Row>

              {/* <Row>
                            <h5 style={{ margin: "1rem" ,width:"100%", textAlign:"center"}}>OP Commercials</h5>
            <table className="table " style={{ width: "78%" }}>
              <thead>
                <tr style={{
                  textAlign: "center",
                  borderBottom: "1px solid rgb(200, 200, 200)",
                  backgroundColor: "hsla(340, 97%, 26%, 0.55)",
                  color: "white",
                }}>
                  <th className="wd-lg-20p"><span>Item Group/Procedures</span></th>
                  <th className="wd-lg-20p"><span>Commercial Term</span></th>
                </tr>
              </thead>
              <tbody>
                {oboard.doctorOPParameter && oboard.doctorOPParameter.map((ele) => (
                  <tr style={{ textAlign: "center" }}>
                    <td>{ele.itemGroup}</td>

                    <td>
                          {ele.opdiscounttype === "Yes" &&
                            ele.opadhoc !== "0"
                            ? ele.fixedpercentage !== "0"
                              ? ele.fixedpercentage +
                              "% of the Net Bill excluding Deductions"
                              : ele.fixedamount + " /- Per case"
                            : ""}
                          {ele.opdiscounttype === "No" &&
                            ele.opadhoc === "0"
                            ? ele.fixedpercentage !== "0"
                              ? ele.fixedpercentage +
                              " % of the Gross Amount"
                              : ele.fixedamount + " /- Per case"
                            : ""}
                          {ele.opdiscounttype === "Yes" &&
                            ele.opadhoc === "0"
                            ? ele.fixedpercentage !== "0"
                              ? ele.fixedpercentage +
                              " % of the Net Amount"
                              : ele.fixedamount + " /- Per case"
                            : ""}
                          {ele.opdiscounttype === "No" &&
                            ele.opadhoc !== "0"
                            ? ele.fixedpercentage !== "0"
                              ? ele.fixedpercentage +
                              "% of the Gross Bill excluding Deductions"
                              : ele.fixedamount + " /- Per case"
                            : ""}
                        </td> */}
              {/* <td> {
                      ele.opadhoc === null && ele.opdiscountvalue !== null ? <>  {ele.fixedpercentage !== ("0" || null) ? ele.fixedpercentage + "% of the Net Amount" : "Rs" + ele.fixedamount + "/- Per case"}  </> :
                        ele.opadhoc === null && ele.opdiscountvalue === null ? <>  {ele.fixedpercentage !== ("0" || null) ? ele.fixedpercentage + "% of the Gross Amount" : "Rs" + ele.fixedamount + "/- Per case"}  </> :
                          ele.opadhoc !== null && ele.opdiscountvalue === null ? <> {ele.fixedpercentage !== ("0" || null) ? ele.fixedpercentage + "% of the Gross Bill excluding Deductions" : "Rs" + ele.fixedamount + "/- Per case"}  </> :
                            ele.opadhoc !== null && ele.opdiscountvalue !== null ? <> {ele.fixedpercentage !== ("0" || null) ? ele.fixedpercentage + "% of the Net Bill excluding Deductions" : "Rs" + ele.fixedamount + "/- Per case"}  </> :
                              ele.fixedpercentage !== ("0" || null) ? <>  {ele.fixedpercentage}% of the consultation fee </> :
                                ele.fixedpercentage === ("0" || null) ? <> Rs.{ele.fixedamount}/- Per case </> : ""
                    }</td> */}
              {/* </tr>
                ))
                }
              </tbody>
            </table>


            <h5 style={{ margin: "1rem",width:"100%", textAlign:"center" }}>IP Commercials</h5>
            <table className="table " style={{ width: "78%" }}>
              <thead>
                <tr style={{
                  textAlign: "center",
                  borderBottom: "1px solid rgb(200, 200, 200)",
                  backgroundColor: "hsla(340, 97%, 26%, 0.55)",
                  color: "white",
                }} >
                  <th className="wd-lg-20p"><span>Item Group/Procedures</span></th>
                  <th className="wd-lg-20p"><span>Room Category</span></th>
                  <th className="wd-lg-20p"><span>MOP</span></th>
                  <th className="wd-lg-20p"><span>Commercial Term</span></th>
                </tr>
              </thead>
              <tbody>
                {oboard.doctorIPParameter && oboard.doctorIPParameter.map((ele) => (
                  <tr style={{ textAlign: "center" }} >
                    <td>{ele.itemGroup}</td>
                    <td>{ele.iproom}</td>
                    <td>{ele.ipmodeofpayment}</td>
                    <td>
                    {ele.ipdeductaionvalue === "0" && ele.ipdiscountType === "Yes" ? <> {ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Net Amount </> :
                            ele.ipdeductaionvalue !== "0" && ele.ipdiscountType === "No" ? <>  {ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Gross Bill excluding Deductions </> :
                              ele.ipdeductaionvalue !== "0" && ele.ipdiscountType === "Yes" ? <> {ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Net Bill excluding Deductions </> :
                                ele.ippackageamountperc !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountType === "No" ? <> {ele.ippackageamountperc}% of the Package Amount </> :
                                  ele.ipsurgeonfeeperc !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountType === "No" ? <> {ele.ipsurgeonfeeperc}% of the  Surgeon Fee </> :
                                    ele.ipnetbillpercentage !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountType === "No" ? <> {ele.ipnetbillpercentage}% of the Net Bill Amount </> :
                                      ele.ipgrossbillpercentage !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountType === "No" ? <> {ele.ipgrossbillpercentage}% of the Gross Bill Amount </>
                                       : ele.ipfixedamount !== 0
                                       ? "Rs" + ele.ipfixedamount + "/- of the Fixed Amount "
                                        : ""}
                        </td> */}
              {/* <td>
                      {ele.ipdeductaionvalue === "0" && ele.ipdiscountvalue !== "0" ? <> {ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Net Amount </> :
                        ele.ipdeductaionvalue !== "0" && ele.ipdiscountvalue === "0" ? <>  {ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Gross Bill excluding Deductions </> :
                          ele.ipdeductaionvalue !== "0" && ele.ipdiscountvalue !== "0" ? <> {ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage !== "0" ? ele.ipnetbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ippackageamountperc === "0" && ele.ipnetbillpercentage === "0" ? ele.ipgrossbillpercentage : ele.ipsurgeonfeeperc === "0" && ele.ipgrossbillpercentage === "0" && ele.ipnetbillpercentage === "0" ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Net Bill excluding Deductions </> :
                            ele.ippackageamountperc !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountvalue === "0" ? <> {ele.ippackageamountperc}% of the Package Amount </> :
                              ele.ipsurgeonfeeperc !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountvalue === "0" ? <> {ele.ipsurgeonfeeperc}% of the  Surgeon Fee </> :
                                ele.ipnetbillpercentage !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountvalue === "0" ? <> {ele.ipnetbillpercentage}% of the Net Bill Amount </> :
                                  ele.ipgrossbillpercentage !== "0" && ele.ipdeductaionvalue === "0" && ele.ipdiscountvalue === "0" ? <> {ele.ipgrossbillpercentage}% of the Gross Bill Amount </>
                                    : ""}
                    </td> */}

              {/* <td> {
                      ele.ippackageamountperc !== (null || "") ? <> {ele.ippackageamountperc}% of the Package Amount </> :
                        ele.ipsurgeonfeeperc !== (null || "") ? <> {ele.ipsurgeonfeeperc}% of the  Surgeon Fee </> :
                          ele.ipnetbillpercentage !== (null || "") ? <> {ele.ipnetbillpercentage}% of the Net Bill Amount </> :
                            ele.ipgrossbillpercentage !== (null || "") ? <> {ele.ipgrossbillpercentage}% of the Gross Bill Amount </> :
                              ele.ipdeductaionvalue === null && ele.ipdiscountvalue !== null ? <> {ele.ipsurgeonfeeperc === null && ele.ippackageamountperc === null ? ele.ipgrossbillpercent : ele.ipsurgeonfeeperc === null && ele.ipgrossbillpercentage === null ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Net Amount </> :
                                ele.ipdeductaionvalue === null && ele.ipdiscountvalue === null ? <>  {ele.ipsurgeonfeeperc === null && ele.ippackageamountperc === null ? ele.ipgrossbillpercent : ele.ipsurgeonfeeperc === null && ele.ipgrossbillpercentage === null ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Gross Amount </> :
                                  ele.ipdeductaionvalue !== null && ele.ipdiscountvalue === null ? <>  {ele.ipsurgeonfeeperc === null && ele.ippackageamountperc === null ? ele.ipgrossbillpercent : ele.ipsurgeonfeeperc === null && ele.ipgrossbillpercentage === null ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Gross Bill excluding Deductions </> :
                                    ele.ipdeductaionvalue !== null && ele.ipdiscountvalue !== null ? <> {ele.ipsurgeonfeeperc === null && ele.ippackageamountperc === null ? ele.ipgrossbillpercent : ele.ipsurgeonfeeperc === null && ele.ipgrossbillpercentage === null ? ele.ippackageamountperc : ele.ipsurgeonfeeperc}% of the Net Bill excluding Deductions </>
                                      : ""

                    }</td> */}
              {/* </tr>
                ))
                }
              </tbody>
            </table> */}


              {/* </Row> */}
              <br />
              <br />
            </div>
          </div>
        </div>
        {/* <button className="btn btn-primary" style={{float:"right", margin: "1rem"}}>Approve</button> */}
      </div>

    </>
  )
}

export default DoctorProfileHR
