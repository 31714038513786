import React from "react";
// material-table component
import MaterialTable from "material-table";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const myTableStyle = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    "& td": {
      fontFamili: "Roboto !important",
      textAlign: "center"
    },
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflow: "auto",
    marginBottom: "50px",
  },
}));

export default function ListUser({ rows, editRow, roleRows, deleteDepartment }) {
  const tables = myTableStyle();
  return (
    <>
      <Paper className={tables.root}>
        <MaterialTable
          columns={[
            { title: "Name", field: "name" },
            { title: "Role", field: "role_name", render: (row) => (
              roleRows.find((role) => role.id == row.users_role_id) ? roleRows.find((role) => role.id == row.users_role_id).name : "--"
            ) },
            { title: "Email-Id", field: "email" },
            { title: "Mobile", field: "mobile" },
            {
              title: "Status",
              field: "status",
              lookup: {
                1: "Active",
                0: "In Active",
                true: "Active",
                false: "In Active",
              },
            },
          ]}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit",
              iconProps: { style: { fontSize: "24px", color: "#5f5fe7" } },
              onClick: (event, row) => editRow(row),
            },
            // {
            //   icon: "delete",
            //   tooltip: "Delete",
            //   iconProps: { style: { fontSize: "24px", color: "#f55151" } },
            //   onClick: (event, row) => {
            //     deleteDepartment(row);
            //   },
            // },
          ]}
          data={rows.filter((z) => z.users_role_id !== 4)}
          options={{
            // filtering: true,
            sorting: true,
            exportButton: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 50, 100, 200, 500],
            search: true,
            columnsButton: true,
            exportAllData: true
          }}
          title="User"
        />
      </Paper>
    </>
  );
}
