import React, { useState, useEffect, useContext, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import NavFilter from "./Navfilter";
import { Modal, Button, Container, Row, Col, Card, Navbar } from "react-bootstrap";
import StackedDualBarGraph from "./StackedDualBarGraph";
import StackedBarGraphreveneue from "./StackedBarGraphreveneue";
import PieChartLabel from "./PieChartLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import PieChartStatus from "./PieChartStatus";
import PiechartIPOP from "./piechartIPOP";
import LineChartRevenue from "./LineChartRevenue";
import StackChartPayout from "./stackChartPayout";
import LineChartPayout from "./LineChartPayout";
import { APIURL } from "../CommomMethods/Fetch";
import { Box, makeStyles } from "@material-ui/core";
import Fab from '@material-ui/core/Fab';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import "react-circular-progressbar/dist/styles.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Slide from "@material-ui/core/Slide";
import Tooltip from '@mui/material/Tooltip';
import CustomGraphTable from "./CustomGraphTable";
import { AppContext } from "../actions/contextApi";
import arrowImg from '../images/Arrow-IP-OP.png'
import BarchartDoctorsByLocation from "./BarchartDoctorsByLocation";
import DoctorsByLocation from "./DoctorsByLocation";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles({
  btn: {
    backgroundColor: '#DE3959',
    opacity: '0.9',
    '&:hover': {
      backgroundColor: '#ae2d46'
    }
  },
  stickyDiv: {
    position: 'sticky',
    zIndex: '9999',
    top: '0px',
    padding: '10px, 0px',
  },
  filterIcon: {
    fill: "white"
  }
});
const backDropStyle = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));
export default function GraphsDashBoard() {
  const [navShow, setNavShow] = useState(false);
  const [backDrop, setBackDrop] = useState(false);
  const [doctorByDept, setDoctorByDept] = useState([]);
  const [DeptArr, setDeptArray] = useState([]);
  const [RevenueData, setRevenueData] = useState([]);
  const [RevenueyearData, setRevenueYearData] = useState([]);
  const [Legendyear, setLegendyear] = useState([]);
  const [RevenueLosData, setRevenueLosData] = useState([]);
  const [CenterList, setCenterList] = useState([]);
  const [IPOPcontriList, setIPOPcontriList] = useState([]);
  const [IPOPPayoutR, setIPOPpayout] = useState([]);
  const [CenterListLos, setCenterListLos] = useState([]);
  const [PayoutData, setPayoutData] = useState([]);
  const [PayoutLosData, setPayoutLosData] = useState([]);
  const [LegendList, setLegendList] = useState([]);
  const [LegendListLos, setLegendListLos] = useState([]);
  const [ItemGroupCount, setItemGroupCount] = useState([]);
  const [LegendPieChart, setLegendPieChart] = useState([]);
  const [onboardingStatus, setOnboardingStatus] = useState([]);
  const [actionName, setActionName] = useState([]);
  const [itemgroupList, setitemgroupList] = useState([]);
  const [DoctorList, setDoctorList] = useState([]);
  const [RegionList, setRegionList] = useState([]);
  const [LOSList, setLOSList] = useState([]);
  const [CenterFilterList, setCenterFilterList] = useState([]);
  const { tableHeaderName, setTableHeaderName } = useContext(AppContext);
  const [YearList, setYearList] = useState([]);
  const [IPOPcontriListPercRevenue, setIPOPcontriListPercRevenue] = useState({});
  const [IPOPcontriListPercPayout, setIPOPcontriListPercPayout] = useState({});
  const [DocCountByCat, setDocCountByCat] = useState([]);
  const [DocCountByLocAndType, setDocCountByLocAndType] = useState([]);
  const [TotalCount, setTotalCount] = useState(0);
  const { tableShow, setTableShow } = useContext(AppContext);
  const { centerNames, setCenterNames } = useContext(AppContext);
  const { Months, setMonths } = useContext(AppContext);
  const { Years, setYears } = useContext(AppContext);
  const { itemGroupNames, setitemGroupNames } = useContext(AppContext);
  const { LOSName, setLOSName } = useContext(AppContext);
  const { IPOPName, setIPOPName } = useContext(AppContext);
  const { DoctorNames, setDoctorNames } = useContext(AppContext);
  const { regionNames, setRegionNames } = useContext(AppContext);
  const userLogin = useSelector((state) => state.userLogin);
  const { user } = userLogin;
  const classes = useStyles();
  const ref = useContext(AppContext);
  const backClasses = backDropStyle();
  const handleClose = () => setTableShow(false);

  console.log(user, "1234567890987654345678987654")

  useEffect(async () => {
    await axios
      .post(APIURL() + "graph/get-filter-master", { userId: user.user.id, userRole: user.user.roles, userName: user.user.username, centerNames: centerNames, DoctorNames: DoctorNames, regionNames: regionNames })
      .then((res) => {
        setitemgroupList(res.data.ItemData);
        setDoctorList(res.data.DocData);
        setRegionList(res.data.RegionData);
        setYearList(res.data.YearData);
        setCenterFilterList(res.data.CenterData);
        setLOSList(res.data.LOSList);

      })
      .catch((err) => {
        toast.error(err.message);
      });
    await axios
      .post(APIURL() + "graph/get-revenue", { userId: user.user.id, userRole: user.user.roles, userName: user.user.username, centerNames: centerNames, Months: Months, Years: Years, itemGroupNames: itemGroupNames, DoctorNames: DoctorNames, regionNames: regionNames, IPOPName: IPOPName, LOSName: LOSName })
      .then((res) => {
        let arr = res.data.data;
        let CenterList = res.data.CenterList;
        let ipopcontri = res.data.IPOPContribution;
        let ipopcontriPerc = res.data.IPOPPercContribution;
        setRevenueData(arr);
        setCenterList(CenterList);
        setIPOPcontriList(ipopcontri);
        setIPOPcontriListPercRevenue(ipopcontriPerc);
      })
      .catch((err) => {
        toast.error(err.message);
      });

    await axios
      .post(APIURL() + "graph/get-payout", { userId: user.user.id, userRole: user.user.roles, userName: user.user.username, centerNames: centerNames, Months: Months, Years: Years, itemGroupNames: itemGroupNames, DoctorNames: DoctorNames, regionNames: regionNames, IPOPName: IPOPName, LOSName: LOSName })
      .then((res) => {
        let arr = res.data.data
        let CenterList = res.data.CenterList
        let ipoppay = res.data.IPOPContribution;
        let ipopcontriPerc = res.data.IPOPPercContribution;
        console.log(ipoppay, "asssssgaagga");
        setIPOPpayout(ipoppay);
        setPayoutData(arr);
        setLegendList(CenterList);
        setIPOPcontriListPercPayout(ipopcontriPerc);
      })
      .catch((err) => {
        toast.error(err.message);
      });
    
    await axios
      .post(APIURL() + "graph/get-payout-vs-revenue-los", { userId: user.user.id, userRole: user.user.roles, userName: user.user.username, centerNames: centerNames, Months: Months, Years: Years, itemGroupNames: itemGroupNames, DoctorNames: DoctorNames, regionNames: regionNames, IPOPName: IPOPName, LOSName: LOSName })
      .then((res) => {
        let arr = res.data.data
        let CenterList = res.data.CenterList
        setPayoutLosData(arr);
        setLegendListLos(CenterList)
      })
      .catch((err) => {
        toast.error(err.message);
      });

    await axios
      .post(APIURL() + "graph/Get-yearOnyear-revenue", { userId: user.user.id, userRole: user.user.roles, userName: user.user.username, centerNames: centerNames, Months: Months, Years: Years, itemGroupNames: itemGroupNames, DoctorNames: DoctorNames, regionNames: regionNames, IPOPName: IPOPName, LOSName: LOSName })
      .then((res) => {
        let arr = res.data.data
        let LegendName = res.data.LegendName
        setRevenueYearData(arr);
        setLegendyear(LegendName);
      })
      .catch((err) => {
        toast.error(err.message);
      });
    
    // await axios
    //   .post(APIURL() + "graph/get-doctor-by-dept", { userId: user.user.id, userRole: user.user.roles, userName: user.user.username, centerNames: centerNames, Months: Months, Years: Years })
    //   .then((res) => {
    //     let arr = res.data.data
    //     let deptList = res.data.deptList
    //     setDoctorByDept(arr);
    //     setDeptArray(deptList)
    //   })
    //   .catch((err) => {
    //     toast.error(err.message);
    //   });
    await axios
      .post(APIURL() + "graph/get-itemgroup-count-by-month", { userId: user.user.id, userRole: user.user.roles, userName: user.user.username, centerNames: centerNames, Months: Months, Years: Years, itemGroupNames: itemGroupNames, DoctorNames: DoctorNames, regionNames: regionNames, IPOPName: IPOPName, LOSName: LOSName })
      .then((res) => {
        let arr = res.data.data
        let CenterList = res.data.deptList
        setItemGroupCount(arr);
        setLegendPieChart(CenterList)
      })
      .catch((err) => {
        toast.error(err.message);
      });
    await axios
      .post(APIURL() + "graph/Get-onboaarding-status", { userId: user.user.id, userRole: user.user.roles, userName: user.user.username, centerNames: centerNames, Months: Months, Years: Years, itemGroupNames: itemGroupNames, DoctorNames: DoctorNames, regionNames: regionNames, IPOPName: IPOPName, LOSName: LOSName })
      .then((res) => {
        if(res.status === 500){
          console.log(res);
        }
        else{
          let arr = res.data.data
          let CenterList = res.data.actionName
          setOnboardingStatus(arr);
          setActionName(CenterList)
        }
      })
      .catch((err) => {
        console.log(err);
        // toast.error(err.message);
      });
    await axios
      .post(APIURL() + "graph/get-Doctor-count-by-cat", { userId: user.user.id, userRole: user.user.roles, userName: user.user.username, centerNames: centerNames, Months: Months, Years: Years, itemGroupNames: itemGroupNames, DoctorNames: DoctorNames, regionNames: regionNames, IPOPName: IPOPName, LOSName: LOSName })
      .then((res) => {
        setDocCountByCat(res.data.data);
        setTotalCount(res.data.TotalVal)
      })
      .catch((err) => {
        toast.error(err.message);
      });
    await axios
      .post(APIURL() + "graph/get-Doctor-count-by-loc-OP-IP", { userId: user.user.id, userRole: user.user.roles, centerNames: centerNames, Months: Months, Years: Years, itemGroupNames: itemGroupNames, DoctorNames: DoctorNames, regionNames: regionNames, IPOPName: IPOPName, LOSName: LOSName })
      .then((res) => {
        setDocCountByLocAndType(res.data.data);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  }, [centerNames, Months, Years, itemGroupNames, DoctorNames, regionNames, LOSName, IPOPName]);
  var currentYear = Legendyear.length > 0 && Legendyear[1] && Legendyear[1].legendName ? (Legendyear[1].legendName).toString() : '';
  var previousYear = Legendyear.length > 0 && Legendyear[0] && Legendyear[0].legendName ? (Legendyear[0].legendName).toString() : '';
  
  
  let IPRevVSPay = [];
  let OPRevVSPay = [];
  let IPRevVSPaygraph = [];
  let OPRevVSPaygraph = [];
  let IPRevVSPayperc = [];
  let OPRevVSPayperc = [];
  let IPRev = 0;
  let IPPay = 0;
  let OPRev = 0;
  let OPPay = 0;
  let IPTotal = 0;
  let OPTotal = 0;

  IPOPPayoutR.map(item => 
    {
      if(item.name == "IP"){
        IPPay = item.value;
      }
      if(item.name == "OP"){
        OPPay = item.value;
      }
    }
    );
    IPOPcontriList.map(item => 
      {
        if(item.name == "IP"){
          IPRev = item.value;
        }
        if(item.name == "OP"){
          OPRev = item.value;
        }
      }
      );
      
      IPTotal = IPRev;
      OPTotal = OPRev;
      
      IPRevVSPay = [
        {name: "Payout", value: IPPay},
        {name: "Revenue", value: IPRev},
      ];
      OPRevVSPay = [
        {name: "Payout", value: OPPay},
        {name: "Revenue", value: OPRev},
      ];
      IPRevVSPaygraph = [
        {name: "Payout", value: IPPay},
        {name: "Revenue", value: IPRev-IPPay},
      ];
      OPRevVSPaygraph = [
        {name: "Payout", value: OPPay},
        {name: "Revenue", value: OPRev-OPPay},
      ];
      IPRevVSPayperc = {Payout: (Math.round((IPPay/IPTotal)*100))} ;
      OPRevVSPayperc = {Payout: (Math.round((OPPay/OPTotal)*100))};

      if (IPOPName) {
        for(let i=0; i<IPOPName.length; i++){
          if(IPOPName[i] == "IP" && IPOPName.length == 1){
            OPRevVSPay = [
              {name: "Revenue", value: 0},
              {name: "Payout", value: 0},
            ];
            OPRevVSPayperc = {Payout: 0};
          }
          if(IPOPName[i] == "OP" && IPOPName.length == 1){
            IPRevVSPay = [
              {name: "Revenue", value: 0},
              {name: "Payout", value: 0},
            ];
            IPRevVSPayperc = {Payout: 0} ;
          }
        }
      }

  return (
    <>
    {user.user.roles == 9 ? 
     <div style={{ zIndex: '9999', position: 'fixed', marginLeft: '90%', marginTop: '18%' }}>
     <Tooltip title='Enable Filter' zIndex="9999">
       <Fab className={classes.btn} aria-label="filter" color="secondary" size="small" onClick={() => setNavShow(prev => !prev)}>
         <FilterAltIcon className={classes.filterIcon} />
       </Fab>
     </Tooltip>
   </div>
    : 
    <div style={{ zIndex: '9999', position: 'fixed', marginLeft: '75%', marginTop: '18%' }}>
        <Tooltip title='Enable Filter' zIndex="9999">
          <Fab className={classes.btn} aria-label="filter" color="secondary" size="small" onClick={() => setNavShow(prev => !prev)}>
            <FilterAltIcon className={classes.filterIcon} />
          </Fab>
        </Tooltip>
      </div>
      }
      

      <div className="col-md-12 col-xl-12 mt-2 px-0">
        <div className={classes.stickyDiv}>
          {navShow && <div className='col-md-12 col-xl-12 mx-auto px-0' style={{ height: '75px', position: 'static' }}><NavFilter itemgroupList={itemgroupList} DoctorList={DoctorList} RegionList={RegionList} YearList={YearList} CenterList={CenterFilterList} LOSList={LOSList} /></div>}
        </div>
        <div className="row mt-1">
          <div className="col-md-12 col-xl-12 mb-2 ">
            <div className="card cardsTheme1 text-center d-flex align-content-center" style={{ height: '337px' }}>
              <div style={{ marginBottom: '10px', marginTop: '4px' }}>
                <span style={{ dislay: 'table', marginLeft: '100px', fontSize: '15px', fontWeight: 'bold', textDecoration: 'underline', textAlign: 'center' }} onClick={() => {
                  setTableHeaderName('Revenue Table')
                  ref.current?.scrollIntoView({ behavior: 'smooth' })
                  setTableShow(true)
                }}>Revenue</span>
                <span style={{ fontSize: '12px', marginRight: '14px', color: 'red', fontStyle: 'italic', float: 'right' }}>* All numbers in Lacs</span>
              </div>
              <LineChartRevenue RevenueData={RevenueData} CenterList={CenterList} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xl-12 mb-2 ">
            <div className="card cardsTheme1 text-center d-flex align-content-center" style={{ height: '337px' }}>
              <div style={{ marginBottom: '10px', marginTop: '4px' }}>
                <span style={{ marginLeft: '100px', fontSize: '15px', fontWeight: 'bold', textDecoration: 'underline', position: 'center', cursor: 'pointer' }} onClick={() => {
                  setTableHeaderName('Payout Table')
                  ref.current?.scrollIntoView({ behavior: 'smooth' })
                  setTableShow(true)
                }}>Payout</span>
                <span style={{ fontSize: '12px', marginRight: '14px', color: 'red', fontStyle: 'italic', float: 'right' }}>* All numbers in Lacs</span>
              </div>
              <LineChartPayout RevenueData={PayoutData} CenterList={LegendList} />
            </div>
          </div>
        </div>
        {user.user.roles == 4 ? null :
          <div className="col-md-12 col-xl-12 px-0">
            <div className="row">
              <div className="col-md-6 col-xl-6 mb-2 pr-1">
                <div className="card cardsTheme1 text-center d-flex align-content-center" style={{ height: '505px' }}>
                  <div style={{ marginBottom: '10px', marginTop: '4px' }}>
                    <span style={{ marginLeft: '90px', fontSize: '15px', fontWeight: 'bold', textDecoration: 'underline' }}>Year On Year Revenue - ( {`${previousYear.slice(-2)} - ${currentYear.slice(-2)}`} )</span>
                    <span style={{ fontSize: '12px', marginRight: '14px', color: 'red', fontStyle: 'italic', float: 'right' }}>* All numbers in Lacs</span>
                  </div>
                  <StackedBarGraphreveneue RevenueyearData={RevenueyearData} Legendyear={Legendyear} />
                </div>
              </div>
              <div className="col-md-6 col-xl-6 mb-2 pl-1">
                <div className="card cardsTheme1 text-center d-flex align-content-center" style={{ height: '505px' }}>
                  <div style={{ marginBottom: '2px', marginTop: '4px' }}>
                    <span style={{ marginLeft: '60px', fontSize: '15px', fontWeight: 'bold', textDecoration: 'underline', position: 'center' }} onClick={() => {
                      setTableHeaderName('Payout-LOS Table')
                      ref.current?.scrollIntoView({ behavior: 'smooth' })
                      setTableShow(true)
                    }}>LOS- Revenue v/s Payout - ( {`${previousYear.slice(-2)} - ${currentYear.slice(-2)}`} )</span>
                    <span style={{ fontSize: '12px', marginRight: '14px', color: 'red', fontStyle: 'italic', float: 'right' }}>* All numbers in Lacs</span>

                  </div>
                  <StackChartPayout RevenueData={PayoutLosData} CenterList={LegendListLos} />
                  <div style={{ marginBottom: '5px', marginTop: '-5px', display: "flex", justifyContent: "right" }}>
                  <span style={{ marginRight: '12px',fontSize: '11px', fontWeight: "bolder", fontStyle: "italic", position: 'center' }}>R-Revenue &nbsp;&nbsp; P-Payout</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-xl-6 mb-2 pr-1">
                <div className="card cardsTheme1 text-center d-flex align-content-center" style={{ height: '230px' }}>
                  <span style={{ marginLeft: '10px',marginBottom: '-10px', fontSize: '15px', fontWeight: 'bold', textDecoration: 'underline' }}>IP-Revenue VS Payout</span>
                    <PiechartIPOP iPOPcontriList={IPRevVSPay} IPOPgraphvalue={IPRevVSPaygraph} IPOPcontriListPerc={IPRevVSPayperc}/>
                    <div style={{ marginBottom: '10px', marginTop: '-70px', display: "flex", justifyContent: "center"}}>  
                        <spam style={{ marginRight: '131px', fontSize: '15px', color: "black", fontWeight: "700", position: 'center' }}>{`${IPRevVSPayperc.Payout}%`}</spam>
                    </div>
                </div>
              </div>
              <div className="col-md-6 col-xl-6 mb-2 pl-1 ">
                <div className="card cardsTheme1 text-center d-flex align-content-center" style={{ height: '230px' }}>
                  <span style={{ marginLeft: '10px',marginBottom: '-10px', fontSize: '15px', fontWeight: 'bold', textDecoration: 'underline' }}>OP-Revenue VS Payout</span>
                    <PiechartIPOP iPOPcontriList={OPRevVSPay} IPOPgraphvalue={OPRevVSPaygraph} IPOPcontriListPerc={OPRevVSPayperc} />
                    <div style={{ marginBottom: '10px', marginTop: '-70px', display: "flex", justifyContent: "center"}}>
                          <spam style={{ marginRight: '131px', fontSize: '15px', color: "black", fontWeight: "700", position: 'center' }}>{`${OPRevVSPayperc.Payout}%`}</spam>
                    </div>
                </div>
              </div>
            </div>
            <div className="row">
              {/* <div className="col-md-6 col-xl-6 mb-2 pr-1">
                <div className="card cardsTheme1 text-center d-flex align-content-center" style={{ height: '300px' }}>
                  <span style={{ marginLeft: '10px', fontSize: '15px', fontWeight: 'bold', textDecoration: 'underline' }} onClick={() => {
                    setTableHeaderName('Doctor Profile Table')
                    ref.current?.scrollIntoView({ behavior: 'smooth' })
                    setTableShow(true)
                  }}>Doctors By Departments</span>
                  <StackedDualBarGraph doctorByDept={doctorByDept} DeptArr={DeptArr} />
                </div>
              </div> */}
              <div className="col-md-6 col-xl-6 mb-2 pr-1">
                  <div className="card cardsTheme1 text-center d-flex align-content-center" style={{ height: '300px' }}>
                    <span style={{ marginLeft: '10px', fontSize: '15px', fontWeight: 'bold', textDecoration: 'underline' }}>On-Boarding Pending Status</span>
                    <PieChartStatus graphData={onboardingStatus} DeptArr={actionName} />  
                    <span style={{ fontSize: '12px', marginRight: '-180px', color: 'red', fontStyle: 'italic', float: 'right' }}>*Ineffective Filters: LOS, IP/OP, ItemGroup, Doctor</span>
                  </div>
                </div>
              <div className="col-md-6 col-xl-6 mb-2 pl-1">
                <div className="card cardsTheme1 text-center d-flex align-content-center" style={{ height: '300px' }}>
                <div style={{ marginBottom: '10px', marginTop: '4px' }}>
                <span style={{ dislay: 'table', marginLeft: '100px', fontSize: '15px', fontWeight: 'bold', textDecoration: 'underline', textAlign: 'center' }}>Revenue By ItemGroup</span>
                <span style={{ fontSize: '12px', marginRight: '10px', color: 'red', fontStyle: 'italic', float: 'right' }}>* All numbers in Lacs</span>
                </div>
                  <PieChartLabel graphData={ItemGroupCount} DeptArr={LegendPieChart} />
                </div>
              </div>
            </div>
            <div className="card cardsTheme1 text-center d-flex align-content-center mb-2" style={{ height: '300px', flexDirection: 'row', width: '-webkit-fill-available' }}>
              <div className="row">
                <span style={{ marginLeft: '10px', fontSize: '15px', fontWeight: 'bold', textDecoration: 'underline', position: 'absolute', width: '-webkit-fill-available' }}
                >Active Doctors by Category</span>
                <span style={{ marginLeft: '10px', fontSize: '12px', position: 'absolute', width: '-webkit-fill-available', color: 'green', marginBottom: '5px', marginTop: '23px' }}>Total Number of Active Doctors: {TotalCount.toLocaleString('en-IN')}</span>
              </div>
              {user.user.roles == 9 ? 
              <div className="row" style={{ marginTop: '33px' }}>
                <div className="col-md-4 col-xl-4 mb-2" style={{ width: '391px', marginLeft: '96px', position: 'absolute' }}>
                  <BarchartDoctorsByLocation graphData={DocCountByCat} Total={TotalCount} />
                </div>
                <div className="col-md-8 col-xl-8 mb-2" style={{ position: 'absolute', marginLeft: '485px' }}>
                  <DoctorsByLocation graphData={DocCountByLocAndType} />
                </div>
            </div>: 
              <div className="row" style={{ marginTop: '33px' }}>
                <div className="col-md-4 col-xl-4 mb-2" style={{ width: '391px', marginLeft: '68px', position: 'absolute' }}>
                  <BarchartDoctorsByLocation graphData={DocCountByCat} Total={TotalCount} />
                </div>
                <div className="col-md-8 col-xl-8 mb-2" style={{ position: 'absolute', marginLeft: '350px' }}>
                  <DoctorsByLocation graphData={DocCountByLocAndType} />
                </div>
            </div>}
              <div className="row" style={{ marginTop: '250px' }}>
              <span style={{ marginLeft: '850px', fontSize: '12px', position: 'absolute', width: '-webkit-fill-available', color: 'red', fontStyle: 'italic', float: 'right', marginBottom: '5px', marginTop: '23px' }}>*Ineffective Filters: LOS,</span>
              <span style={{ marginLeft: '-645px', fontSize: '12px', position: 'absolute', width: '-webkit-fill-available', color: 'red', fontStyle: 'italic', float: 'right', marginBottom: '5px', marginTop: '23px' }}>*Ineffective Filters: LOS, IPOP, ItemGroup</span>
              </div>
            </div>
          </div>}      
      </div>
      
      <Dialog
        fullWidth={true}
        disableEnforceFocus
        maxWidth="md"
        open={tableShow}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        // style={{position: 'fixed', zIndex: '9999',}}
      >
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          style={{
            backgroundColor: "#810e36",
            color: "white",
          }}
        >
          {tableHeaderName}
          <Button
            autoFocus
            onClick={handleClose}
            color="primary"
            variant="contained"
            size="large"
            style={{float: "right"}}
          >
            <CloseIcon/>
          </Button>
        </DialogTitle>
        <DialogContent style={{heigth: "500px"}}>
          <div className="row" ref={ref}>
            {/* <span style={{ margin: '10px', fontSize: '15px', fontWeight: 'bold', textDecoration: 'underline' }}>{tableHeaderName}</span> */}
            <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'center', marginBottom: "2", height: "450px", width: "-webkit-fill-available" }}><CustomGraphTable /></div>
          </div>
        </DialogContent>   
      </Dialog>

    </>
  );
}
